import { useState } from 'react';
import { X, Upload, XCircle, Link, AlertTriangle } from 'lucide-react';

interface CancellationReasonModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: (reason: string, files?: File[]) => void;
  isSubmitting?: boolean;
}

export function CancellationReasonModal({ isOpen, onClose, onConfirm, isSubmitting = false }: CancellationReasonModalProps) {
  const [reason, setReason] = useState('');
  const [files, setFiles] = useState<File[]>([]);
  const [previews, setPreviews] = useState<string[]>([]);

  if (!isOpen) return null;

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = Array.from(e.target.files || []);
    const validFiles = selectedFiles.filter(file => 
      file.type.startsWith('image/') && file.size <= 5 * 1024 * 1024 // 5MB limit
    );

    if (validFiles.length < selectedFiles.length) {
      alert('Some files were not added. Please ensure all files are images under 5MB.');
    }

    setFiles(prevFiles => [...prevFiles, ...validFiles]);
    
    // Create previews for the new files
    validFiles.forEach(file => {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviews(prev => [...prev, reader.result as string]);
      };
      reader.readAsDataURL(file);
    });
  };

  const removeFile = (index: number) => {
    setFiles(files.filter((_, i) => i !== index));
    setPreviews(previews.filter((_, i) => i !== index));
  };

  const openPreview = (preview: string) => {
    const previewWindow = window.open('', '_blank');
    if (previewWindow) {
      previewWindow.document.write(`
        <!DOCTYPE html>
        <html>
          <head>
            <title>Image Preview</title>
            <style>
              body {
                margin: 0;
                padding: 20px;
                display: flex;
                justify-content: center;
                align-items: center;
                min-height: 100vh;
                background: #1a1a1a;
              }
              img {
                max-width: 100%;
                max-height: 90vh;
                object-fit: contain;
                border-radius: 4px;
              }
            </style>
          </head>
          <body>
            <img src="${preview}" alt="Preview" />
          </body>
        </html>
      `);
      previewWindow.document.close();
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      await onConfirm(reason, files);
      onClose(); // Close the modal after successful cancellation
    } catch (error) {
      console.error('Error during cancellation:', error);
    }
  };

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto">
      <div className="flex min-h-screen items-center justify-center p-4 text-center">
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" onClick={onClose} />

        <div className="relative transform overflow-hidden rounded-lg bg-white dark:bg-gray-800 px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
          <div className="absolute right-0 top-0 pr-4 pt-4">
            <button
              type="button"
              className="rounded-md bg-white dark:bg-gray-800 text-gray-400 hover:text-gray-500 focus:outline-none"
              onClick={onClose}
            >
              <X className="h-6 w-6" />
            </button>
          </div>

          <div className="mt-3 text-center sm:mt-0 sm:text-left w-full">
            <h3 className="text-lg font-semibold leading-6 text-gray-900 dark:text-white">
              Cancel & Refund Application
            </h3>

            {/* Warning Box */}
            <div className="mt-4 bg-yellow-50 dark:bg-yellow-900/30 border border-yellow-200 dark:border-yellow-800 rounded-lg p-4">
              <div className="flex">
                <AlertTriangle className="h-5 w-5 text-yellow-500 dark:text-yellow-400 flex-shrink-0 mt-0.5" />
                <div className="ml-3">
                  <h4 className="text-sm font-medium text-yellow-800 dark:text-yellow-200">
                    Important Notice
                  </h4>
                  <div className="mt-2 text-sm text-yellow-700 dark:text-yellow-100">
                    <p className="mb-2">Before cancelling an application:</p>
                    <ul className="list-disc ml-4 space-y-1">
                      <li>Contact admins first for guidance and potential solutions</li>
                      <li>Consider if the issue can be resolved without cancellation</li>
                      <li>Ensure you have a valid reason for cancellation</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <form onSubmit={handleSubmit} className="mt-4">
              <div className="space-y-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                    Cancellation Reason *
                  </label>
                  <textarea
                    value={reason}
                    onChange={(e) => setReason(e.target.value)}
                    className="w-full rounded-md border-gray-300 dark:border-gray-600 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 dark:bg-gray-700 dark:text-white p-3"
                    rows={4}
                    placeholder="Please provide a detailed reason for cancelling this application..."
                    required
                  />
                </div>

                {/* File Upload Section */}
                <div>
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                    Attach Screenshots *
                  </label>
                  <div className="flex items-center gap-2">
                    <label className="inline-flex items-center px-3 py-2 rounded-md border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700 text-sm font-medium text-gray-700 dark:text-gray-200 hover:bg-gray-50 dark:hover:bg-gray-600 cursor-pointer">
                      <Upload className="w-4 h-4 mr-2" />
                      Add Screenshots
                      <input
                        type="file"
                        className="hidden"
                        accept="image/*"
                        multiple
                        onChange={handleFileChange}
                        disabled={isSubmitting}
                        required
                      />
                    </label>
                  </div>
                  {files.length === 0 && (
                    <p className="mt-1 text-sm text-red-500 dark:text-red-400">
                      At least one screenshot is required
                    </p>
                  )}
                </div>

                {/* File List Section */}
                {files.length > 0 && (
                  <div className="space-y-2">
                    {files.map((file, index) => (
                      <div key={index} className="flex items-center justify-between group">
                        <button
                          type="button"
                          onClick={() => openPreview(previews[index])}
                          className="flex items-center text-sm text-indigo-600 dark:text-indigo-400 hover:text-indigo-500"
                        >
                          <Link className="w-4 h-4 mr-2" />
                          {file.name}
                        </button>
                        <button
                          type="button"
                          onClick={() => removeFile(index)}
                          className="text-red-500 hover:text-red-600 p-1 rounded-full"
                        >
                          <XCircle className="w-4 h-4" />
                        </button>
                      </div>
                    ))}
                  </div>
                )}

                <div className="mt-6 flex justify-end">
                  <button
                    type="submit"
                    disabled={isSubmitting || !reason.trim() || files.length === 0}
                    className="inline-flex w-full justify-center rounded-md bg-[#800000] hover:bg-[#660000] px-3 py-2 text-sm font-semibold text-white shadow-sm sm:w-auto disabled:opacity-50"
                  >
                    {isSubmitting ? 'Cancelling...' : 'Cancel & Refund'}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
} 