import { useState, useEffect } from 'react';
import { auth, db } from '../lib/firebase';
import {
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signOut as firebaseSignOut,
  onAuthStateChanged,
  GoogleAuthProvider,
  signInWithPopup,
  User,
  sendEmailVerification
} from 'firebase/auth';
import { doc, setDoc, getDoc, updateDoc, onSnapshot } from 'firebase/firestore';
import toast from 'react-hot-toast';
import { UserProfile } from '../types/user';
import { useNavigate } from 'react-router-dom';

export type UserType = 'admin' | 'customer' | 'agent';

export function useAuth() {
  const [user, setUser] = useState<User | null>(null);
  const [userProfile, setUserProfile] = useState<UserProfile | null>(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const fetchUserProfile = async (uid: string) => {
    try {
      const docRef = doc(db, 'users', uid);
      const docSnap = await getDoc(docRef);
      
      if (docSnap.exists()) {
        const profileData = docSnap.data() as UserProfile;
        
        // Check if email verification status needs updating
        if (auth.currentUser?.emailVerified && !profileData.isVerified) {
          try {
            await updateDoc(docRef, {
              isVerified: true
            });
            profileData.isVerified = true;
          } catch (error) {
            console.error('Error updating verification status:', error);
          }
        }
        
        setUserProfile(profileData);
      } else {
        setUserProfile(null);
      }
    } catch (error) {
      console.error('Error fetching user profile:', error);
      setUserProfile(null);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      setUser(user);
      if (user) {
        await fetchUserProfile(user.uid);
      } else {
        setUserProfile(null);
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  // Add a function to refresh user profile on demand
  const refreshUserProfile = async () => {
    if (user) {
      await fetchUserProfile(user.uid);
    }
  };

  const signIn = async (email: string, password: string) => {
    try {
      setLoading(true);
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      await fetchUserProfile(userCredential.user.uid);
      
      // Get user type and redirect accordingly
      const userDoc = await getDoc(doc(db, 'users', userCredential.user.uid));
      const userData = userDoc.data() as UserProfile;
      
      if (userData.userType === 'admin') {
        navigate('/admin/dashboard');
      } else if (userData.userType === 'agent') {
        navigate('/agent/dashboard');
      } else {
        navigate('/dashboard');
      }
      
      return { success: true };
    } catch (error: any) {
      console.error('Sign in error:', error);
      toast.error(error.message);
      return { success: false, error: error.message };
    } finally {
      setLoading(false);
    }
  };

  const register = async (email: string, password: string, firstName: string, lastName: string) => {
    try {
      // Create user in Firebase Auth
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      
      // Create user document in Firestore
      const userRef = doc(db, 'users', userCredential.user.uid);
      await setDoc(userRef, {
        firstName,
        lastName,
        email,
        userType: 'customer',
        credits: 15, // Starting credits
        isPhoneVerified: false,
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
        isVerified: false
      });

      // Send email verification
      await sendEmailVerification(userCredential.user);
      
      toast.success('Account created successfully! Please verify your email.');
      return userCredential; // Return the user credential
    } catch (error: any) {
      console.error('Registration error:', error);
      throw new Error(error.message);
    }
  };

  const signInWithGoogle = async () => {
    try {
      const provider = new GoogleAuthProvider();
      const userCredential = await signInWithPopup(auth, provider);
      
      // Check if user document exists
      const userRef = doc(db, 'users', userCredential.user.uid);
      const userDoc = await getDoc(userRef);
      
      if (!userDoc.exists()) {
        // Create new user document for Google sign-in
        const newUserData = {
          firstName: userCredential.user.displayName?.split(' ')[0] || '',
          lastName: userCredential.user.displayName?.split(' ').slice(1).join(' ') || '',
          email: userCredential.user.email || '',
          userType: 'customer',
          credits: 15,
          isPhoneVerified: false,
          createdAt: new Date().toISOString(),
          updatedAt: new Date().toISOString(),
          isVerified: userCredential.user.emailVerified
        };
        
        // Create the user document
        await setDoc(userRef, newUserData);
        
        // Wait a moment to ensure Firestore write is complete
        await new Promise(resolve => setTimeout(resolve, 1000));
        
        // Verify the document was created by fetching it again
        const verifyDoc = await getDoc(userRef);
        if (!verifyDoc.exists()) {
          throw new Error('Failed to create user profile');
        }
        
        setUserProfile(newUserData as UserProfile);
      } else {
        // Update existing user's userType if not set
        const userData = userDoc.data();
        if (!userData.userType) {
          await updateDoc(userRef, {
            userType: 'customer',
            updatedAt: new Date().toISOString()
          });
          // Wait for update to complete
          await new Promise(resolve => setTimeout(resolve, 1000));
        }
        // Fetch and set the existing user profile
        await fetchUserProfile(userCredential.user.uid);
      }
      
      // Get the latest user data after all operations
      const finalUserDoc = await getDoc(userRef);
      const finalUserData = finalUserDoc.data();
      
      if (!finalUserData) {
        throw new Error('User profile not found');
      }
      
      toast.success('Signed in successfully!');
      
      // Navigate based on final user type
      if (finalUserData.userType === 'admin') {
        window.location.href = '/admin/dashboard';
      } else if (finalUserData.userType === 'agent') {
        window.location.href = '/agent/dashboard';
      } else {
        window.location.href = '/dashboard';
      }
    } catch (error: any) {
      console.error('Google sign-in error:', error);
      throw new Error(error.message);
    }
  };

  const signOut = async () => {
    await firebaseSignOut(auth);
    toast.success('Signed out successfully', {
      id: 'auth-signout',
      duration: 3000
    });
  };

  return {
    user,
    userProfile,
    loading,
    register,
    signIn,
    signOut,
    signInWithGoogle,
    checkVerification: async () => {
      if (user) {
        await user.reload();
        if (user.emailVerified) {
          const docRef = doc(db, 'users', user.uid);
          await updateDoc(docRef, {
            isVerified: true
          });
          await fetchUserProfile(user.uid);
        }
      }
    },
    fetchUserProfile,
    refreshUserProfile,
  };
}