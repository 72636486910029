import { useState, useEffect } from 'react';
import { useAuth } from '../hooks/useAuth';
import { collection, query, where, orderBy, getDocs } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { Application } from '../types/application';
import { format } from 'date-fns';
import { Loader2, ExternalLink, Search, X } from 'lucide-react';
import { Link } from 'react-router-dom';

type TabType = 'all' | 'in_progress' | 'pending' | 'completed' | 'unpaid' | 'paid' | 'cancelled';

export function AgentCompletions() {
  const { user } = useAuth();
  const [applications, setApplications] = useState<Application[]>([]);
  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState<TabType>('all');
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    if (!user) return;
    loadApplications();
  }, [user]);

  const loadApplications = async () => {
    if (!user) return;

    try {
      setLoading(true);
      // Query for applications where user is either the agent or the one who cancelled
      const [agentAppsSnapshot, cancelledAppsSnapshot] = await Promise.all([
        getDocs(query(
          collection(db, 'applications'),
          where('agentId', '==', user.uid),
          orderBy('updatedAt', 'desc')
        )),
        getDocs(query(
          collection(db, 'applications'),
          where('lastActionBy', '==', user.uid),
          where('status', '==', 'cancelled'),
          orderBy('updatedAt', 'desc')
        ))
      ]);

      // Combine and deduplicate results
      const agentApps = agentAppsSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      const cancelledApps = cancelledAppsSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));

      // Combine and deduplicate by ID
      const combinedApps = [...agentApps];
      cancelledApps.forEach(cancelledApp => {
        if (!combinedApps.some(app => app.id === cancelledApp.id)) {
          combinedApps.push(cancelledApp);
        }
      });

      setApplications(combinedApps as Application[]);
    } catch (error) {
      console.error('Error loading applications:', error);
    } finally {
      setLoading(false);
    }
  };

  const filteredApplications = applications.filter(app => {
    const matchesSearch = searchTerm === '' || 
      app.company.toLowerCase().includes(searchTerm.toLowerCase()) ||
      app.position.toLowerCase().includes(searchTerm.toLowerCase()) ||
      app.id.toLowerCase().includes(searchTerm.toLowerCase());

    switch (activeTab) {
      case 'in_progress':
        return matchesSearch && app.status === 'in_progress';
      case 'pending':
        return matchesSearch && app.status === 'pending';
      case 'completed':
        return matchesSearch && app.status === 'completed';
      case 'unpaid':
        return matchesSearch && app.status === 'completed' && app.paymentStatus === 'pending';
      case 'paid':
        return matchesSearch && app.status === 'completed' && app.paymentStatus === 'paid';
      case 'cancelled':
        return matchesSearch && app.status === 'cancelled' && app.lastActionBy === user?.uid;
      default:
        return matchesSearch;
    }
  });

  const tabs: { id: TabType; name: string; count: number }[] = [
    { 
      id: 'all', 
      name: 'All', 
      count: applications.length
    },
    { 
      id: 'in_progress', 
      name: 'In Progress', 
      count: applications.filter(app => app.status === 'in_progress').length
    },
    { 
      id: 'pending', 
      name: 'Pending', 
      count: applications.filter(app => app.status === 'pending').length
    },
    { 
      id: 'completed', 
      name: 'Completed', 
      count: applications.filter(app => app.status === 'completed').length
    },
    {
      id: 'cancelled',
      name: 'Cancelled',
      count: applications.filter(app => app.status === 'cancelled' && app.lastActionBy === user?.uid).length
    },
    { 
      id: 'unpaid', 
      name: 'Unpaid', 
      count: applications.filter(app => app.status === 'completed' && app.paymentStatus === 'pending').length
    },
    { 
      id: 'paid', 
      name: 'Paid', 
      count: applications.filter(app => app.status === 'completed' && app.paymentStatus === 'paid').length
    }
  ];

  if (loading) {
    return (
      <div className="flex justify-center py-12">
        <Loader2 className="h-8 w-8 animate-spin text-indigo-600 dark:text-indigo-400" />
      </div>
    );
  }

  const getPaymentStatusDisplay = (application: Application) => {
    if (application.status === 'cancelled') {
      return {
        text: 'Not Eligible',
        className: 'bg-gray-100 text-gray-800 dark:bg-gray-900/30 dark:text-gray-300',
        title: 'Cancelled applications are not eligible for payment'
      };
    }

    if (application.status !== 'completed') {
      return {
        text: 'N/A',
        className: 'bg-gray-100 text-gray-800 dark:bg-gray-900/30 dark:text-gray-300',
        title: 'Only completed applications are eligible for payment'
      };
    }

    if (application.paymentStatus === 'paid') {
      return {
        text: 'Paid',
        className: 'bg-green-100 text-green-800 dark:bg-green-900/30 dark:text-green-300',
        title: 'Payment has been processed for this application'
      };
    }

    return {
      text: 'Unpaid',
      className: 'bg-violet-100 text-violet-800 dark:bg-violet-900/30 dark:text-violet-300',
      title: 'Payment is pending for this application'
    };
  };

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <div className="mb-8">
        <h1 className="text-3xl font-bold text-gray-900 dark:text-white">My Completions</h1>
        <p className="mt-2 text-gray-600 dark:text-gray-400">
          Track and manage your completed applications
        </p>
      </div>

      {/* Search Bar */}
      <div className="mb-6">
        <div className="relative">
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <Search className="h-5 w-5 text-gray-400" />
          </div>
          <input
            type="text"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder="Search by company, position, or application ID..."
            className="block w-full pl-10 pr-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md leading-5 bg-white dark:bg-gray-700 text-gray-900 dark:text-white placeholder-gray-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
          {searchTerm && (
            <button
              onClick={() => setSearchTerm('')}
              className="absolute inset-y-0 right-0 pr-3 flex items-center"
            >
              <X className="h-5 w-5 text-gray-400 hover:text-gray-500" />
            </button>
          )}
        </div>
      </div>

      {/* Tabs */}
      <div className="border-b border-gray-200 dark:border-gray-700 mb-6">
        <nav className="-mb-px flex space-x-8">
          {tabs.map((tab) => (
            <button
              key={tab.id}
              onClick={() => setActiveTab(tab.id)}
              title={tab.id === 'all' ? 'View all applications regardless of status' :
                    tab.id === 'in_progress' ? 'Applications being actively processed by you' :
                    tab.id === 'pending' ? 'Applications waiting for additional information from the customer' :
                    tab.id === 'completed' ? 'Applications that have been successfully submitted' :
                    tab.id === 'unpaid' ? 'Completed applications pending payment' :
                    tab.id === 'paid' ? 'Completed applications that have been paid' :
                    'Cancelled applications'}
              className={`
                whitespace-nowrap pb-4 px-1 border-b-2 font-medium text-sm
                ${activeTab === tab.id
                  ? 'border-indigo-500 text-indigo-600 dark:text-indigo-400'
                  : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 dark:text-gray-400 dark:hover:text-gray-300'
                }
              `}
            >
              {tab.name} ({tab.count})
            </button>
          ))}
        </nav>
      </div>

      {/* Applications Table */}
      <div className="bg-white dark:bg-gray-800 shadow overflow-hidden sm:rounded-lg">
        <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
          <thead className="bg-gray-100 dark:bg-gray-700/50">
            <tr>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-700 dark:text-gray-100 uppercase tracking-wider bg-gray-100 dark:bg-gray-700/50 border-b border-gray-200 dark:border-gray-700">
                Application Details
              </th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-700 dark:text-gray-100 uppercase tracking-wider bg-gray-100 dark:bg-gray-700/50 border-b border-gray-200 dark:border-gray-700">
                Status
              </th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-700 dark:text-gray-100 uppercase tracking-wider bg-gray-100 dark:bg-gray-700/50 border-b border-gray-200 dark:border-gray-700">
                Payment Status
              </th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-700 dark:text-gray-100 uppercase tracking-wider bg-gray-100 dark:bg-gray-700/50 border-b border-gray-200 dark:border-gray-700">
                Dates
              </th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-700 dark:text-gray-100 uppercase tracking-wider bg-gray-100 dark:bg-gray-700/50 border-b border-gray-200 dark:border-gray-700">
                Actions
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 dark:divide-gray-700">
            {filteredApplications.length === 0 ? (
              <tr>
                <td colSpan={5} className="px-6 py-4 text-center text-gray-500 dark:text-gray-400">
                  No applications found
                </td>
              </tr>
            ) : (
              filteredApplications.map((application) => (
                <tr key={application.id}>
                  <td className="px-6 py-4">
                    <Link
                      to={`/applications/${application.id}`}
                      className="flex flex-col hover:bg-gray-50 dark:hover:bg-gray-700/50 -mx-6 -my-4 px-6 py-4 transition-colors duration-200"
                    >
                      <span className="text-sm font-medium text-gray-900 dark:text-white">
                        {application.company}
                      </span>
                      <span className="text-sm text-gray-500 dark:text-gray-400">
                        {application.position}
                      </span>
                      <span className="text-xs text-gray-400 dark:text-gray-500 font-mono mt-1">
                        {application.id}
                      </span>
                    </Link>
                  </td>
                  <td className="px-6 py-4">
                    <button
                      onClick={() => setActiveTab(application.status)}
                      className="w-full h-full flex items-center hover:bg-gray-50 dark:hover:bg-gray-700/50 -mx-6 -my-4 px-6 py-4 transition-colors duration-200"
                      title={application.status === 'in_progress' ? 'Application is being actively processed by you' :
                            application.status === 'pending' ? 'Waiting for additional information from the customer' :
                            application.status === 'completed' ? 'Application has been successfully submitted' :
                            application.status === 'cancelled' ? 'Application was cancelled' :
                            'Unknown status'}
                    >
                      <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium transform scale-[1.1]
                      ${application.status === 'completed' ? 'bg-green-100 text-green-800 dark:bg-green-900/50 dark:text-green-300' :
                        application.status === 'in_progress' ? 'bg-yellow-100 text-yellow-800 dark:bg-yellow-900/50 dark:text-yellow-300' :
                        application.status === 'pending' ? 'bg-orange-100 text-orange-800 dark:bg-orange-900/50 dark:text-orange-300' :
                        application.status === 'cancelled' ? 'bg-red-100 text-red-800 dark:bg-red-900/50 dark:text-red-300' :
                        'bg-gray-100 text-gray-800 dark:bg-gray-900/30 dark:text-gray-300'}`}
                      >
                        {application.status.charAt(0).toUpperCase() + application.status.slice(1)}
                      </span>
                    </button>
                  </td>
                  <td className="px-6 py-4">
                    <button
                      onClick={() => {
                        if (application.status === 'cancelled') {
                          setActiveTab('cancelled');
                        } else if (application.status === 'completed') {
                          setActiveTab(application.paymentStatus === 'paid' ? 'paid' : 'unpaid');
                        }
                      }}
                      className="w-full h-full flex items-center hover:bg-gray-50 dark:hover:bg-gray-700/50 -mx-6 -my-4 px-6 py-4 transition-colors duration-200"
                      title={getPaymentStatusDisplay(application).title}
                    >
                      <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium transform scale-[1.1]
                      ${application.paymentStatus === 'paid' ? 'bg-green-100 text-green-800 dark:bg-green-900/50 dark:text-green-300' :
                        application.paymentStatus === 'pending' ? 'bg-yellow-100 text-yellow-800 dark:bg-yellow-900/50 dark:text-yellow-300' :
                        'bg-gray-100 text-gray-800 dark:bg-gray-900/30 dark:text-gray-300'}`}
                      >
                        {getPaymentStatusDisplay(application).text.charAt(0).toUpperCase() + getPaymentStatusDisplay(application).text.slice(1)}
                      </span>
                    </button>
                  </td>
                  <td className="px-6 py-4">
                    <Link
                      to={`/applications/${application.id}`}
                      className="flex flex-col hover:bg-gray-50 dark:hover:bg-gray-700/50 -mx-6 -my-4 px-6 py-4 transition-colors duration-200 text-sm text-gray-500 dark:text-gray-400"
                    >
                      <span>Created: {format(new Date(application.createdAt), 'MMM d, yyyy')}</span>
                      <span>Updated: {format(new Date(application.updatedAt), 'MMM d, yyyy')}</span>
                      {application.completedAt && (
                        <span>Completed: {format(new Date(application.completedAt), 'MMM d, yyyy')}</span>
                      )}
                    </Link>
                  </td>
                  <td className="px-6 py-4">
                    <Link
                      to={`/applications/${application.id}`}
                      className="text-indigo-600 hover:text-indigo-900 dark:text-indigo-400 dark:hover:text-indigo-300 inline-flex items-center"
                    >
                      View Details
                      <ExternalLink className="ml-1 h-4 w-4" />
                    </Link>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
} 