import { Link } from 'react-router-dom';

export function Terms() {
  return (
    <div className="bg-gray-50 dark:bg-gray-900 min-h-screen">
      <div className="max-w-4xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
        <div className="bg-white dark:bg-gray-800 rounded-lg shadow-sm p-8">
          <h1 className="text-4xl font-bold text-gray-900 dark:text-white mb-4">Terms of Service</h1>
          <p className="text-lg text-gray-600 dark:text-gray-400 mb-8">
            Last updated: {new Date().toLocaleDateString()}
          </p>

          <div className="space-y-8">
            <section>
              <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-4">1. Agreement to Terms</h2>
              <p className="text-gray-600 dark:text-gray-400">
                By accessing or using BlitzApply's services, you agree to be bound by these Terms of Service. If you disagree with any part of these terms, you may not access our service.
              </p>
            </section>

            <section>
              <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-4">2. Description of Service</h2>
              <p className="text-gray-600 dark:text-gray-400 mb-4">
                BlitzApply provides a job application assistance service that includes:
              </p>
              <ul className="list-disc pl-6 text-gray-600 dark:text-gray-400 space-y-2">
                <li>Processing and submitting job applications on behalf of users</li>
                <li>Managing application tracking and status updates</li>
                <li>Providing agent assistance for application processing</li>
                <li>Credit-based system for service usage</li>
              </ul>
            </section>

            <section>
              <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-4">3. User Accounts</h2>
              <div className="space-y-4">
                <p className="text-gray-600 dark:text-gray-400">
                  To use our services, you must:
                </p>
                <ul className="list-disc pl-6 text-gray-600 dark:text-gray-400 space-y-2">
                  <li>Be at least 16 years of age</li>
                  <li>Register for an account with accurate information</li>
                  <li>Maintain the security of your account credentials</li>
                  <li>Notify us immediately of any unauthorized access</li>
                </ul>
              </div>
            </section>

            <section>
              <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-4">4. Credits and Payments</h2>
              <div className="space-y-4">
                <p className="text-gray-600 dark:text-gray-400">
                  Our service operates on a credit-based system:
                </p>
                <ul className="list-disc pl-6 text-gray-600 dark:text-gray-400 space-y-2">
                  <li>Credits must be purchased to use application services</li>
                  <li>Each application submission costs 3 credits</li>
                  <li>Credits are non-refundable once used</li>
                  <li>Unused credits expire after their validity period</li>
                  <li>New users receive 15 free credits upon registration</li>
                </ul>
              </div>
            </section>

            <section>
              <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-4">5. User Responsibilities</h2>
              <div className="space-y-4">
                <p className="text-gray-600 dark:text-gray-400">
                  You agree to:
                </p>
                <ul className="list-disc pl-6 text-gray-600 dark:text-gray-400 space-y-2">
                  <li>Provide accurate and truthful information in applications</li>
                  <li>Respond promptly to agent requests for additional information</li>
                  <li>Not misrepresent your qualifications or experience</li>
                  <li>Not use the service for any unlawful purpose</li>
                  <li>Maintain professional communication with agents</li>
                </ul>
              </div>
            </section>

            <section>
              <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-4">6. Service Limitations</h2>
              <div className="space-y-4">
                <p className="text-gray-600 dark:text-gray-400">
                  Please be aware that:
                </p>
                <ul className="list-disc pl-6 text-gray-600 dark:text-gray-400 space-y-2">
                  <li>We cannot guarantee job placement or interview opportunities</li>
                  <li>Application processing times may vary based on complexity</li>
                  <li>Some job platforms may have specific requirements or limitations</li>
                  <li>Service availability may be affected by maintenance or updates</li>
                </ul>
              </div>
            </section>

            <section>
              <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-4">7. Intellectual Property</h2>
              <p className="text-gray-600 dark:text-gray-400">
                The service, content, and trademarks are owned by BlitzApply. You may not use our materials without our prior written consent.
              </p>
            </section>

            <section>
              <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-4">8. Termination</h2>
              <p className="text-gray-600 dark:text-gray-400">
                We may terminate or suspend your account immediately, without prior notice, for conduct that we believe violates these Terms or is harmful to other users, us, or third parties, or for any other reason.
              </p>
            </section>

            <section>
              <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-4">9. Limitation of Liability</h2>
              <p className="text-gray-600 dark:text-gray-400">
                BlitzApply shall not be liable for any indirect, incidental, special, consequential, or punitive damages resulting from your use of or inability to use the service.
              </p>
            </section>

            <section>
              <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-4">10. Changes to Terms</h2>
              <p className="text-gray-600 dark:text-gray-400">
                We reserve the right to modify these terms at any time. We will notify users of any material changes via email or through the service.
              </p>
            </section>

            <section>
              <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-4">11. Contact Information</h2>
              <p className="text-gray-600 dark:text-gray-400">
                For questions about these Terms, please{' '}
                <Link to="/contact" className="text-indigo-600 dark:text-indigo-400 hover:underline">
                  contact us
                </Link>.
              </p>
            </section>

            <div className="mt-12 p-4 bg-gray-100 dark:bg-gray-700 rounded-lg">
              <p className="text-sm text-gray-600 dark:text-gray-400">
                By using BlitzApply, you acknowledge that you have read and understood these Terms of Service and agree to be bound by them. Please also review our{' '}
                <Link to="/privacy" className="text-indigo-600 dark:text-indigo-400 hover:underline">
                  Privacy Policy
                </Link>.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
} 