import { useState, useEffect } from 'react';
import { useAuth } from '../../hooks/useAuth';
import { collection, query, where, orderBy, limit, getDocs } from 'firebase/firestore';
import { db } from '../../lib/firebase';
import { format } from 'date-fns';
import { Loader2, ExternalLink, RefreshCw } from 'lucide-react';
import { Link } from 'react-router-dom';

interface Payment {
  orderId: string;
  packageType: 'plus' | 'pro';
  amount: number;
  credits: number;
  timestamp: Date;
  packageName: string;
}

export function RecentPayments() {
  const { user } = useAuth();
  const [payments, setPayments] = useState<Payment[]>([]);
  const [loading, setLoading] = useState(true);
  const [refreshing, setRefreshing] = useState(false);

  const fetchPayments = async () => {
    if (!user) return;

    try {
      const q = query(
        collection(db, 'userPayments'),
        where('userId', '==', user.uid),
        orderBy('timestamp', 'desc'),
        limit(3)
      );

      const snapshot = await getDocs(q);
      const paymentData = snapshot.docs.map(doc => ({
        ...doc.data(),
        timestamp: doc.data().timestamp.toDate(),
      })) as Payment[];

      setPayments(paymentData);
    } catch (error) {
      console.error('Error fetching recent payments:', error);
    } finally {
      setLoading(false);
      setRefreshing(false);
    }
  };

  useEffect(() => {
    fetchPayments();
  }, [user]);

  const handleRefresh = async () => {
    setRefreshing(true);
    await fetchPayments();
  };

  if (loading) {
    return (
      <div className="flex justify-center py-4">
        <Loader2 className="h-6 w-6 animate-spin text-indigo-600" />
      </div>
    );
  }

  if (payments.length === 0) {
    return (
      <div className="text-center py-4 text-gray-500 dark:text-gray-400">
        No payment history available
      </div>
    );
  }

  return (
    <div className="space-y-4">
      <div className="flex justify-between items-center mb-4">
        <h3 className="text-lg font-medium text-gray-900 dark:text-white">Recent Payments</h3>
        <div className="flex items-center space-x-4">
          <button
            onClick={handleRefresh}
            disabled={refreshing}
            className="text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-300"
            title="Refresh payments"
          >
            <RefreshCw className={`h-4 w-4 ${refreshing ? 'animate-spin' : ''}`} />
          </button>
          <Link
            to="/payments"
            className="text-sm text-indigo-600 dark:text-indigo-400 hover:text-indigo-500 flex items-center"
          >
            View All
            <ExternalLink className="h-4 w-4 ml-1" />
          </Link>
        </div>
      </div>

      <div className="rounded-lg overflow-hidden">
        <div className="bg-gray-700/50">
          <div className="grid grid-cols-4 px-6 py-3">
            <div className="text-left text-xs font-medium text-gray-400 uppercase">Date</div>
            <div className="text-left text-xs font-medium text-gray-400 uppercase">Credits</div>
            <div className="text-left text-xs font-medium text-gray-400 uppercase">Amount</div>
            <div className="text-left text-xs font-medium text-gray-400 uppercase">Order ID</div>
          </div>
        </div>
        {payments.map((payment) => (
          <div key={payment.orderId} className="bg-gray-900/50">
            <div className="grid grid-cols-4 px-6 py-4">
              <div className="text-sm text-gray-300">{format(payment.timestamp, "MMM d, yyyy h:mm a")}</div>
              <div className="text-sm text-gray-300">{payment.credits} CP</div>
              <div className="text-sm text-gray-300">${payment.amount}</div>
              <div className="text-sm text-gray-300">{payment.orderId}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
} 