import { useState, useEffect } from 'react';
import { useAuth } from '../hooks/useAuth';
import { collection, query, where, orderBy, getDocs } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { Application } from '../types/application';
import { format } from 'date-fns';
import { Loader2, ExternalLink, Search, X, CheckCircle, Clock, AlertCircle, XCircle, Send, PlusCircle } from 'lucide-react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import React from 'react';

type TabType = 'all' | 'in_progress' | 'pending' | 'submitted' | 'completed' | 'cancelled';

export function Completions() {
  const { user } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const [applications, setApplications] = useState<Application[]>([]);
  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState<TabType>('all');
  const [searchTerm, setSearchTerm] = useState('');

  // Parse status from URL query parameters
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const statusParam = params.get('status');
    
    if (statusParam && ['all', 'in_progress', 'pending', 'submitted', 'completed', 'cancelled'].includes(statusParam)) {
      setActiveTab(statusParam as TabType);
    } else {
      setActiveTab('all');
    }
  }, [location.search]);

  // Update URL when tab changes
  const handleTabChange = (tab: TabType) => {
    setActiveTab(tab);
    if (tab === 'all') {
      navigate('/completions');
    } else {
      navigate(`/completions?status=${tab}`);
    }
  };

  useEffect(() => {
    if (!user) return;
    loadApplications();
  }, [user]);

  const loadApplications = async () => {
    if (!user) return;

    try {
      setLoading(true);
      const applicationsRef = collection(db, 'applications');
      const userApplicationsQuery = query(
        applicationsRef,
        where('userId', '==', user.uid),
        orderBy('updatedAt', 'desc')
      );
      
      const querySnapshot = await getDocs(userApplicationsQuery);
      
      const applicationsData = querySnapshot.docs
        .map(doc => ({
          id: doc.id,
          ...doc.data(),
        })) as Application[];
      
      setApplications(applicationsData);
    } catch (error) {
      console.error('Error loading applications:', error);
    } finally {
      setLoading(false);
    }
  };

  const filteredApplications = applications.filter(app => {
    const matchesSearch = searchTerm === '' || 
      app.company.toLowerCase().includes(searchTerm.toLowerCase()) ||
      app.position.toLowerCase().includes(searchTerm.toLowerCase()) ||
      app.id.toLowerCase().includes(searchTerm.toLowerCase());

    switch (activeTab) {
      case 'in_progress':
        return matchesSearch && app.status === 'in_progress';
      case 'pending':
        return matchesSearch && app.status === 'pending';
      case 'submitted':
        return matchesSearch && app.status === 'submitted';
      case 'completed':
        return matchesSearch && app.status === 'completed';
      case 'cancelled':
        return matchesSearch && app.status === 'cancelled';
      default:
        return matchesSearch;
    }
  });

  const tabs: { id: TabType; name: string; count: number }[] = [
    { 
      id: 'all', 
      name: 'All', 
      count: applications.length
    },
    { 
      id: 'in_progress', 
      name: 'In Progress', 
      count: applications.filter(app => app.status === 'in_progress').length
    },
    { 
      id: 'pending', 
      name: 'Pending', 
      count: applications.filter(app => app.status === 'pending').length
    },
    { 
      id: 'submitted', 
      name: 'Submitted', 
      count: applications.filter(app => app.status === 'submitted').length
    },
    { 
      id: 'completed', 
      name: 'Completed', 
      count: applications.filter(app => app.status === 'completed').length
    },
    {
      id: 'cancelled',
      name: 'Cancelled',
      count: applications.filter(app => app.status === 'cancelled').length
    }
  ];

  if (loading) {
    return (
      <div className="flex justify-center py-12">
        <Loader2 className="h-8 w-8 animate-spin text-indigo-600 dark:text-indigo-400" />
      </div>
    );
  }

  const getStatusDisplay = (status: string) => {
    switch (status) {
      case 'completed':
        return {
          text: 'Completed',
          className: 'bg-green-100 text-green-800 dark:bg-green-900/50 dark:text-green-300',
          tooltip: 'Application has been successfully submitted',
          icon: CheckCircle
        };
      case 'in_progress':
        return {
          text: 'In Progress',
          className: 'bg-yellow-100 text-yellow-800 dark:bg-yellow-900/50 dark:text-yellow-300',
          tooltip: 'Application is being actively processed by an agent',
          icon: Clock
        };
      case 'pending':
        return {
          text: 'Pending',
          className: 'bg-orange-100 text-orange-800 dark:bg-orange-900/50 dark:text-orange-300',
          tooltip: 'Additional Information is required to complete the application',
          icon: AlertCircle
        };
      case 'submitted':
        return {
          text: 'Submitted',
          className: 'bg-blue-100 text-blue-800 dark:bg-blue-900/50 dark:text-blue-300',
          tooltip: 'Application submitted. Waiting to be picked up by an agent',
          icon: Send
        };
      case 'cancelled':
        return {
          text: 'Cancelled',
          className: 'bg-red-100 text-red-800 dark:bg-red-900/50 dark:text-red-300',
          tooltip: 'Application process was cancelled and refunded',
          icon: XCircle
        };
      default:
        return {
          text: status.charAt(0).toUpperCase() + status.slice(1),
          className: 'bg-gray-100 text-gray-800 dark:bg-gray-900/30 dark:text-gray-300',
          tooltip: 'Unknown application status',
          icon: AlertCircle
        };
    }
  };

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <div className="mb-8 flex flex-col sm:flex-row sm:items-center sm:justify-between">
        <div>
          <h1 className="text-3xl font-bold text-gray-900 dark:text-white">My Applications</h1>
          <p className="mt-2 text-gray-600 dark:text-gray-400">
            View and track all your applications. Click on an Application to view Application Details.
          </p>
        </div>
        <Link 
          to="/submit-application" 
          className="mt-4 sm:mt-0 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 dark:ring-offset-gray-900 transition-colors duration-200"
        >
          <PlusCircle className="h-5 w-5 mr-2" />
          Submit New Application
        </Link>
      </div>

      {/* Search Bar */}
      <div className="mb-6">
        <div className="relative">
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <Search className="h-5 w-5 text-gray-400" />
          </div>
          <input
            type="text"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder="Search by company, position, or application ID..."
            className="block w-full pl-10 pr-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md leading-5 bg-white dark:bg-gray-700 text-gray-900 dark:text-white placeholder-gray-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
          {searchTerm && (
            <button
              onClick={() => setSearchTerm('')}
              className="absolute inset-y-0 right-0 pr-3 flex items-center"
            >
              <X className="h-5 w-5 text-gray-400 hover:text-gray-500" />
            </button>
          )}
        </div>
      </div>

      {/* Tabs - Made scrollable for mobile */}
      <div className="border-b border-gray-200 dark:border-gray-700 mb-6 overflow-x-auto">
        <nav className="-mb-px flex space-x-6 md:space-x-8 whitespace-nowrap pb-1 px-1">
          {tabs.map((tab) => (
            <button
              key={tab.id}
              onClick={() => handleTabChange(tab.id)}
              title={tab.id === 'all' ? 'View all applications' :
                    tab.id === 'in_progress' ? 'Applications being actively processed' :
                    tab.id === 'pending' ? 'Applications waiting for your input' :
                    tab.id === 'submitted' ? 'Applications that have been submitted' :
                    tab.id === 'completed' ? 'Applications that have been completed' :
                    'Applications that have been cancelled'}
              className={`
                whitespace-nowrap pb-3 px-1 border-b-2 font-medium text-sm flex-shrink-0
                ${activeTab === tab.id
                  ? 'border-indigo-500 text-indigo-600 dark:text-indigo-400'
                  : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 dark:text-gray-400 dark:hover:text-gray-300'
                }
              `}
            >
              {tab.name} <span className="ml-1">({tab.count})</span>
            </button>
          ))}
        </nav>
      </div>

      {/* Applications - Table for desktop, Cards for mobile */}
      <div className="hidden md:block bg-white dark:bg-gray-800 shadow overflow-hidden sm:rounded-lg">
        <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
          <thead className="bg-gray-100 dark:bg-gray-700/50">
            <tr>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-700 dark:text-gray-100 uppercase tracking-wider bg-gray-100 dark:bg-gray-700/50 border-b border-gray-200 dark:border-gray-700">
                Application Details
              </th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-700 dark:text-gray-100 uppercase tracking-wider bg-gray-100 dark:bg-gray-700/50 border-b border-gray-200 dark:border-gray-700">
                Status
              </th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-700 dark:text-gray-100 uppercase tracking-wider bg-gray-100 dark:bg-gray-700/50 border-b border-gray-200 dark:border-gray-700">
                Dates
              </th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-700 dark:text-gray-100 uppercase tracking-wider bg-gray-100 dark:bg-gray-700/50 border-b border-gray-200 dark:border-gray-700">
                Actions
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 dark:divide-gray-700">
            {filteredApplications.length === 0 ? (
              <tr>
                <td colSpan={4} className="px-6 py-4 text-center text-gray-500 dark:text-gray-400">
                  No applications found
                </td>
              </tr>
            ) : (
              filteredApplications.map((application) => (
                <tr 
                  key={application.id} 
                  onClick={() => navigate(`/applications/${application.id}`)}
                  className="cursor-pointer hover:bg-gray-50 dark:hover:bg-gray-700/50 transition-colors duration-200"
                >
                  <td className="px-6 py-4">
                    <div className="flex flex-col">
                      <span className="text-sm font-medium text-gray-900 dark:text-white">
                        {application.company}
                      </span>
                      <span className="text-sm text-gray-500 dark:text-gray-400">
                        {application.position}
                      </span>
                      <span className="text-xs text-gray-400 dark:text-gray-500 font-mono mt-1">
                        {application.id}
                      </span>
                    </div>
                  </td>
                  <td className="px-6 py-4">
                    <span 
                      className={`inline-flex items-center px-2.5 py-1 rounded-full text-xs font-medium transform scale-[1.21]
                      ${getStatusDisplay(application.status).className}`}
                      title={getStatusDisplay(application.status).tooltip}
                    >
                      {React.createElement(getStatusDisplay(application.status).icon, { className: "h-3 w-3 mr-1.5" })}
                      {getStatusDisplay(application.status).text}
                    </span>
                  </td>
                  <td className="px-6 py-4">
                    <div className="flex flex-col text-sm text-gray-500 dark:text-gray-400">
                      <span>Created: {format(new Date(application.createdAt), 'MMM d, yyyy')}</span>
                      <span>Updated: {format(new Date(application.updatedAt), 'MMM d, yyyy')}</span>
                      {application.completedAt && (
                        <span>Completed: {format(new Date(application.completedAt), 'MMM d, yyyy')}</span>
                      )}
                    </div>
                  </td>
                  <td className="px-6 py-4">
                    <div className="text-indigo-600 hover:text-indigo-900 dark:text-indigo-400 dark:hover:text-indigo-300 inline-flex items-center">
                      View Details
                      <ExternalLink className="ml-1 h-4 w-4" />
                    </div>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>

      {/* Mobile Card Layout */}
      <div className="md:hidden space-y-4">
        {filteredApplications.length === 0 ? (
          <div className="bg-white dark:bg-gray-800 shadow rounded-lg p-6 text-center text-gray-500 dark:text-gray-400">
            No applications found
          </div>
        ) : (
          filteredApplications.map((application) => (
            <Link
              key={application.id}
              to={`/applications/${application.id}`}
              className="block bg-white dark:bg-gray-800 shadow rounded-lg overflow-hidden hover:shadow-md transition-all duration-200"
            >
              <div className="p-4 border-b border-gray-200 dark:border-gray-700 flex items-start justify-between">
                <div>
                  <h3 className="text-lg font-medium text-gray-900 dark:text-white">
                    {application.company}
                  </h3>
                  <p className="text-sm text-gray-600 dark:text-gray-400">
                    {application.position}
                  </p>
                </div>
                <span 
                  className={`inline-flex items-center px-2.5 py-1 rounded-full text-xs font-medium transform scale-[1.21]
                  ${getStatusDisplay(application.status).className}`}
                  title={getStatusDisplay(application.status).tooltip}
                >
                  {React.createElement(getStatusDisplay(application.status).icon, { className: "h-3 w-3 mr-1.5" })}
                  {getStatusDisplay(application.status).text}
                </span>
              </div>
              <div className="p-4 text-sm text-gray-600 dark:text-gray-400 space-y-1">
                <div className="flex justify-between">
                  <span>Created:</span>
                  <span>{format(new Date(application.createdAt), 'MMM d, yyyy')}</span>
                </div>
                <div className="flex justify-between">
                  <span>Updated:</span>
                  <span>{format(new Date(application.updatedAt), 'MMM d, yyyy')}</span>
                </div>
                {application.completedAt && (
                  <div className="flex justify-between">
                    <span>Completed:</span>
                    <span>{format(new Date(application.completedAt), 'MMM d, yyyy')}</span>
                  </div>
                )}
                <div className="pt-2 flex justify-end">
                  <span className="text-indigo-600 hover:text-indigo-900 dark:text-indigo-400 dark:hover:text-indigo-300 inline-flex items-center">
                    View Details
                    <ExternalLink className="ml-1 h-4 w-4" />
                  </span>
                </div>
              </div>
            </Link>
          ))
        )}
      </div>
    </div>
  );
} 