import { Spinner } from './Spinner';

interface LoadingOverlayProps {
  message?: string;
}

export function LoadingOverlay({ message = 'Loading...' }: LoadingOverlayProps) {
  return (
    <div className="fixed inset-0 bg-gray-900/50 dark:bg-gray-900/70 backdrop-blur-sm flex items-center justify-center z-50">
      <div className="bg-white dark:bg-gray-800 rounded-lg p-8 shadow-xl flex flex-col items-center space-y-4">
        <Spinner size="lg" />
        <p className="text-gray-700 dark:text-gray-300 text-lg font-medium">{message}</p>
      </div>
    </div>
  );
} 