import { Dialog } from '@headlessui/react';
import { AlertTriangle, X } from 'lucide-react';
import { Button } from '../../ui/Button';

interface UnassignConfirmationModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  isSubmitting?: boolean;
}

export function UnassignConfirmationModal({
  isOpen,
  onClose,
  onConfirm,
  isSubmitting = false
}: UnassignConfirmationModalProps) {
  const handleConfirm = async () => {
    await onConfirm();
    onClose();
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      className="fixed inset-0 z-50 overflow-y-auto"
    >
      <div className="flex min-h-screen items-center justify-center">
        <Dialog.Overlay className="fixed inset-0 bg-black/30 dark:bg-black/50" />
        
        <div className="relative mx-auto max-w-md rounded-lg bg-white p-6 dark:bg-gray-800">
          {/* Close button */}
          <button
            onClick={onClose}
            className="absolute top-4 right-4 text-gray-400 hover:text-gray-500 dark:text-gray-500 dark:hover:text-gray-400"
          >
            <X className="h-5 w-5" />
          </button>

          <div className="flex items-center gap-4">
            <div className="flex h-12 w-12 items-center justify-center rounded-full bg-yellow-100 dark:bg-yellow-900">
              <AlertTriangle className="h-6 w-6 text-yellow-600 dark:text-yellow-400" />
            </div>
            <Dialog.Title className="text-lg font-medium text-gray-900 dark:text-white">
              Confirm Agent Unassignment
            </Dialog.Title>
          </div>

          <div className="mt-4">
            <p className="text-sm text-gray-500 dark:text-gray-400">
              Are you sure you want to unassign the agent from this application? The application will return to the submitted state and be available for any agent to pick up.
            </p>
          </div>

          <div className="mt-6 flex justify-end">
            <Button
              variant="secondary"
              onClick={handleConfirm}
              disabled={isSubmitting}
              className="bg-gray-100 hover:bg-gray-200 text-gray-700 dark:bg-gray-700 dark:hover:bg-gray-600 dark:text-gray-200"
            >
              {isSubmitting ? 'Unassigning...' : 'Unassign Agent'}
            </Button>
          </div>
        </div>
      </div>
    </Dialog>
  );
} 