import { Application } from '../../../types/application';
import { Button } from '../../ui/Button';
import { PendingReasonModal } from './PendingReasonModal';
import { CancellationReasonModal } from './CancellationReasonModal';
import { CompletionConfirmationModal } from './CompletionConfirmationModal';
import { UnassignConfirmationModal } from './UnassignConfirmationModal';
import { useState } from 'react';

interface ApplicationActionsProps {
  application: Application;
  isCustomer: boolean;
  isAssignedAgent: boolean;
  userType: string;
  updating: boolean;
  onCancel: (reason: string) => void;
  onPickUp: () => void;
  onUpdateStatus: (status: 'completed' | 'pending' | 'in_progress', notes?: string, files?: File[]) => void;
  onUnassign?: () => void;
}

export function ApplicationActions({
  application,
  isCustomer,
  isAssignedAgent,
  userType,
  updating,
  onCancel,
  onPickUp,
  onUpdateStatus,
  onUnassign
}: ApplicationActionsProps) {
  const [showPendingModal, setShowPendingModal] = useState(false);
  const [showCancellationModal, setShowCancellationModal] = useState(false);
  const [showCompletionModal, setShowCompletionModal] = useState(false);
  const [showUnassignModal, setShowUnassignModal] = useState(false);

  const isAdmin = userType === 'admin';
  const canShowAgentActions = isAssignedAgent || isAdmin;

  const handlePendingConfirm = (reason: string, files?: File[]) => {
    onUpdateStatus('pending', reason, files);
    setShowPendingModal(false);
  };

  const handleCancellationConfirm = (reason: string) => {
    onCancel(reason);
    setShowCancellationModal(false);
  };

  const handleCompletionConfirm = () => {
    onUpdateStatus('completed');
    setShowCompletionModal(false);
  };

  const handleUnassignConfirm = () => {
    onUnassign?.();
    setShowUnassignModal(false);
  };

  return (
    <>
      <div className="flex flex-wrap sm:flex-nowrap gap-2 sm:space-x-3">
        {/* Agent and Admin Actions */}
        {!isCustomer && (
          <>
            {application.status === 'submitted' && (
              <Button
                variant="primary"
                onClick={onPickUp}
                disabled={updating}
                className="w-full sm:w-auto text-sm whitespace-nowrap"
              >
                Pick Up Application
              </Button>
            )}
            {canShowAgentActions && (
              <>
                {application.status === 'in_progress' && (
                  <>
                    <Button
                      variant="primary"
                      onClick={() => setShowPendingModal(true)}
                      disabled={updating}
                      className="w-full sm:w-auto text-sm whitespace-nowrap bg-amber-800 hover:bg-amber-900 dark:bg-yellow-800 dark:hover:bg-yellow-900 text-white border-transparent shadow-sm font-medium rounded-md transition-all duration-200 flex items-center justify-center px-4 py-2"
                    >
                      Request Additional Info
                    </Button>
                    <Button
                      variant="primary"
                      onClick={() => setShowCompletionModal(true)}
                      disabled={updating}
                      className="w-full sm:w-auto text-sm whitespace-nowrap bg-green-700 hover:bg-green-800 dark:bg-green-800 dark:hover:bg-green-900 text-white border-transparent shadow-sm font-medium rounded-md transition-all duration-200 flex items-center justify-center px-4 py-2"
                    >
                      Mark as Complete
                    </Button>
                  </>
                )}
                {application.status === 'pending' && (isAssignedAgent || isAdmin) && (
                  <Button
                    variant="primary"
                    onClick={() => onUpdateStatus('in_progress')}
                    disabled={updating}
                    className="w-full sm:w-auto text-sm whitespace-nowrap shadow-sm font-medium transition-all duration-200"
                  >
                    Resume Processing
                  </Button>
                )}
              </>
            )}
            {/* Admin Unassign Action */}
            {isAdmin && application.agentId && (application.status === 'in_progress' || application.status === 'pending') && (
              <Button
                variant="secondary"
                onClick={() => setShowUnassignModal(true)}
                disabled={updating}
                className="w-full sm:w-auto bg-slate-500 hover:bg-slate-600 dark:bg-slate-600 dark:hover:bg-slate-700 text-white border-transparent shadow-sm text-sm font-medium rounded-md transition-all duration-200 flex items-center justify-center px-4 py-2 active:bg-slate-800"
              >
                Unassign Agent
              </Button>
            )}
          </>
        )}

        {/* Customer Actions */}
        {isCustomer && application.status !== 'cancelled' && (
          <div className="flex items-center">
            {/* Removed the message about contacting support */}
          </div>
        )}
      </div>

      {/* Modals */}
      {showPendingModal && (
        <PendingReasonModal
          isOpen={showPendingModal}
          onClose={() => setShowPendingModal(false)}
          onConfirm={handlePendingConfirm}
        />
      )}

      {showCancellationModal && (
        <CancellationReasonModal
          isOpen={showCancellationModal}
          onClose={() => setShowCancellationModal(false)}
          onConfirm={handleCancellationConfirm}
        />
      )}

      {showCompletionModal && (
        <CompletionConfirmationModal
          isOpen={showCompletionModal}
          onClose={() => setShowCompletionModal(false)}
          onConfirm={handleCompletionConfirm}
        />
      )}

      {showUnassignModal && (
        <UnassignConfirmationModal
          isOpen={showUnassignModal}
          onClose={() => setShowUnassignModal(false)}
          onConfirm={handleUnassignConfirm}
          isSubmitting={updating}
        />
      )}
    </>
  );
}