import { Link } from 'react-router-dom';
import { User, HelpCircle, PlusCircle } from 'lucide-react';
import { GiTwoCoins } from 'react-icons/gi';
import { SignOutButton } from './SignOutButton';
import { UserProfile } from '../../types/user';
import { useAuth } from '../../hooks/useAuth';

interface NavLinksProps {
  userProfile: UserProfile | null;
  isMobile?: boolean;
}

export function NavLinks({ userProfile, isMobile = false }: NavLinksProps) {
  const { loading } = useAuth();
  const commonLinkClasses = isMobile
    ? "block w-full px-4 py-3 text-base font-medium text-gray-100 hover:bg-gray-700/50 dark:hover:bg-gray-600/50 border-b border-gray-700/50 dark:border-gray-600/50"
    : "text-white hover:text-white px-3 py-2 rounded-md text-sm font-medium hover:bg-gray-800 dark:hover:bg-gray-600 transition-colors duration-200";

  // Show nothing while loading
  if (loading) {
    return null;
  }

  // If no user profile, show public navigation
  if (!userProfile) {
    return (
      <div className={isMobile ? "py-2" : "flex items-center space-x-2"}>
        <Link to="/blog" className={commonLinkClasses}>
          Blog
        </Link>
      </div>
    );
  }

  return (
    <div className={isMobile ? "flex flex-col w-full" : "flex items-center space-x-2"}>
      {userProfile?.userType === 'customer' && (
        <>
          <Link to="/dashboard" className={commonLinkClasses}>
            Dashboard
          </Link>
          <Link to="/job-profiles" className={commonLinkClasses}>
            Job Profiles
          </Link>
          <Link to="/applications" className={commonLinkClasses}>
            Applications
          </Link>
          <Link to="/completions" className={commonLinkClasses}>
            Completions
          </Link>
          <Link
            to="/credits"
            className={`${commonLinkClasses} flex items-center`}
          >
            {isMobile ? (
              <>
                <span>Credits</span>
                <div className="flex items-center ml-auto">
                  <GiTwoCoins className="h-5 w-5 text-yellow-400 mr-1" />
                  <span>{userProfile.credits || 0} CP</span>
                </div>
              </>
            ) : (
              <div className="flex items-center">
                <GiTwoCoins className="h-5 w-5 text-yellow-400 mr-1" />
                <span>{userProfile.credits || 0} CP</span>
              </div>
            )}
          </Link>
          <Link to="/help" className={commonLinkClasses}>
            {isMobile ? "Help & Support" : <HelpCircle className="h-5 w-5" />}
          </Link>
        </>
      )}

      {userProfile?.userType === 'agent' && (
        <>
          <Link to="/agent/dashboard" className={commonLinkClasses}>
            Dashboard
          </Link>
          <Link to="/agent/applications" className={commonLinkClasses}>
            Applications
          </Link>
          <Link to="/agent/completions" className={commonLinkClasses}>
            Completions
          </Link>
        </>
      )}

      {userProfile?.userType === 'admin' && (
        <>
          <Link to="/admin/dashboard" className={commonLinkClasses}>
            Dashboard
          </Link>
          <Link to="/admin/applications" className={commonLinkClasses}>
            Applications
          </Link>
          <Link to="/admin/agents" className={commonLinkClasses}>
            Agents
          </Link>
          <Link to="/admin/customers" className={commonLinkClasses}>
            Customers
          </Link>
          <Link to="/admin/submit-application" className={commonLinkClasses}>
            Submit Application
          </Link>
        </>
      )}

      <Link to="/profile" className={commonLinkClasses}>
        {isMobile ? "Profile" : <User className="h-5 w-5" />}
      </Link>

      {isMobile ? (
        <SignOutButton variant="full" className={commonLinkClasses} />
      ) : (
        <SignOutButton />
      )}
    </div>
  );
}