import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';
import { LoadingOverlay } from '../ui/LoadingOverlay';

interface CustomerRouteProps {
  children: React.ReactNode;
}

export function CustomerRoute({ children }: CustomerRouteProps) {
  const { user, userProfile, loading } = useAuth();
  const location = useLocation();

  // Don't redirect while loading
  if (loading) {
    return <LoadingOverlay message="Loading your profile..." />;
  }

  // If not logged in, redirect to login
  if (!user) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  // If logged in but not a customer, redirect to appropriate dashboard
  if (userProfile && userProfile.userType !== 'customer') {
    if (userProfile.userType === 'admin') {
      return <Navigate to="/admin/dashboard" replace />;
    }
    if (userProfile.userType === 'agent') {
      return <Navigate to="/agent/dashboard" replace />;
    }
  }

  // If customer and phone is verified, redirect away from phone verification page
  if (userProfile && 
      userProfile.userType === 'customer' && 
      userProfile.isPhoneVerified && 
      location.pathname === '/phone-verification') {
    return <Navigate to="/dashboard" replace />;
  }

  // If customer but phone not verified, redirect to phone verification
  // except if already on the phone verification page
  if (userProfile && 
      userProfile.userType === 'customer' && 
      !userProfile.isPhoneVerified && 
      location.pathname !== '/phone-verification') {
    return <Navigate to="/phone-verification" replace />;
  }

  return <>{children}</>;
} 