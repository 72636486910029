import { useAuth } from '../hooks/useAuth';
import { CustomerProfile } from '../components/profiles/CustomerProfile';
import { StaffProfile } from '../components/profiles/StaffProfile';

export function Profile() {
  const { userProfile } = useAuth();

  if (!userProfile) return null;

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      {userProfile.userType === 'customer' ? (
        <CustomerProfile />
      ) : (
        <StaffProfile />
      )}
    </div>
  );
}