import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';
import { db } from '../../lib/firebase';
import { doc, getDoc, setDoc, updateDoc } from 'firebase/firestore';
import { v4 as uuidv4 } from 'uuid';
import toast from 'react-hot-toast';
import { GeneralApplication, GeneralApplicationFormData } from '../../types/generalApplication';

const REQUIRED_FIELDS = [
  'firstName',
  'lastName',
  'countryCallingCode',
  'phoneNumber',
  'street',
  'city',
  'state',
  'zipCode',
  'country',
  'visaStatus',
  'availabilityNoticePeriod',
  'preferredWorkEnvironment'
];

export function GeneralApplicationForm() {
  const { id } = useParams();
  const { user } = useAuth();
  const navigate = useNavigate();
  
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState<GeneralApplicationFormData>({
    // Personal Information
    firstName: '',
    middleName: '',
    lastName: '',
    preferredFirstName: '',
    
    // Contact Information
    primaryEmail: user?.email || '',
    secondaryEmail: '',
    countryCallingCode: '',
    phoneNumber: '',
    
    // Online Presence
    linkedinUrl: '',
    githubUrl: '',
    customUrls: [],
    
    // Address Information
    street: '',
    aptSuiteUnit: '',
    city: '',
    state: '',
    zipCode: '',
    country: '',
    
    // Equal Employment Opportunity
    gender: '',
    raceEthnicity: '',
    veteranStatus: '',
    disabilityStatus: '',
    
    // Work Authorization & Security
    canObtainSecurityClearance: false,
    securityClearanceLevel: '',
    isFormerGovernmentEmployee: false,
    currentCitizenship: '',
    isAuthorizedToWork: false,
    requiresSponsorship: false,
    isSTEMDegree: false,
    currentlyInUS: false,
    willingToRelocate: false,
    canRelocateAtOwnExpense: false,
    employmentAuthStatus: '',
    isEligibleForSTEMExtension: false,
    requiresCPTAuthorization: '',
    proficientLanguages: '',
    totalWorkExperience: '',
    employmentAuthStartDate: '',
    employmentAuthEndDate: '',
    workAuthorization: '',
    
    // Education History
    educationHistory: [],
    
    // Work History
    workHistory: [],
    
    // Work Details
    visaStatus: '',
    availabilityNoticePeriod: '',
    preferredWorkEnvironment: ''
  });

  useEffect(() => {
    if (id && user) {
      loadGeneralApplication();
    }
  }, [id, user]);

  async function loadGeneralApplication() {
    if (!id || !user) return;

    try {
      const docRef = doc(db, 'generalApplications', id);
      const docSnap = await getDoc(docRef);
      
      if (docSnap.exists()) {
        const data = docSnap.data() as GeneralApplication;
        setFormData({
          ...formData,
          firstName: data.firstName || '',
          middleName: data.middleName || '',
          lastName: data.lastName || '',
          preferredFirstName: data.preferredFirstName || '',
          primaryEmail: data.primaryEmail || user.email || '',
          secondaryEmail: data.secondaryEmail || '',
          countryCallingCode: data.countryCallingCode || '',
          phoneNumber: data.phoneNumber || '',
          linkedinUrl: data.linkedinUrl || '',
          githubUrl: data.githubUrl || '',
          customUrls: data.customUrls || [],
          street: data.street || '',
          aptSuiteUnit: data.aptSuiteUnit || '',
          city: data.city || '',
          state: data.state || '',
          zipCode: data.zipCode || '',
          country: data.country || '',
          gender: data.gender || '',
          raceEthnicity: data.raceEthnicity || '',
          veteranStatus: data.veteranStatus || '',
          disabilityStatus: data.disabilityStatus || '',
          canObtainSecurityClearance: data.canObtainSecurityClearance || false,
          securityClearanceLevel: data.securityClearanceLevel || '',
          isFormerGovernmentEmployee: data.isFormerGovernmentEmployee || false,
          currentCitizenship: data.currentCitizenship || '',
          isAuthorizedToWork: data.isAuthorizedToWork || false,
          requiresSponsorship: data.requiresSponsorship || false,
          isSTEMDegree: data.isSTEMDegree || false,
          currentlyInUS: data.currentlyInUS || false,
          willingToRelocate: data.willingToRelocate || false,
          canRelocateAtOwnExpense: data.canRelocateAtOwnExpense || false,
          employmentAuthStatus: data.employmentAuthStatus || '',
          isEligibleForSTEMExtension: data.isEligibleForSTEMExtension || false,
          requiresCPTAuthorization: data.requiresCPTAuthorization || '',
          proficientLanguages: data.proficientLanguages || '',
          totalWorkExperience: data.totalWorkExperience || '',
          employmentAuthStartDate: data.employmentAuthStartDate || '',
          employmentAuthEndDate: data.employmentAuthEndDate || '',
          workAuthorization: data.workAuthorization || '',
          educationHistory: data.educationHistory || [],
          workHistory: data.workHistory || [],
          visaStatus: data.visaStatus || '',
          availabilityNoticePeriod: data.availabilityNoticePeriod || '',
          preferredWorkEnvironment: data.preferredWorkEnvironment || ''
        });
      }
    } catch (error) {
      console.error('Error loading general application:', error);
      toast.error('Failed to load general application');
    }
  }

  const checkFormCompletion = (data: GeneralApplicationFormData): boolean => {
    return REQUIRED_FIELDS.every(field => Boolean(data[field as keyof GeneralApplicationFormData]));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!user) return;

    try {
      setLoading(true);
      const isComplete = checkFormCompletion(formData);
      const timestamp = new Date().toISOString();
      const docId = id || uuidv4();
      
      // Reference to the generalApplications collection (top-level)
      const genAppRef = doc(db, 'generalApplications', docId);
      // Reference to the user document to update userGeneralApplications
      const userRef = doc(db, 'users', user.uid);
      
      // Create or update the general application in the top-level collection
      await setDoc(genAppRef, {
        ...formData,
        id: docId,
        userId: user.uid,
        isComplete,
        createdAt: id ? (await getDoc(genAppRef)).data()?.createdAt || timestamp : timestamp,
        updatedAt: timestamp
      }, { merge: true });
      
      // Update the user document with a reference to the general application
      await updateDoc(userRef, {
        [`userGeneralApplications.${docId}`]: {
          isComplete,
          createdAt: timestamp,
          updatedAt: timestamp
        }
      });

      toast.success(id ? 'General application updated successfully' : 'General application created successfully');
      
      // Check if we're coming from the todo page as part of onboarding flow
      const isOnboarding = sessionStorage.getItem('onboardingFlow') === 'true';
      if (!id && isOnboarding) {
        // If creating a new application during onboarding, continue to next step
        toast('Continue to the next step: Create a job profile', { icon: '👉' });
        navigate('/job-profiles/new');
      } else {
        navigate('/job-profiles');
      }
    } catch (error) {
      console.error('Error saving general application:', error);
      toast.error('Failed to save general application');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12 bg-gray-50 dark:bg-gray-900 min-h-screen">
      <div className="max-w-3xl mx-auto">
        <h1 className="text-3xl font-bold text-gray-900 dark:text-white mb-8">
          {id ? 'Edit General Application' : 'New General Application'}
        </h1>
        
        <p className="text-lg text-gray-600 dark:text-gray-400 mb-8">
          This will be the only time you'll ever need to fill out these details for your job applications
        </p>

        <form onSubmit={handleSubmit} className="space-y-6">
          {/* Personal Information */}
          <div className="bg-white dark:bg-gray-800 shadow rounded-lg p-6">
            <h2 className="text-xl font-semibold text-gray-900 dark:text-white mb-4">Personal Information</h2>
            <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
              <div>
                <label htmlFor="firstName" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                  First Name *
                </label>
                <input
                  type="text"
                  id="firstName"
                  required
                  className="mt-1 block w-full px-4 py-2 rounded-md border-gray-300 dark:border-gray-600 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 bg-gray-50 dark:bg-gray-700 text-gray-900 dark:text-white"
                  value={formData.firstName}
                  onChange={(e) => setFormData({ ...formData, firstName: e.target.value })}
                />
              </div>
              <div>
                <label htmlFor="middleName" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                  Middle Name
                </label>
                <input
                  type="text"
                  id="middleName"
                  className="mt-1 block w-full px-4 py-2 rounded-md border-gray-300 dark:border-gray-600 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 bg-gray-50 dark:bg-gray-700 text-gray-900 dark:text-white"
                  value={formData.middleName}
                  onChange={(e) => setFormData({ ...formData, middleName: e.target.value })}
                />
              </div>
              <div>
                <label htmlFor="lastName" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                  Last Name *
                </label>
                <input
                  type="text"
                  id="lastName"
                  required
                  className="mt-1 block w-full px-4 py-2 rounded-md border-gray-300 dark:border-gray-600 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 bg-gray-50 dark:bg-gray-700 text-gray-900 dark:text-white"
                  value={formData.lastName}
                  onChange={(e) => setFormData({ ...formData, lastName: e.target.value })}
                />
              </div>
              <div>
                <label htmlFor="preferredFirstName" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                  Preferred Name
                </label>
                <input
                  type="text"
                  id="preferredFirstName"
                  className="mt-1 block w-full px-4 py-2 rounded-md border-gray-300 dark:border-gray-600 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 bg-gray-50 dark:bg-gray-700 text-gray-900 dark:text-white"
                  value={formData.preferredFirstName}
                  onChange={(e) => setFormData({ ...formData, preferredFirstName: e.target.value })}
                />
              </div>
            </div>
          </div>

          {/* Contact Information */}
          <div className="bg-white dark:bg-gray-800 shadow rounded-lg p-6">
            <h2 className="text-xl font-semibold text-gray-900 dark:text-white mb-4">Contact Information</h2>
            <div className="space-y-4">
              <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
                <div>
                  <label htmlFor="primaryEmail" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                    Primary Email *
                  </label>
                  <input
                    type="email"
                    id="primaryEmail"
                    required
                    className="mt-1 block w-full px-4 py-2 rounded-md border-gray-300 dark:border-gray-600 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 bg-gray-50 dark:bg-gray-700 text-gray-900 dark:text-white"
                    value={formData.primaryEmail}
                    onChange={(e) => setFormData({ ...formData, primaryEmail: e.target.value })}
                  />
                </div>
                <div>
                  <label htmlFor="secondaryEmail" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                    Secondary Email
                  </label>
                  <input
                    type="email"
                    id="secondaryEmail"
                    className="mt-1 block w-full px-4 py-2 rounded-md border-gray-300 dark:border-gray-600 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 bg-gray-50 dark:bg-gray-700 text-gray-900 dark:text-white"
                    value={formData.secondaryEmail}
                    onChange={(e) => setFormData({ ...formData, secondaryEmail: e.target.value })}
                  />
                </div>
              </div>
              <div className="flex gap-4">
                <div className="w-1/4">
                  <label htmlFor="countryCallingCode" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                    Phone Country Code *
                  </label>
                  <input
                    type="text"
                    id="countryCallingCode"
                    required
                    placeholder="+1"
                    className="mt-1 block w-full px-4 py-2 rounded-md border-gray-300 dark:border-gray-600 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 bg-gray-50 dark:bg-gray-700 text-gray-900 dark:text-white"
                    value={formData.countryCallingCode}
                    onChange={(e) => setFormData({ ...formData, countryCallingCode: e.target.value })}
                  />
                </div>
                <div className="flex-1">
                  <label htmlFor="phoneNumber" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                    Phone Number *
                  </label>
                  <input
                    type="tel"
                    id="phoneNumber"
                    required
                    className="mt-1 block w-full px-4 py-2 rounded-md border-gray-300 dark:border-gray-600 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 bg-gray-50 dark:bg-gray-700 text-gray-900 dark:text-white"
                    value={formData.phoneNumber}
                    onChange={(e) => setFormData({ ...formData, phoneNumber: e.target.value })}
                  />
                </div>
              </div>
            </div>
          </div>

          {/* Address Information */}
          <div className="bg-white dark:bg-gray-800 shadow rounded-lg p-6">
            <h2 className="text-xl font-semibold text-gray-900 dark:text-white mb-4">Address Information</h2>
            <div className="space-y-4">
              <div>
                <label htmlFor="street" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                  Street *
                </label>
                <input
                  type="text"
                  id="street"
                  required
                  className="mt-1 block w-full px-4 py-2 rounded-md border-gray-300 dark:border-gray-600 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 bg-gray-50 dark:bg-gray-700 text-gray-900 dark:text-white"
                  value={formData.street}
                  onChange={(e) => setFormData({ ...formData, street: e.target.value })}
                />
              </div>
              <div>
                <label htmlFor="aptSuiteUnit" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                  Apt, Suite, Unit
                </label>
                <input
                  type="text"
                  id="aptSuiteUnit"
                  className="mt-1 block w-full px-4 py-2 rounded-md border-gray-300 dark:border-gray-600 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 bg-gray-50 dark:bg-gray-700 text-gray-900 dark:text-white"
                  value={formData.aptSuiteUnit}
                  onChange={(e) => setFormData({ ...formData, aptSuiteUnit: e.target.value })}
                />
              </div>
              <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
                <div>
                  <label htmlFor="city" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                    City *
                  </label>
                  <input
                    type="text"
                    id="city"
                    required
                    className="mt-1 block w-full px-4 py-2 rounded-md border-gray-300 dark:border-gray-600 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 bg-gray-50 dark:bg-gray-700 text-gray-900 dark:text-white"
                    value={formData.city}
                    onChange={(e) => setFormData({ ...formData, city: e.target.value })}
                  />
                </div>
                <div>
                  <label htmlFor="state" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                    State *
                  </label>
                  <input
                    type="text"
                    id="state"
                    required
                    className="mt-1 block w-full px-4 py-2 rounded-md border-gray-300 dark:border-gray-600 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 bg-gray-50 dark:bg-gray-700 text-gray-900 dark:text-white"
                    value={formData.state}
                    onChange={(e) => setFormData({ ...formData, state: e.target.value })}
                  />
                </div>
              </div>
              <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
                <div>
                  <label htmlFor="zipCode" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                    ZIP Code *
                  </label>
                  <input
                    type="text"
                    id="zipCode"
                    required
                    className="mt-1 block w-full px-4 py-2 rounded-md border-gray-300 dark:border-gray-600 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 bg-gray-50 dark:bg-gray-700 text-gray-900 dark:text-white"
                    value={formData.zipCode}
                    onChange={(e) => setFormData({ ...formData, zipCode: e.target.value })}
                  />
                </div>
                <div>
                  <label htmlFor="country" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                    Country *
                  </label>
                  <input
                    type="text"
                    id="country"
                    required
                    className="mt-1 block w-full px-4 py-2 rounded-md border-gray-300 dark:border-gray-600 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 bg-gray-50 dark:bg-gray-700 text-gray-900 dark:text-white"
                    value={formData.country}
                    onChange={(e) => setFormData({ ...formData, country: e.target.value })}
                  />
                </div>
              </div>
            </div>
          </div>

          {/* Equal Employment Opportunity */}
          <div className="bg-white dark:bg-gray-800 shadow rounded-lg p-6">
            <h2 className="text-xl font-semibold text-gray-900 dark:text-white mb-4">Equal Employment Opportunity</h2>
            <div className="space-y-4">
              <div>
                <label htmlFor="gender" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                  Gender
                </label>
                <select
                  id="gender"
                  className="mt-1 block w-full px-4 py-2 rounded-md border-gray-300 dark:border-gray-600 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 bg-gray-50 dark:bg-gray-700 text-gray-900 dark:text-white"
                  value={formData.gender}
                  onChange={(e) => setFormData({ ...formData, gender: e.target.value })}
                >
                  <option value="">Select gender</option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                  <option value="non_binary">Non-binary</option>
                  <option value="prefer_not_to_say">Prefer not to say</option>
                </select>
              </div>
              <div>
                <label htmlFor="raceEthnicity" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                  Race/Ethnicity
                </label>
                <select
                  id="raceEthnicity"
                  className="mt-1 block w-full px-4 py-2 rounded-md border-gray-300 dark:border-gray-600 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 bg-gray-50 dark:bg-gray-700 text-gray-900 dark:text-white"
                  value={formData.raceEthnicity}
                  onChange={(e) => setFormData({ ...formData, raceEthnicity: e.target.value })}
                >
                  <option value="">Select race/ethnicity</option>
                  <option value="asian">Asian</option>
                  <option value="black">Black or African American</option>
                  <option value="hispanic">Hispanic or Latino</option>
                  <option value="native_american">Native American or Alaska Native</option>
                  <option value="pacific_islander">Native Hawaiian or Other Pacific Islander</option>
                  <option value="white">White</option>
                  <option value="two_or_more">Two or More Races</option>
                  <option value="prefer_not_to_say">Prefer not to say</option>
                </select>
              </div>
              <div>
                <label htmlFor="veteranStatus" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                  Veteran Status
                </label>
                <select
                  id="veteranStatus"
                  className="mt-1 block w-full px-4 py-2 rounded-md border-gray-300 dark:border-gray-600 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 bg-gray-50 dark:bg-gray-700 text-gray-900 dark:text-white"
                  value={formData.veteranStatus}
                  onChange={(e) => setFormData({ ...formData, veteranStatus: e.target.value })}
                >
                  <option value="">Select veteran status</option>
                  <option value="veteran">I am a veteran</option>
                  <option value="not_veteran">I am not a veteran</option>
                  <option value="prefer_not_to_say">Prefer not to say</option>
                </select>
              </div>
              <div>
                <label htmlFor="disabilityStatus" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                  Disability Status
                </label>
                <select
                  id="disabilityStatus"
                  className="mt-1 block w-full px-4 py-2 rounded-md border-gray-300 dark:border-gray-600 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 bg-gray-50 dark:bg-gray-700 text-gray-900 dark:text-white"
                  value={formData.disabilityStatus}
                  onChange={(e) => setFormData({ ...formData, disabilityStatus: e.target.value })}
                >
                  <option value="">Select disability status</option>
                  <option value="yes">Yes, I have a disability</option>
                  <option value="no">No, I don't have a disability</option>
                  <option value="prefer_not_to_say">Prefer not to say</option>
                </select>
              </div>
            </div>
          </div>

          {/* Work Authorization & Security */}
          <div className="bg-white dark:bg-gray-800 shadow rounded-lg p-6">
            <h2 className="text-xl font-semibold text-gray-900 dark:text-white mb-4">Work Authorization & Security</h2>
            <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
              <div>
                <label htmlFor="canObtainSecurityClearance" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                  Can obtain security clearance
                </label>
                <select
                  id="canObtainSecurityClearance"
                  className="mt-1 block w-full px-4 py-2 rounded-md border-gray-300 dark:border-gray-600 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 bg-gray-50 dark:bg-gray-700 text-gray-900 dark:text-white"
                  value={formData.canObtainSecurityClearance ? "yes" : "no"}
                  onChange={(e) => setFormData({ ...formData, canObtainSecurityClearance: e.target.value === "yes" })}
                >
                  <option value="no">No</option>
                  <option value="yes">Yes</option>
                </select>
              </div>
              <div>
                <label htmlFor="securityClearanceLevel" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                  Security Clearance Level
                </label>
                <select
                  id="securityClearanceLevel"
                  className="mt-1 block w-full px-4 py-2 rounded-md border-gray-300 dark:border-gray-600 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 bg-gray-50 dark:bg-gray-700 text-gray-900 dark:text-white"
                  value={formData.securityClearanceLevel}
                  onChange={(e) => setFormData({ ...formData, securityClearanceLevel: e.target.value })}
                >
                  <option value="">Select clearance level</option>
                  <option value="no_clearance">No clearance</option>
                  <option value="active_confidential">Active Confidential</option>
                  <option value="active_secret">Active Secret</option>
                  <option value="active_top_secret">Active Top Secret</option>
                  <option value="active_top_sci">Active Top SCI</option>
                  <option value="inactive_confidential">Inactive Confidential</option>
                  <option value="inactive_secret">Inactive Secret</option>
                  <option value="inactive_top_secret">Inactive Top Secret</option>
                  <option value="inactive_top_secret_sci">Inactive Top Secret SCI</option>
                </select>
              </div>
              <div>
                <label htmlFor="isFormerGovernmentEmployee" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                  Is former government employee
                </label>
                <select
                  id="isFormerGovernmentEmployee"
                  className="mt-1 block w-full px-4 py-2 rounded-md border-gray-300 dark:border-gray-600 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 bg-gray-50 dark:bg-gray-700 text-gray-900 dark:text-white"
                  value={formData.isFormerGovernmentEmployee ? "yes" : "no"}
                  onChange={(e) => setFormData({ ...formData, isFormerGovernmentEmployee: e.target.value === "yes" })}
                >
                  <option value="no">No</option>
                  <option value="yes">Yes</option>
                </select>
              </div>
              <div>
                <label htmlFor="currentCitizenship" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                  Current Citizenship
                </label>
                <input
                  type="text"
                  id="currentCitizenship"
                  className="mt-1 block w-full px-4 py-2 rounded-md border-gray-300 dark:border-gray-600 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 bg-gray-50 dark:bg-gray-700 text-gray-900 dark:text-white"
                  value={formData.currentCitizenship}
                  onChange={(e) => setFormData({ ...formData, currentCitizenship: e.target.value })}
                />
              </div>
              <div>
                <label htmlFor="isAuthorizedToWork" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                  Is authorized to work in the US
                </label>
                <select
                  id="isAuthorizedToWork"
                  className="mt-1 block w-full px-4 py-2 rounded-md border-gray-300 dark:border-gray-600 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 bg-gray-50 dark:bg-gray-700 text-gray-900 dark:text-white"
                  value={formData.isAuthorizedToWork ? "yes" : "no"}
                  onChange={(e) => setFormData({ ...formData, isAuthorizedToWork: e.target.value === "yes" })}
                >
                  <option value="no">No</option>
                  <option value="yes">Yes</option>
                </select>
              </div>
              <div>
                <label htmlFor="requiresSponsorship" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                  Requires sponsorship
                </label>
                <select
                  id="requiresSponsorship"
                  className="mt-1 block w-full px-4 py-2 rounded-md border-gray-300 dark:border-gray-600 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 bg-gray-50 dark:bg-gray-700 text-gray-900 dark:text-white"
                  value={formData.requiresSponsorship ? "yes" : "no"}
                  onChange={(e) => setFormData({ ...formData, requiresSponsorship: e.target.value === "yes" })}
                >
                  <option value="no">No</option>
                  <option value="yes">Yes</option>
                </select>
              </div>
              <div>
                <label htmlFor="isSTEMDegree" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                  Is STEM degree
                </label>
                <select
                  id="isSTEMDegree"
                  className="mt-1 block w-full px-4 py-2 rounded-md border-gray-300 dark:border-gray-600 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 bg-gray-50 dark:bg-gray-700 text-gray-900 dark:text-white"
                  value={formData.isSTEMDegree ? "yes" : "no"}
                  onChange={(e) => setFormData({ ...formData, isSTEMDegree: e.target.value === "yes" })}
                >
                  <option value="no">No</option>
                  <option value="yes">Yes</option>
                </select>
              </div>
              <div>
                <label htmlFor="currentlyInUS" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                  Currently in US
                </label>
                <select
                  id="currentlyInUS"
                  className="mt-1 block w-full px-4 py-2 rounded-md border-gray-300 dark:border-gray-600 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 bg-gray-50 dark:bg-gray-700 text-gray-900 dark:text-white"
                  value={formData.currentlyInUS ? "yes" : "no"}
                  onChange={(e) => setFormData({ ...formData, currentlyInUS: e.target.value === "yes" })}
                >
                  <option value="no">No</option>
                  <option value="yes">Yes</option>
                </select>
              </div>
              <div>
                <label htmlFor="willingToRelocate" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                  Willing to relocate
                </label>
                <select
                  id="willingToRelocate"
                  className="mt-1 block w-full px-4 py-2 rounded-md border-gray-300 dark:border-gray-600 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 bg-gray-50 dark:bg-gray-700 text-gray-900 dark:text-white"
                  value={formData.willingToRelocate ? "yes" : "no"}
                  onChange={(e) => setFormData({ ...formData, willingToRelocate: e.target.value === "yes" })}
                >
                  <option value="no">No</option>
                  <option value="yes">Yes</option>
                </select>
              </div>
              <div>
                <label htmlFor="canRelocateAtOwnExpense" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                  Can relocate at own expense
                </label>
                <select
                  id="canRelocateAtOwnExpense"
                  className="mt-1 block w-full px-4 py-2 rounded-md border-gray-300 dark:border-gray-600 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 bg-gray-50 dark:bg-gray-700 text-gray-900 dark:text-white"
                  value={formData.canRelocateAtOwnExpense ? "yes" : "no"}
                  onChange={(e) => setFormData({ ...formData, canRelocateAtOwnExpense: e.target.value === "yes" })}
                >
                  <option value="no">No</option>
                  <option value="yes">Yes</option>
                </select>
              </div>
              <div>
                <label htmlFor="employmentAuthStatus" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                  Employment Authorization Status
                </label>
                <select
                  id="employmentAuthStatus"
                  className="mt-1 block w-full px-4 py-2 rounded-md border-gray-300 dark:border-gray-600 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 bg-gray-50 dark:bg-gray-700 text-gray-900 dark:text-white"
                  value={formData.employmentAuthStatus}
                  onChange={(e) => setFormData({ ...formData, employmentAuthStatus: e.target.value })}
                >
                  <option value="">Select status</option>
                  <option value="f1_opt">F-1 (OPT)</option>
                  <option value="f1_opt_stem">F-1 (OPT) with approved STEM extension</option>
                  <option value="h1b">H-1B</option>
                  <option value="green_card">Green Card</option>
                  <option value="h4">H-4</option>
                  <option value="us_citizen">United States Citizen</option>
                  <option value="asylum">Asylum</option>
                  <option value="o1">O-1</option>
                  <option value="j1">J-1</option>
                  <option value="j2">J-2</option>
                  <option value="l1">L-1</option>
                  <option value="l2">L-2</option>
                  <option value="tn">TN</option>
                  <option value="e3">E-3</option>
                  <option value="e3d">E-3D</option>
                  <option value="h1b1">H-1B1 (Chile or Singapore)</option>
                  <option value="not_in_us">Not in U.S.</option>
                </select>
              </div>
              <div>
                <label htmlFor="isEligibleForSTEMExtension" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                  Is eligible for STEM extension
                </label>
                <select
                  id="isEligibleForSTEMExtension"
                  className="mt-1 block w-full px-4 py-2 rounded-md border-gray-300 dark:border-gray-600 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 bg-gray-50 dark:bg-gray-700 text-gray-900 dark:text-white"
                  value={formData.isEligibleForSTEMExtension ? "yes" : "no"}
                  onChange={(e) => setFormData({ ...formData, isEligibleForSTEMExtension: e.target.value === "yes" })}
                >
                  <option value="no">No</option>
                  <option value="yes">Yes</option>
                </select>
              </div>
              <div>
                <label htmlFor="requiresCPTAuthorization" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                  Requires CPT authorization
                </label>
                <select
                  id="requiresCPTAuthorization"
                  className="mt-1 block w-full px-4 py-2 rounded-md border-gray-300 dark:border-gray-600 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 bg-gray-50 dark:bg-gray-700 text-gray-900 dark:text-white"
                  value={formData.requiresCPTAuthorization}
                  onChange={(e) => setFormData({ ...formData, requiresCPTAuthorization: e.target.value })}
                >
                  <option value="">Select option</option>
                  <option value="yes">Yes</option>
                  <option value="no">No</option>
                </select>
              </div>
              <div>
                <label htmlFor="proficientLanguages" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                  Proficient Languages
                </label>
                <input
                  type="text"
                  id="proficientLanguages"
                  className="mt-1 block w-full px-4 py-2 rounded-md border-gray-300 dark:border-gray-600 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 bg-gray-50 dark:bg-gray-700 text-gray-900 dark:text-white"
                  value={formData.proficientLanguages}
                  onChange={(e) => setFormData({ ...formData, proficientLanguages: e.target.value })}
                />
              </div>
              <div>
                <label htmlFor="totalWorkExperience" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                  Total Work Experience
                </label>
                <input
                  type="text"
                  id="totalWorkExperience"
                  className="mt-1 block w-full px-4 py-2 rounded-md border-gray-300 dark:border-gray-600 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 bg-gray-50 dark:bg-gray-700 text-gray-900 dark:text-white"
                  value={formData.totalWorkExperience}
                  onChange={(e) => setFormData({ ...formData, totalWorkExperience: e.target.value })}
                />
              </div>
              <div>
                <label htmlFor="employmentAuthStartDate" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                  Employment Auth Start Date
                </label>
                <input
                  type="date"
                  id="employmentAuthStartDate"
                  className="mt-1 block w-full px-4 py-2 rounded-md border-gray-300 dark:border-gray-600 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 bg-gray-50 dark:bg-gray-700 text-gray-900 dark:text-white"
                  value={formData.employmentAuthStartDate}
                  onChange={(e) => setFormData({ ...formData, employmentAuthStartDate: e.target.value })}
                />
              </div>
              <div>
                <label htmlFor="employmentAuthEndDate" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                  Employment Auth End Date
                </label>
                <input
                  type="date"
                  id="employmentAuthEndDate"
                  className="mt-1 block w-full px-4 py-2 rounded-md border-gray-300 dark:border-gray-600 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 bg-gray-50 dark:bg-gray-700 text-gray-900 dark:text-white"
                  value={formData.employmentAuthEndDate}
                  onChange={(e) => setFormData({ ...formData, employmentAuthEndDate: e.target.value })}
                />
              </div>
              <div>
                <label htmlFor="workAuthorization" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                  Work Authorization
                </label>
                <input
                  type="text"
                  id="workAuthorization"
                  className="mt-1 block w-full px-4 py-2 rounded-md border-gray-300 dark:border-gray-600 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 bg-gray-50 dark:bg-gray-700 text-gray-900 dark:text-white"
                  value={formData.workAuthorization}
                  onChange={(e) => setFormData({ ...formData, workAuthorization: e.target.value })}
                />
              </div>
            </div>
          </div>

          {/* Education History */}
          <div className="bg-white dark:bg-gray-800 shadow rounded-lg p-6">
            <h2 className="text-xl font-semibold text-gray-900 dark:text-white mb-4">Education History</h2>
            <div className="space-y-4">
              {(formData.educationHistory || []).map((education, index) => (
                <div key={index} className="p-4 border border-gray-200 dark:border-gray-700 rounded-lg">
                  <div className="grid grid-cols-1 gap-4 sm:grid-cols-3">
                    <div className="sm:col-span-2">
                      <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                        School
                      </label>
                      <input
                        type="text"
                        className="mt-1 block w-full px-4 py-2 rounded-md border-gray-300 dark:border-gray-600 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 bg-gray-50 dark:bg-gray-700 text-gray-900 dark:text-white"
                        value={education.school}
                        onChange={(e) => {
                          const newHistory = [...formData.educationHistory];
                          newHistory[index] = { ...newHistory[index], school: e.target.value };
                          setFormData({ ...formData, educationHistory: newHistory });
                        }}
                      />
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                        Education Level
                      </label>
                      <select
                        className="mt-1 block w-full px-4 py-2 rounded-md border-gray-300 dark:border-gray-600 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 bg-gray-50 dark:bg-gray-700 text-gray-900 dark:text-white"
                        value={education.education}
                        onChange={(e) => {
                          const newHistory = [...formData.educationHistory];
                          newHistory[index] = { ...newHistory[index], education: e.target.value };
                          setFormData({ ...formData, educationHistory: newHistory });
                        }}
                      >
                        <option value="">Select level</option>
                        <option value="high_school">High School</option>
                        <option value="associates">Associate's Degree</option>
                        <option value="bachelors">Bachelor's Degree</option>
                        <option value="masters">Master's Degree</option>
                        <option value="doctorate">Doctorate</option>
                        <option value="professional">Professional Degree</option>
                        <option value="certification">Certification</option>
                        <option value="other">Other</option>
                      </select>
                    </div>
                    <div className="sm:col-span-2">
                      <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                        Degree Major
                      </label>
                      <input
                        type="text"
                        className="mt-1 block w-full px-4 py-2 rounded-md border-gray-300 dark:border-gray-600 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 bg-gray-50 dark:bg-gray-700 text-gray-900 dark:text-white"
                        value={education.degreeMajor}
                        onChange={(e) => {
                          const newHistory = [...formData.educationHistory];
                          newHistory[index] = { ...newHistory[index], degreeMajor: e.target.value };
                          setFormData({ ...formData, educationHistory: newHistory });
                        }}
                      />
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                        Minor
                      </label>
                      <input
                        type="text"
                        className="mt-1 block w-full px-4 py-2 rounded-md border-gray-300 dark:border-gray-600 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 bg-gray-50 dark:bg-gray-700 text-gray-900 dark:text-white"
                        value={education.minor}
                        onChange={(e) => {
                          const newHistory = [...formData.educationHistory];
                          newHistory[index] = { ...newHistory[index], minor: e.target.value };
                          setFormData({ ...formData, educationHistory: newHistory });
                        }}
                      />
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                        GPA (4.0 scale)
                      </label>
                      <input
                        type="number"
                        step="0.01"
                        min="0"
                        max="4"
                        className="mt-1 block w-full px-4 py-2 rounded-md border-gray-300 dark:border-gray-600 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 bg-gray-50 dark:bg-gray-700 text-gray-900 dark:text-white"
                        value={education.gpa}
                        onChange={(e) => {
                          const newHistory = [...formData.educationHistory];
                          newHistory[index] = { ...newHistory[index], gpa: e.target.value };
                          setFormData({ ...formData, educationHistory: newHistory });
                        }}
                      />
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                        Start Date
                      </label>
                      <input
                        type="date"
                        className="mt-1 block w-full px-4 py-2 rounded-md border-gray-300 dark:border-gray-600 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 bg-gray-50 dark:bg-gray-700 text-gray-900 dark:text-white"
                        value={education.startDate}
                        onChange={(e) => {
                          const newHistory = [...formData.educationHistory];
                          newHistory[index] = { ...newHistory[index], startDate: e.target.value };
                          setFormData({ ...formData, educationHistory: newHistory });
                        }}
                      />
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                        Graduation Date
                      </label>
                      <input
                        type="date"
                        className="mt-1 block w-full px-4 py-2 rounded-md border-gray-300 dark:border-gray-600 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 bg-gray-50 dark:bg-gray-700 text-gray-900 dark:text-white"
                        value={education.graduationDate}
                        onChange={(e) => {
                          const newHistory = [...formData.educationHistory];
                          newHistory[index] = { ...newHistory[index], graduationDate: e.target.value };
                          setFormData({ ...formData, educationHistory: newHistory });
                        }}
                      />
                    </div>
                  </div>
                  <button
                    type="button"
                    onClick={() => {
                      const newHistory = formData.educationHistory.filter((_, i) => i !== index);
                      setFormData({ ...formData, educationHistory: newHistory });
                    }}
                    className="mt-4 text-sm text-red-600 hover:text-red-800"
                  >
                    Remove Education
                  </button>
                </div>
              ))}
              <button
                type="button"
                onClick={() => {
                  setFormData({
                    ...formData,
                    educationHistory: [
                      ...formData.educationHistory,
                      {
                        school: '',
                        education: '',
                        degreeMajor: '',
                        gpa: '',
                        startDate: '',
                        graduationDate: ''
                      }
                    ]
                  });
                }}
                className="mt-4 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Add Education
              </button>
            </div>
          </div>

          {/* Work History */}
          <div className="bg-white dark:bg-gray-800 shadow rounded-lg p-6">
            <h2 className="text-xl font-semibold text-gray-900 dark:text-white mb-4">Work History</h2>
            <div className="space-y-4">
              {(formData.workHistory || []).map((work, index) => (
                <div key={index} className="p-4 border border-gray-200 dark:border-gray-700 rounded-lg">
                  <div className="grid grid-cols-1 gap-4 sm:grid-cols-3">
                    <div className="sm:col-span-2">
                      <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                        Company
                      </label>
                      <input
                        type="text"
                        className="mt-1 block w-full px-4 py-2 rounded-md border-gray-300 dark:border-gray-600 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 bg-gray-50 dark:bg-gray-700 text-gray-900 dark:text-white"
                        value={work.company}
                        onChange={(e) => {
                          const newHistory = [...formData.workHistory];
                          newHistory[index] = { ...newHistory[index], company: e.target.value };
                          setFormData({ ...formData, workHistory: newHistory });
                        }}
                      />
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                        Job Type
                      </label>
                      <select
                        className="mt-1 block w-full px-4 py-2 rounded-md border-gray-300 dark:border-gray-600 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 bg-gray-50 dark:bg-gray-700 text-gray-900 dark:text-white"
                        value={work.jobType}
                        onChange={(e) => {
                          const newHistory = [...formData.workHistory];
                          newHistory[index] = { ...newHistory[index], jobType: e.target.value };
                          setFormData({ ...formData, workHistory: newHistory });
                        }}
                      >
                        <option value="">Select type</option>
                        <option value="full_time">Full-time</option>
                        <option value="part_time">Part-time</option>
                        <option value="contract">Contract</option>
                        <option value="internship">Internship</option>
                        <option value="temporary">Temporary</option>
                        <option value="freelance">Freelance</option>
                      </select>
                    </div>
                    <div className="sm:col-span-2">
                      <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                        Job Title
                      </label>
                      <input
                        type="text"
                        className="mt-1 block w-full px-4 py-2 rounded-md border-gray-300 dark:border-gray-600 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 bg-gray-50 dark:bg-gray-700 text-gray-900 dark:text-white"
                        value={work.jobTitle}
                        onChange={(e) => {
                          const newHistory = [...formData.workHistory];
                          newHistory[index] = { ...newHistory[index], jobTitle: e.target.value };
                          setFormData({ ...formData, workHistory: newHistory });
                        }}
                      />
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                        Hours per Week
                      </label>
                      <input
                        type="number"
                        min="0"
                        className="mt-1 block w-full px-4 py-2 rounded-md border-gray-300 dark:border-gray-600 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 bg-gray-50 dark:bg-gray-700 text-gray-900 dark:text-white"
                        value={work.hoursPerWeek}
                        onChange={(e) => {
                          const newHistory = [...formData.workHistory];
                          newHistory[index] = { ...newHistory[index], hoursPerWeek: e.target.value };
                          setFormData({ ...formData, workHistory: newHistory });
                        }}
                      />
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                        Start Date
                      </label>
                      <input
                        type="date"
                        className="mt-1 block w-full px-4 py-2 rounded-md border-gray-300 dark:border-gray-600 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 bg-gray-50 dark:bg-gray-700 text-gray-900 dark:text-white"
                        value={work.startDate}
                        onChange={(e) => {
                          const newHistory = [...formData.workHistory];
                          newHistory[index] = { ...newHistory[index], startDate: e.target.value };
                          setFormData({ ...formData, workHistory: newHistory });
                        }}
                      />
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                        End Date
                      </label>
                      <input
                        type="date"
                        className="mt-1 block w-full px-4 py-2 rounded-md border-gray-300 dark:border-gray-600 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 bg-gray-50 dark:bg-gray-700 text-gray-900 dark:text-white"
                        value={work.endDate}
                        onChange={(e) => {
                          const newHistory = [...formData.workHistory];
                          newHistory[index] = { ...newHistory[index], endDate: e.target.value };
                          setFormData({ ...formData, workHistory: newHistory });
                        }}
                      />
                    </div>
                    <div className="sm:col-span-3">
                      <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                        Reason for Leaving
                      </label>
                      <select
                        className="mt-1 block w-full px-4 py-2 rounded-md border-gray-300 dark:border-gray-600 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 bg-gray-50 dark:bg-gray-700 text-gray-900 dark:text-white"
                        value={work.reasonForLeaving}
                        onChange={(e) => {
                          const newHistory = [...formData.workHistory];
                          newHistory[index] = { ...newHistory[index], reasonForLeaving: e.target.value };
                          setFormData({ ...formData, workHistory: newHistory });
                        }}
                      >
                        <option value="">Select reason</option>
                        <option value="contract_ended">Contract job ended</option>
                        <option value="first_job">Came to the US and looked for my first job</option>
                        <option value="contract_to_fulltime">Employed for a contract job and would like a full-time job</option>
                        <option value="part_time_to_fulltime">Employed for a part-time job and would like a full-time job</option>
                        <option value="short_term_to_fulltime">Employed for a short-term project and would like a full-time job</option>
                        <option value="internship_to_fulltime">Employed for an internship and would like a full-time job</option>
                        <option value="graduated">Graduated from school and looking for a job</option>
                        <option value="internship_ended">Internship ended</option>
                        <option value="duties_reduced">Job duties have been reduced</option>
                        <option value="no_growth">Lack of growth opportunities at the company</option>
                        <option value="outsourced">Laid off because job duties have been outsourced</option>
                        <option value="position_eliminated">Laid off because my position was eliminated</option>
                        <option value="budget_cuts">Laid off because of budget cuts</option>
                        <option value="company_closed">Laid off because the company closed down</option>
                        <option value="merger">Laid off because the company underwent a merger/acquisition</option>
                        <option value="restructuring">Laid off because the company was restructuring</option>
                        <option value="family_reasons">Left my employer because of family reasons</option>
                        <option value="health_reasons">Left my employer because of health reasons</option>
                        <option value="career_change">Looking for a change in career direction</option>
                        <option value="layoff_concerns">Looking for a job because of lay off concerns</option>
                        <option value="better_pay">Looking for a job that pays more</option>
                        <option value="less_travel">Looking for a job that travels less often</option>
                        <option value="better_hours">Looking for a job with better hours</option>
                        <option value="job_security">Looking for a job with better job security</option>
                        <option value="visa_sponsor">Looking for an employer that will sponsor my work visa</option>
                        <option value="career_prospects">Looking for better career prospects</option>
                        <option value="new_challenges">Looking for new challenges at work</option>
                        <option value="career_change">Looking to a career change</option>
                        <option value="relocate">Looking to relocate</option>
                        <option value="better_personality_fit">Looking to work at a company that better aligns with my personality</option>
                        <option value="better_values_fit">Looking to work at a company that better aligns with my values</option>
                        <option value="new_offer">Offered a new job from another company</option>
                        <option value="promoted">Promoted in the same company</option>
                        <option value="went_to_school">Went to school</option>
                      </select>
                    </div>
                  </div>
                  <button
                    type="button"
                    onClick={() => {
                      const newHistory = formData.workHistory.filter((_, i) => i !== index);
                      setFormData({ ...formData, workHistory: newHistory });
                    }}
                    className="mt-4 text-sm text-red-600 hover:text-red-800"
                  >
                    Remove Work Experience
                  </button>
                </div>
              ))}
              <button
                type="button"
                onClick={() => {
                  setFormData({
                    ...formData,
                    workHistory: [
                      ...formData.workHistory,
                      {
                        company: '',
                        jobTitle: '',
                        jobType: '',
                        hoursPerWeek: '',
                        startDate: '',
                        endDate: '',
                        reasonForLeaving: ''
                      }
                    ]
                  });
                }}
                className="mt-4 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Add Work Experience
              </button>
            </div>
          </div>

          {/* Work Details */}
          <div className="bg-white dark:bg-gray-800 shadow rounded-lg p-6">
            <h2 className="text-xl font-semibold text-gray-900 dark:text-white mb-4">Work Details</h2>
            <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
              <div>
                <label htmlFor="visaStatus" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                  Visa Status *
                </label>
                <input
                  type="text"
                  id="visaStatus"
                  required
                  className="mt-1 block w-full px-4 py-2 rounded-md border-gray-300 dark:border-gray-600 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 bg-gray-50 dark:bg-gray-700 text-gray-900 dark:text-white"
                  value={formData.visaStatus}
                  onChange={(e) => setFormData({ ...formData, visaStatus: e.target.value })}
                />
              </div>
              <div>
                <label htmlFor="availabilityNoticePeriod" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                  What is your availability or notice period? *
                </label>
                <input
                  type="text"
                  id="availabilityNoticePeriod"
                  required
                  className="mt-1 block w-full px-4 py-2 rounded-md border-gray-300 dark:border-gray-600 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 bg-gray-50 dark:bg-gray-700 text-gray-900 dark:text-white"
                  value={formData.availabilityNoticePeriod}
                  onChange={(e) => setFormData({ ...formData, availabilityNoticePeriod: e.target.value })}
                />
              </div>
              <div className="sm:col-span-2">
                <label htmlFor="preferredWorkEnvironment" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                  What is your preferred work environment? *
                </label>
                <select
                  id="preferredWorkEnvironment"
                  required
                  className="mt-1 block w-full px-4 py-2 rounded-md border-gray-300 dark:border-gray-600 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 bg-gray-50 dark:bg-gray-700 text-gray-900 dark:text-white"
                  value={formData.preferredWorkEnvironment}
                  onChange={(e) => setFormData({ ...formData, preferredWorkEnvironment: e.target.value })}
                >
                  <option value="">Select preferred work environment</option>
                  <option value="onsite">On-site</option>
                  <option value="hybrid">Hybrid</option>
                  <option value="remote">Remote</option>
                </select>
              </div>
            </div>
          </div>

          {/* Move Online Presence section to the bottom, just before Form Actions */}
          {/* Online Presence */}
          <div className="bg-white dark:bg-gray-800 shadow rounded-lg p-6">
            <h2 className="text-xl font-semibold text-gray-900 dark:text-white mb-4">Online Presence</h2>
            <div className="space-y-4">
              <div>
                <label htmlFor="linkedinUrl" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                  LinkedIn URL
                </label>
                <input
                  type="url"
                  id="linkedinUrl"
                  className="mt-1 block w-full px-4 py-2 rounded-md border-gray-300 dark:border-gray-600 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 bg-gray-50 dark:bg-gray-700 text-gray-900 dark:text-white"
                  value={formData.linkedinUrl}
                  onChange={(e) => setFormData({ ...formData, linkedinUrl: e.target.value })}
                />
              </div>
              <div>
                <label htmlFor="githubUrl" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                  GitHub URL
                </label>
                <input
                  type="url"
                  id="githubUrl"
                  className="mt-1 block w-full px-4 py-2 rounded-md border-gray-300 dark:border-gray-600 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 bg-gray-50 dark:bg-gray-700 text-gray-900 dark:text-white"
                  value={formData.githubUrl}
                  onChange={(e) => setFormData({ ...formData, githubUrl: e.target.value })}
                />
              </div>
              
              {/* Custom URLs */}
              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                  Additional URLs
                </label>
                {(formData.customUrls || []).map((url, index) => (
                  <div key={index} className="mt-2 flex items-center gap-2">
                    <input
                      type="text"
                      placeholder="Label (e.g. Portfolio, Blog)"
                      className="flex-1 px-4 py-2 rounded-md border-gray-300 dark:border-gray-600 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 bg-gray-50 dark:bg-gray-700 text-gray-900 dark:text-white"
                      value={url.label}
                      onChange={(e) => {
                        const newUrls = [...formData.customUrls];
                        newUrls[index] = { ...newUrls[index], label: e.target.value };
                        setFormData({ ...formData, customUrls: newUrls });
                      }}
                    />
                    <input
                      type="url"
                      placeholder="URL"
                      className="flex-1 px-4 py-2 rounded-md border-gray-300 dark:border-gray-600 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 bg-gray-50 dark:bg-gray-700 text-gray-900 dark:text-white"
                      value={url.url}
                      onChange={(e) => {
                        const newUrls = [...formData.customUrls];
                        newUrls[index] = { ...newUrls[index], url: e.target.value };
                        setFormData({ ...formData, customUrls: newUrls });
                      }}
                    />
                    <button
                      type="button"
                      onClick={() => {
                        const newUrls = formData.customUrls.filter((_, i) => i !== index);
                        setFormData({ ...formData, customUrls: newUrls });
                      }}
                      className="p-2 text-red-600 hover:text-red-800"
                    >
                      Remove
                    </button>
                  </div>
                ))}
                <button
                  type="button"
                  onClick={() => {
                    setFormData({
                      ...formData,
                      customUrls: [...formData.customUrls, { label: '', url: '' }]
                    });
                  }}
                  className="mt-2 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Add URL
                </button>
              </div>
            </div>
          </div>

          {/* Form Actions */}
          <div className="flex justify-end space-x-4">
            <button
              type="button"
              onClick={() => navigate('/job-profiles')}
              className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Cancel
            </button>
            <button
              type="submit"
              disabled={loading}
              className="px-4 py-2 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              {loading ? 'Saving...' : 'Save'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
} 