import { useState, useEffect, useRef } from 'react';
import { useAuth } from '../../hooks/useAuth';
import { db, storage } from '../../lib/firebase';
import { collection, query, orderBy, onSnapshot, addDoc, serverTimestamp, doc, getDoc, deleteDoc, updateDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { Loader2, Paperclip, X, Edit2, Trash2, Check, XCircle } from 'lucide-react';
import toast from 'react-hot-toast';
import { formatDistanceToNow, format } from 'date-fns';

interface UserNotesProps {
  userId: string;
  onUpdate?: () => void;
}

interface UserNote {
  id: string;
  userId: string;
  authorId: string;
  userType: 'agent' | 'admin';
  message: string;
  createdAt: any; // Firestore timestamp
  editedAt?: any;
  attachments?: {
    id: string;
    name: string;
    url: string;
    type: string;
    size: number;
  }[];
}

interface UserNoteWithAuthor extends UserNote {
  authorDetails?: {
    firstName: string;
    lastName: string;
    photoURL: string | null;
  };
}

export function UserNotes({ userId, onUpdate }: UserNotesProps) {
  const { user, userProfile } = useAuth();
  const [notes, setNotes] = useState<UserNoteWithAuthor[]>([]);
  const [newNote, setNewNote] = useState('');
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [files, setFiles] = useState<File[]>([]);
  const [editingNoteId, setEditingNoteId] = useState<string | null>(null);
  const [editedMessage, setEditedMessage] = useState('');
  const fileInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (!userId) {
      setLoading(false);
      setError('Invalid user ID');
      return;
    }

    try {
      const q = query(
        collection(db, `users/${userId}/notes`),
        orderBy('createdAt', 'desc')
      );

      const unsubscribe = onSnapshot(q, 
        async (snapshot) => {
          const notesPromises = snapshot.docs.map(async (docSnapshot) => {
            const noteData = docSnapshot.data();
            // Fetch author details for each note
            const authorDoc = await getDoc(doc(db, 'users', noteData.authorId));
            const authorData = authorDoc.data();
            
            return {
              id: docSnapshot.id,
              ...noteData,
              authorDetails: authorData ? {
                firstName: authorData.firstName,
                lastName: authorData.lastName,
                photoURL: authorData.photoURL
              } : undefined
            } as UserNoteWithAuthor;
          });

          const notesList = await Promise.all(notesPromises);
          setNotes(notesList);
          setLoading(false);
          setError(null);
        },
        (error) => {
          console.error('Error fetching user notes:', error);
          setError('Failed to load notes');
          setLoading(false);
        }
      );

      return () => unsubscribe();
    } catch (error) {
      console.error('Error setting up user notes listener:', error);
      setError('Failed to load notes');
      setLoading(false);
    }
  }, [userId]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!user || !userProfile || (!newNote.trim() && files.length === 0) || !userId) return;

    // Only allow admins and agents to post notes
    if (userProfile.userType !== 'admin' && userProfile.userType !== 'agent') return;

    try {
      setSubmitting(true);
      let attachments: { id: string; url: string; name: string; type: string; size: number }[] = [];

      if (files.length > 0) {
        const urls = await uploadFiles(files);
        attachments = files.map((file, index) => ({
          id: crypto.randomUUID(),
          url: urls[index],
          name: file.name,
          type: file.type,
          size: file.size
        }));
      }

      await addDoc(collection(db, `users/${userId}/notes`), {
        userId: userId,
        authorId: user.uid,
        userType: userProfile.userType,
        message: newNote.trim(),
        createdAt: serverTimestamp(),
        attachments
      });

      setNewNote('');
      setFiles([]);
      if (onUpdate) onUpdate();
      toast.success('Note added successfully');
    } catch (error) {
      console.error('Error adding note:', error);
      toast.error('Failed to add note');
    } finally {
      setSubmitting(false);
    }
  };

  const handleEdit = (note: UserNoteWithAuthor) => {
    setEditingNoteId(note.id);
    setEditedMessage(note.message);
  };

  const handleSaveEdit = async (noteId: string) => {
    if (!editedMessage.trim()) return;

    try {
      setSubmitting(true);
      await updateDoc(doc(db, `users/${userId}/notes/${noteId}`), {
        message: editedMessage.trim(),
        editedAt: serverTimestamp()
      });
      setEditingNoteId(null);
      toast.success('Note updated successfully');
    } catch (error) {
      console.error('Error updating note:', error);
      toast.error('Failed to update note');
    } finally {
      setSubmitting(false);
    }
  };

  const handleDelete = async (noteId: string) => {
    if (!window.confirm('Are you sure you want to delete this note?')) return;

    try {
      setSubmitting(true);
      await deleteDoc(doc(db, `users/${userId}/notes/${noteId}`));
      toast.success('Note deleted successfully');
    } catch (error) {
      console.error('Error deleting note:', error);
      toast.error('Failed to delete note');
    } finally {
      setSubmitting(false);
    }
  };

  const uploadFiles = async (files: File[]): Promise<string[]> => {
    const uploadPromises = files.map(async (file) => {
      const fileRef = ref(storage, `users/${userId}/notes/${Date.now()}_${file.name}`);
      const snapshot = await uploadBytes(fileRef, file);
      return getDownloadURL(snapshot.ref);
    });

    return Promise.all(uploadPromises);
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const selectedFiles = Array.from(e.target.files);
      const validFiles = selectedFiles.filter(file => file.size <= 5 * 1024 * 1024); // 5MB limit
      
      if (validFiles.length !== selectedFiles.length) {
        toast.error('Some files exceed the 5MB size limit and were not added');
      }
      
      setFiles(current => [...current, ...validFiles]);
      // Reset the input value so the same file can be selected again
      if (e.target.value) e.target.value = '';
    }
  };

  const handleRemoveFile = (index: number) => {
    setFiles(current => current.filter((_, i) => i !== index));
  };

  const formatDate = (date: any) => {
    if (!date) return '';
    
    // Handle Firestore timestamp
    const timestamp = date.toDate ? date.toDate() : new Date(date);
    
    // If the date is less than 24 hours ago, use relative time
    const timeAgo = formatDistanceToNow(timestamp, { addSuffix: true });
    if (timestamp > new Date(Date.now() - 24 * 60 * 60 * 1000)) {
      return timeAgo;
    }
    
    // Otherwise use the full date
    return format(timestamp, 'MMM d, yyyy \'at\' h:mm a');
  };

  if (loading) {
    return (
      <div className="flex justify-center py-8">
        <Loader2 className="h-8 w-8 animate-spin text-indigo-600 dark:text-indigo-400" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-center py-8 text-red-600 dark:text-red-400">
        <p>{error}</p>
      </div>
    );
  }

  return (
    <div>
      {/* Show note form only for admins and agents */}
      {userProfile?.userType !== 'customer' && (
        <form onSubmit={handleSubmit} className="mb-6 space-y-4">
          <div>
            <label htmlFor="note" className="sr-only">Add a note about this user</label>
            <textarea
              id="note"
              rows={3}
              className="block w-full rounded-md border-gray-300 dark:border-gray-600 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 dark:bg-gray-700 dark:text-white sm:text-sm p-4"
              placeholder="Add a note about this user..."
              value={newNote}
              onChange={(e) => setNewNote(e.target.value)}
              disabled={submitting}
            />
          </div>

          {/* File attachments */}
          <div>
            {files.length > 0 && (
              <div className="mb-4 space-y-2">
                <p className="text-sm font-medium text-gray-700 dark:text-gray-300">Attachments:</p>
                <div className="space-y-2">
                  {files.map((file, index) => (
                    <div 
                      key={index} 
                      className="flex items-center justify-between bg-gray-50 dark:bg-gray-800 p-2 rounded-md"
                    >
                      <div className="flex items-center space-x-2 text-sm text-gray-700 dark:text-gray-300">
                        <Paperclip className="h-4 w-4" />
                        <span className="truncate max-w-xs">{file.name}</span>
                        <span className="text-gray-500 dark:text-gray-400">
                          ({(file.size / 1024).toFixed(1)} KB)
                        </span>
                      </div>
                      <button 
                        type="button"
                        onClick={() => handleRemoveFile(index)}
                        className="text-gray-400 hover:text-gray-600 dark:hover:text-gray-200"
                      >
                        <X className="h-4 w-4" />
                      </button>
                    </div>
                  ))}
                </div>
              </div>
            )}
            
            <div className="flex space-x-2">
              <button
                type="button"
                onClick={() => fileInputRef.current?.click()}
                className="inline-flex items-center px-3 py-2 border border-gray-300 dark:border-gray-600 shadow-sm text-sm font-medium rounded-md text-gray-700 dark:text-gray-200 bg-white dark:bg-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 dark:focus:ring-offset-gray-800"
                disabled={submitting}
              >
                <Paperclip className="h-4 w-4 mr-2" />
                Attach File
              </button>
              <input
                type="file"
                ref={fileInputRef}
                onChange={handleFileChange}
                className="hidden"
                multiple
                accept="image/*,.pdf,.doc,.docx,.txt"
              />
              
              <button
                type="submit"
                className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 dark:focus:ring-offset-gray-800 disabled:bg-indigo-400 disabled:cursor-not-allowed"
                disabled={submitting || (!newNote.trim() && files.length === 0)}
              >
                {submitting ? (
                  <>
                    <Loader2 className="h-4 w-4 mr-2 animate-spin" />
                    Saving...
                  </>
                ) : (
                  'Add Note'
                )}
              </button>
            </div>
          </div>
        </form>
      )}

      <div className="space-y-4">
        {notes.length > 0 ? (
          notes.map((note) => (
            <div key={note.id} className="bg-gray-50 dark:bg-gray-700/50 rounded-lg p-4">
              <div className="flex items-start space-x-4">
                {/* User Avatar */}
                <div className="flex-shrink-0">
                  {note.authorDetails?.photoURL ? (
                    <img
                      src={note.authorDetails.photoURL}
                      alt="Profile"
                      className="h-10 w-10 rounded-full object-cover"
                    />
                  ) : (
                    <div className="h-10 w-10 rounded-full bg-gray-200 dark:bg-gray-600 flex items-center justify-center">
                      <span className="text-lg font-medium text-gray-600 dark:text-gray-300">
                        {note.authorDetails?.firstName?.[0]?.toUpperCase() || '?'}
                      </span>
                    </div>
                  )}
                </div>

                <div className="flex-1 min-w-0">
                  <div className="flex justify-between items-start">
                    <div className="flex items-center space-x-2">
                      <h4 className="text-sm font-medium text-gray-900 dark:text-white">
                        {note.authorDetails ? 
                          `${note.authorDetails.firstName} ${note.authorDetails.lastName}` : 
                          'Unknown User'}
                      </h4>
                      <span className={`text-xs px-2 py-1 rounded-full capitalize
                        ${note.userType === 'admin' ? 'bg-red-100 text-red-800 dark:bg-red-900/30 dark:text-red-300' : 
                          'bg-blue-100 text-blue-800 dark:bg-blue-900/30 dark:text-blue-300'}`}>
                        {note.userType}
                      </span>
                    </div>

                    {/* Edit/Delete icons */}
                    {user?.uid === note.authorId && editingNoteId !== note.id && (
                      <div className="flex space-x-2 ml-4">
                        <button
                          onClick={() => handleEdit(note)}
                          className="text-gray-400 hover:text-gray-600 dark:hover:text-gray-300"
                          title="Edit note"
                        >
                          <Edit2 className="h-4 w-4" />
                        </button>
                        <button
                          onClick={() => handleDelete(note.id)}
                          className="text-gray-400 hover:text-red-600 dark:hover:text-red-400"
                          title="Delete note"
                        >
                          <Trash2 className="h-4 w-4" />
                        </button>
                      </div>
                    )}
                  </div>

                  <p className="text-xs text-gray-500 dark:text-gray-400 mt-1">
                    {formatDate(note.createdAt)}
                    {note.editedAt && ' (edited)'}
                  </p>

                  {editingNoteId === note.id ? (
                    <div className="mt-2">
                      <textarea
                        value={editedMessage}
                        onChange={(e) => setEditedMessage(e.target.value)}
                        className="w-full rounded-md border-gray-300 dark:border-gray-600 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 dark:bg-gray-700 dark:text-white sm:text-sm p-2"
                        rows={3}
                      />
                      <div className="mt-2 flex space-x-2">
                        <button
                          onClick={() => handleSaveEdit(note.id)}
                          disabled={submitting}
                          className="inline-flex items-center px-2 py-1 text-sm text-green-600 hover:text-green-700 dark:text-green-400"
                        >
                          <Check className="h-4 w-4 mr-1" />
                          Save
                        </button>
                        <button
                          onClick={() => setEditingNoteId(null)}
                          className="inline-flex items-center px-2 py-1 text-sm text-gray-600 hover:text-gray-700 dark:text-gray-400"
                        >
                          <XCircle className="h-4 w-4 mr-1" />
                          Cancel
                        </button>
                      </div>
                    </div>
                  ) : (
                    <p className="mt-2 text-sm text-gray-900 dark:text-white whitespace-pre-wrap">
                      {note.message}
                    </p>
                  )}

                  {/* For posted notes, show clickable attachments */}
                  {note.attachments && note.attachments.length > 0 && (
                    <div className="mt-3 space-y-2">
                      <p className="text-xs font-medium text-gray-500 dark:text-gray-400">Attachments:</p>
                      <div className="grid grid-cols-2 gap-2">
                        {note.attachments.map((file) => (
                          <a
                            key={file.id}
                            href={file.url}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="block group"
                          >
                            {file.type.startsWith('image/') ? (
                              <div className="relative rounded-md overflow-hidden border border-gray-200 dark:border-gray-700 aspect-[4/3]">
                                <img
                                  src={file.url}
                                  alt={file.name}
                                  className="w-full h-full object-cover group-hover:opacity-90 transition-opacity"
                                />
                                <div className="absolute inset-0 flex items-center justify-center opacity-0 group-hover:opacity-100 bg-black bg-opacity-30 transition-opacity">
                                  <span className="text-white text-xs px-2 py-1 rounded-md bg-black bg-opacity-50">
                                    View
                                  </span>
                                </div>
                              </div>
                            ) : (
                              <div className="flex items-center space-x-2 p-2 border border-gray-200 dark:border-gray-700 rounded-md hover:bg-gray-100 dark:hover:bg-gray-800 transition-colors">
                                <Paperclip className="h-4 w-4 text-gray-400" />
                                <span className="text-sm text-gray-700 dark:text-gray-300 truncate">
                                  {file.name}
                                </span>
                              </div>
                            )}
                          </a>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          ))
        ) : (
          <div className="text-center py-8 text-gray-500 dark:text-gray-400">
            <p>No notes yet</p>
            <p className="text-sm mt-1">Add the first note about this user</p>
          </div>
        )}
      </div>
    </div>
  );
} 