import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';
import { db } from '../lib/firebase';
import { collection, query, where, getDocs, getDoc, doc, deleteField } from 'firebase/firestore';
import { Check, ChevronRight, FileText, ClipboardList, Briefcase, Lock } from 'lucide-react';
import toast from 'react-hot-toast';

interface TodoStep {
  id: string;
  title: string;
  description: string;
  isCompleted: boolean;
  link: string;
  icon: JSX.Element;
  order: number;
}

interface OnboardingStatus {
  generalApp: boolean;
  jobProfile: boolean;
  application: boolean;
}

export function CustomerTodo() {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [steps, setSteps] = useState<TodoStep[]>([]);
  const [completedCount, setCompletedCount] = useState(0);
  const [allCompleted, setAllCompleted] = useState(false);

  useEffect(() => {
    if (!user) return;
    
    // Try to get saved onboarding status first for immediate UI feedback
    const savedStatus = sessionStorage.getItem('onboardingStatus');
    if (savedStatus) {
      try {
        const status = JSON.parse(savedStatus) as OnboardingStatus;
        updateStepsFromStatus(status);
        // Still check with server for the most up-to-date status
        checkCompletionStatus();
      } catch (e) {
        console.error('Error parsing saved onboarding status:', e);
        checkCompletionStatus();
      }
    } else {
      checkCompletionStatus();
    }
  }, [user]);

  const updateStepsFromStatus = (status: OnboardingStatus) => {
    const updatedSteps: TodoStep[] = [
      {
        id: 'general-app',
        title: 'Fill out General Application Form',
        description: 'Complete your general application form with your personal, contact, and work details that will be used across all job applications.',
        isCompleted: status.generalApp,
        link: '/general-application/new',
        icon: <FileText className="h-6 w-6 text-indigo-500" />,
        order: 1
      },
      {
        id: 'job-profile',
        title: 'Save a Resume',
        description: 'Save at least one resume for your job applications. You can save multiple resumes for different job roles.',
        isCompleted: status.jobProfile,
        link: '/job-profiles/new',
        icon: <ClipboardList className="h-6 w-6 text-indigo-500" />,
        order: 2
      },
      {
        id: 'submit-app',
        title: 'Submit Your First Application',
        description: 'Submit a job application URL for our agents to process. Your application will be completed by our team and you\'ll be notified when it\'s done.',
        isCompleted: status.application,
        link: '/submit-application',
        icon: <Briefcase className="h-6 w-6 text-indigo-500" />,
        order: 3
      }
    ];
    
    setSteps(updatedSteps);
    
    // Calculate completed count
    const completed = [status.generalApp, status.jobProfile, status.application].filter(Boolean).length;
    setCompletedCount(completed);
    
    // Check if all steps are completed
    setAllCompleted(completed === 3);
    
    // Reduce loading time if we have saved status
    setLoading(false);
  };

  const checkCompletionStatus = async () => {
    if (!user) return;
    setLoading(true);

    try {
      // Check step 1: General Application
      // Get the user document to check userGeneralApplications
      const userDoc = await getDoc(doc(db, 'users', user.uid));
      const userData = userDoc.data();
      const userGeneralApps = userData?.userGeneralApplications || {};
      
      // First, get the valid general application IDs (filter out null values)
      const validGeneralAppIds = Object.entries(userGeneralApps)
        .filter(([_, value]) => value !== null)
        .map(([key, _]) => key);
      
      let hasGeneralApp = false;
      
      // If there are valid IDs, check if any are complete
      if (validGeneralAppIds.length > 0) {
        // Fetch each general application to check its isComplete status
        for (const appId of validGeneralAppIds) {
          try {
            const appDoc = await getDoc(doc(db, 'generalApplications', appId));
            if (appDoc.exists() && appDoc.data()?.isComplete === true) {
              hasGeneralApp = true;
              break;
            }
          } catch (error) {
            console.error(`Error checking general application ${appId}:`, error);
          }
        }
      }

      // Check step 2: Job Profile
      const profilesQuery = query(
        collection(db, 'jobProfiles'),
        where('userId', '==', user.uid)
      );
      const profilesSnapshot = await getDocs(profilesQuery);
      const hasJobProfile = !profilesSnapshot.empty;

      // Check step 3: Submitted Application
      const applicationsQuery = query(
        collection(db, 'applications'),
        where('userId', '==', user.uid)
      );
      const applicationsSnapshot = await getDocs(applicationsQuery);
      const hasSubmittedApp = !applicationsSnapshot.empty;

      // Save onboarding status to session storage
      const status: OnboardingStatus = {
        generalApp: hasGeneralApp,
        jobProfile: hasJobProfile,
        application: hasSubmittedApp
      };
      sessionStorage.setItem('onboardingStatus', JSON.stringify(status));
      
      // Update steps with completion status
      updateStepsFromStatus(status);

      // If all steps are completed, redirect to dashboard
      if (hasGeneralApp && hasJobProfile && hasSubmittedApp) {
        toast.success('All onboarding steps completed! Welcome to your dashboard.');
        navigate('/dashboard');
      }
    } catch (error) {
      console.error('Error checking completion status:', error);
      toast.error('Failed to check your progress. Please try again.');
      setLoading(false);
    }
  };

  // Function to determine if a step is accessible
  const isStepAccessible = (step: TodoStep): boolean => {
    // First step is always accessible
    if (step.order === 1) return true;
    
    // Check if all previous steps are completed
    return steps
      .filter(s => s.order < step.order)
      .every(s => s.isCompleted);
  };
  
  // Function to handle step click and enforce sequential progression
  const handleStepClick = (step: TodoStep) => {
    if (!isStepAccessible(step)) {
      toast.error('Please complete the previous steps first', { icon: '🔒' });
      return;
    }
    
    sessionStorage.setItem('onboardingFlow', 'true');
    navigate(step.link);
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen bg-gray-50 dark:bg-gray-900">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-indigo-500"></div>
      </div>
    );
  }

  return (
    <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-12 bg-gray-50 dark:bg-gray-900 min-h-screen">
      <div className="text-center mb-8">
        <h1 className="text-3xl font-bold text-gray-900 dark:text-white">Welcome to BlitzApply!</h1>
        <p className="mt-2 text-lg text-gray-600 dark:text-gray-400">
          Complete these steps to get started with outsourcing your job applications
        </p>
        <div className="mt-4 flex justify-center">
          <div className={`rounded-full px-5 py-2 shadow-sm ${
            completedCount === 3 
              ? 'bg-green-100 dark:bg-green-900/30' 
              : 'bg-amber-100 dark:bg-amber-900/30'
          }`}>
            <span className={`font-medium ${
              completedCount === 3 
                ? 'text-green-600 dark:text-green-400' 
                : 'text-amber-600 dark:text-amber-400'
            }`}>
              {completedCount}/3 steps completed
            </span>
          </div>
        </div>
      </div>

      <div className="bg-white dark:bg-gray-800 shadow rounded-lg overflow-hidden">
        <div className="p-6">
          <div className="space-y-0">
            {steps.map((step) => {
              const isAccessible = isStepAccessible(step);
              
              return (
                <div 
                  key={step.id} 
                  className={`relative border-l-4 pl-8 pb-8 ${
                    step.isCompleted 
                      ? 'border-green-500 dark:border-green-400' 
                      : isAccessible 
                        ? 'border-indigo-500 dark:border-indigo-400'
                        : 'border-gray-300 dark:border-gray-600'
                  }`}
                >
                  {/* Step circle indicator */}
                  <div 
                    className={`absolute left-0 transform -translate-x-1/2 w-10 h-10 rounded-full flex items-center justify-center border-4 ${
                      step.isCompleted 
                        ? 'bg-green-100 dark:bg-green-900 border-green-500 dark:border-green-400' 
                        : isAccessible
                          ? 'bg-white dark:bg-gray-700 border-indigo-500 dark:border-indigo-400'
                          : 'bg-gray-100 dark:bg-gray-800 border-gray-300 dark:border-gray-600'
                    }`}
                  >
                    {step.isCompleted ? (
                      <Check className="h-5 w-5 text-green-600 dark:text-green-400" />
                    ) : (
                      <span className="text-lg font-semibold text-gray-700 dark:text-gray-300">{step.order}</span>
                    )}
                  </div>
                  
                  {/* Step content */}
                  <div className={`mt-1 mb-4 ${!isAccessible && !step.isCompleted ? 'opacity-50' : ''}`}>
                    <div className="flex items-center">
                      {step.icon}
                      <h3 className="ml-2 text-lg font-medium text-gray-900 dark:text-white flex items-center">
                        {step.title}
                        {!isAccessible && !step.isCompleted && (
                          <Lock className="ml-2 h-4 w-4 text-gray-400" />
                        )}
                      </h3>
                    </div>
                    <p className="mt-1 text-sm text-gray-600 dark:text-gray-400">
                      {step.description}
                    </p>
                    {step.id === 'general-app' && !step.isCompleted && (
                      <p className="mt-2 text-sm font-medium bg-blue-50 dark:bg-blue-900/20 text-blue-600 dark:text-blue-400 p-3 rounded-md">
                        Oh come on now, not another form ? We promise this is the LAST one you'll ever fill out! 🎉
                      </p>
                    )}
                  </div>
                  
                  {/* Action button */}
                  <div className="mt-2">
                    {step.isCompleted ? (
                      <span className="inline-flex items-center px-3 py-1 rounded-full text-sm font-medium bg-green-100 text-green-800 dark:bg-green-900/40 dark:text-green-400">
                        <Check className="mr-1 h-4 w-4" /> Completed
                      </span>
                    ) : isAccessible ? (
                      <button
                        onClick={() => handleStepClick(step)}
                        className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-600 hover:bg-green-700 dark:bg-green-800 dark:hover:bg-green-900"
                      >
                        Complete Now
                        <ChevronRight className="ml-1 h-4 w-4" />
                      </button>
                    ) : (
                      <button
                        disabled
                        className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gray-400 dark:bg-gray-600 cursor-not-allowed opacity-70"
                      >
                        <Lock className="mr-1 h-4 w-4" />
                        Complete Previous Steps First
                      </button>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        <div className="bg-gray-50 dark:bg-gray-700/30 px-6 py-4 border-t border-gray-200 dark:border-gray-700">
          <div className="flex justify-between items-center">
            <div>
              <p className="text-sm text-gray-600 dark:text-gray-400">
                {allCompleted ? (
                  'All steps completed! You can now access your dashboard.'
                ) : (
                  'You must complete all steps above to access your dashboard.'
                )}
              </p>
            </div>
            {allCompleted && (
              <button
                onClick={() => navigate('/dashboard')}
                className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 dark:bg-indigo-500 dark:hover:bg-indigo-600"
              >
                Go to Dashboard
                <ChevronRight className="ml-1 h-4 w-4" />
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
} 