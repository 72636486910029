import { useState, useEffect } from 'react';
import { RecaptchaVerifier, PhoneAuthProvider, updatePhoneNumber } from 'firebase/auth';
import { auth } from '../lib/firebase';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../lib/firebase';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';
import { TelegramService, TELEGRAM_GROUP_ID, NEW_USER_SIGNUPS_TOPIC_ID } from '../services/telegram';

// Add type declaration for window.recaptchaVerifier
declare global {
  interface Window {
    recaptchaVerifier: RecaptchaVerifier | undefined;
    recaptchaWidgetId: number | undefined;
  }
}

export function PhoneVerificationBanner() {
  const [countryCode, setCountryCode] = useState('+1');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [verificationId, setVerificationId] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [isCodeSent, setIsCodeSent] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { fetchUserProfile } = useAuth();

  // The full phone number combining country code and phone number
  const fullPhoneNumber = `${countryCode}${phoneNumber}`;

  useEffect(() => {
    // Cleanup reCAPTCHA on component unmount
    return () => {
      if (window.recaptchaVerifier) {
        window.recaptchaVerifier.clear();
        window.recaptchaVerifier = undefined;
        window.recaptchaWidgetId = undefined;
      }
    };
  }, []);

  const setupRecaptcha = () => {
    try {
      // Clear existing reCAPTCHA if any
      if (window.recaptchaVerifier) {
        window.recaptchaVerifier.clear();
        window.recaptchaVerifier = undefined;
        window.recaptchaWidgetId = undefined;
      }

      window.recaptchaVerifier = new RecaptchaVerifier(auth, 'send-code-button', {
        size: 'invisible',
        callback: () => {
          // reCAPTCHA solved, continue with phone verification
          console.log('reCAPTCHA verified');
        },
        'expired-callback': () => {
          // Reset the verifier
          window.recaptchaVerifier?.clear();
          window.recaptchaVerifier = undefined;
          window.recaptchaWidgetId = undefined;
          toast.error('reCAPTCHA expired. Please try again.');
        }
      });

    } catch (error: any) {
      console.error('Error setting up reCAPTCHA:', error);
      toast.error('Failed to set up verification. Please try again.');
      if (window.recaptchaVerifier) {
        window.recaptchaVerifier.clear();
        window.recaptchaVerifier = undefined;
        window.recaptchaWidgetId = undefined;
      }
    }
  };

  const handleSendCode = async () => {
    if (!phoneNumber) {
      toast.error('Please enter a phone number');
      return;
    }

    try {
      setLoading(true);
      
      // Clear existing reCAPTCHA if any
      if (window.recaptchaVerifier) {
        window.recaptchaVerifier.clear();
        window.recaptchaVerifier = undefined;
        window.recaptchaWidgetId = undefined;
      }

      // Setup new reCAPTCHA
      setupRecaptcha();

      if (!window.recaptchaVerifier) {
        throw new Error('Failed to initialize reCAPTCHA');
      }

      const phoneProvider = new PhoneAuthProvider(auth);
      const verificationId = await phoneProvider.verifyPhoneNumber(
        fullPhoneNumber,
        window.recaptchaVerifier
      );
      
      setVerificationId(verificationId);
      setIsCodeSent(true);
      toast.success('Verification code sent!');
    } catch (error: any) {
      console.error('Error sending code:', error);
      if (error.code === 'auth/invalid-phone-number') {
        toast.error('Invalid phone number format. Please use international format (e.g., +1234567890)');
      } else if (error.code === 'auth/quota-exceeded') {
        toast.error('SMS quota exceeded. Please try again later.');
      } else {
        toast.error(error.message || 'Failed to send verification code');
        // If there's an error, suggest refreshing the page
        toast.error('Please refresh the page and try again with the correct number');
      }
      // Reset state on error
      setIsCodeSent(false);
      setVerificationId('');
      // Reset reCAPTCHA on error
      if (window.recaptchaVerifier) {
        window.recaptchaVerifier.clear();
        window.recaptchaVerifier = undefined;
        window.recaptchaWidgetId = undefined;
      }
    } finally {
      setLoading(false);
    }
  };

  // Add a function to handle phone number changes
  const handlePhoneNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPhoneNumber(e.target.value);
    // If user is changing the phone number after an error, reset the verification state
    if (isCodeSent) {
      setIsCodeSent(false);
      setVerificationId('');
      if (window.recaptchaVerifier) {
        window.recaptchaVerifier.clear();
        window.recaptchaVerifier = undefined;
        window.recaptchaWidgetId = undefined;
      }
    }
  };

  // Add a function to handle country code changes
  const handleCountryCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCountryCode(e.target.value);
    // If user is changing the country code after an error, reset the verification state
    if (isCodeSent) {
      setIsCodeSent(false);
      setVerificationId('');
      if (window.recaptchaVerifier) {
        window.recaptchaVerifier.clear();
        window.recaptchaVerifier = undefined;
        window.recaptchaWidgetId = undefined;
      }
    }
  };

  const handleVerifyCode = async () => {
    if (!verificationCode) {
      toast.error('Please enter the verification code');
      return;
    }

    try {
      setLoading(true);
      const cred = PhoneAuthProvider.credential(verificationId, verificationCode);
      
      if (auth.currentUser) {
        // First update phone number in Firebase Auth
        await updatePhoneNumber(auth.currentUser, cred);
        
        // Then update the user's profile in Firestore
        const userRef = doc(db, 'users', auth.currentUser.uid);
        await updateDoc(userRef, {
          phoneNumber: fullPhoneNumber,
          isPhoneVerified: true,
          updatedAt: new Date().toISOString(),
          userType: 'customer' // Ensure user type is set
        });
        
        // Wait for the profile to be refreshed in the auth context
        await fetchUserProfile(auth.currentUser.uid);
        
        // Send Telegram notification
        try {
          await TelegramService.logPhoneVerification(
            auth.currentUser.uid,
            fullPhoneNumber,
            auth.currentUser.email
          );
        } catch (telegramError) {
          console.error('Error sending Telegram notification:', telegramError);
          // Don't throw the error as phone verification was successful
        }
        
        // Show success message
        toast.success('Phone number verified successfully!');
        
        // Ensure all state updates and async operations are complete
        await new Promise(resolve => setTimeout(resolve, 1000));
        
        // Use window.location for a hard redirect
        window.location.href = '/dashboard';
      }
    } catch (error: any) {
      console.error('Error verifying code:', error);
      toast.error(error.message || 'Failed to verify code');
      // Reset reCAPTCHA on error
      if (window.recaptchaVerifier) {
        window.recaptchaVerifier.clear();
        window.recaptchaVerifier = undefined;
        window.recaptchaWidgetId = undefined;
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="max-w-3xl mx-auto">
      <div className="bg-white dark:bg-gray-800 shadow-sm rounded-lg p-6 mb-6">
        <div className="bg-green-50 dark:bg-green-900/30 p-4 rounded-md mb-6">
          <p className="text-green-700 dark:text-green-200">
            <span className="font-medium">Recommended:</span> Please enter your WhatsApp-linked phone number to receive important updates about your application status.
          </p>
        </div>
        
        {!isCodeSent ? (
          <div className="space-y-4">
            <div>
              <label htmlFor="phone" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                Phone Number (WhatsApp preferred)
              </label>
              <div className="mt-1 flex rounded-md shadow-sm">
                <div className="flex-shrink-0 mr-2">
                  <input
                    type="text"
                    id="countryCode"
                    value={countryCode}
                    onChange={handleCountryCodeChange}
                    placeholder="+1"
                    className="h-full block w-full px-3 py-2 rounded-md border-gray-300 dark:border-gray-600 dark:bg-gray-700 dark:text-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                    disabled={loading}
                    style={{width: '60px'}}
                  />
                </div>
                <input
                  type="tel"
                  id="phone"
                  value={phoneNumber}
                  onChange={handlePhoneNumberChange}
                  placeholder="1234567890"
                  className="block w-full px-4 py-2 rounded-md border-gray-300 dark:border-gray-600 dark:bg-gray-700 dark:text-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                  disabled={loading}
                />
                <button
                  id="send-code-button"
                  onClick={handleSendCode}
                  disabled={loading || !phoneNumber}
                  className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:opacity-50"
                >
                  {loading ? 'Sending...' : 'Send Code'}
                </button>
              </div>
              <p className="mt-2 text-xs text-gray-500 dark:text-gray-400">
                By confirming your number, you agree to receive communications via WhatsApp.
              </p>
            </div>
          </div>
        ) : (
          <div className="space-y-4">
            <div>
              <label htmlFor="code" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                Verification Code
              </label>
              <div className="mt-1 flex rounded-md shadow-sm">
                <input
                  type="text"
                  id="code"
                  value={verificationCode}
                  onChange={(e) => setVerificationCode(e.target.value)}
                  placeholder="Enter 6-digit code"
                  className="block w-full px-4 py-2 rounded-md border-gray-300 dark:border-gray-600 dark:bg-gray-700 dark:text-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                  disabled={loading}
                />
                <button
                  onClick={handleVerifyCode}
                  disabled={loading || !verificationCode}
                  className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:opacity-50"
                >
                  {loading ? 'Verifying...' : 'Verify Code'}
                </button>
              </div>
            </div>
            <button
              onClick={() => {
                setIsCodeSent(false);
                window.recaptchaVerifier = undefined;
                window.location.reload();
              }}
              className="text-sm text-indigo-600 dark:text-indigo-400 hover:text-indigo-500"
            >
              Change phone number
            </button>
          </div>
        )}
      </div>
    </div>
  );
} 