import { Application } from '../types/application';
import { getDoc, doc } from 'firebase/firestore';
import { db } from '../lib/firebase';

const TELEGRAM_BOT_TOKEN = import.meta.env.VITE_TELEGRAM_BOT_TOKEN;
if (!TELEGRAM_BOT_TOKEN) {
  console.error('VITE_TELEGRAM_BOT_TOKEN is not set in environment variables');
}

const TELEGRAM_GROUP_ID = '-1002405665228';
const AGENTS_GROUP_ID = '-1002236929387';
const CHANGELOG_TOPIC_ID = 5;
const PENDING_TICKETS_TOPIC_ID = 31;
const CANCELLED_TICKETS_TOPIC_ID = 19;
const STALLED_TICKETS_TOPIC_ID = 82;
const NEW_USER_SIGNUPS_TOPIC_ID = 21;
const NEW_TICKET_SUBMISSIONS_TOPIC_ID = 174;
const AGENTS_NEW_APPLICATIONS_TOPIC_ID = 2;
const TICKET_UNASSIGNMENTS_TOPIC_ID = 17;
const PAYMENT_SUCCESS_TOPIC_ID = 211;

// Export constants that need to be used in other files
export { TELEGRAM_GROUP_ID, NEW_USER_SIGNUPS_TOPIC_ID };

interface TelegramMessage {
  message_thread_id?: number;
  chat_id: string;
  text: string;
  parse_mode?: 'HTML' | 'Markdown' | 'MarkdownV2';
}

/**
 * Client-side Telegram Service
 * 
 * NOTE: Most notification methods have been moved to server-side implementation
 * to prevent duplicate notifications and ensure reliability.
 * 
 * This service now only contains:
 * 1. Methods that must be triggered from client-side UI actions
 * 2. Testing utilities
 * 3. Helper methods used by other parts of the application
 */
export class TelegramService {
  private static async sendMessage(message: TelegramMessage): Promise<void> {
    try {
      const response = await fetch(`https://api.telegram.org/bot${TELEGRAM_BOT_TOKEN}/sendMessage`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(message),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(`Telegram API Error: ${errorData.description || response.statusText}`);
      }

      const data = await response.json();
      if (!data.ok) {
        throw new Error(`Telegram API Error: ${data.description}`);
      }
    } catch (error) {
      console.error('Error sending Telegram message:', error);
      throw error;
    }
  }

  private static async sendPhoto(chatId: string, photo: File, caption?: string, messageThreadId?: number): Promise<void> {
    try {
      const formData = new FormData();
      formData.append('chat_id', chatId);
      formData.append('photo', photo);
      
      if (caption) {
        formData.append('caption', caption);
        formData.append('parse_mode', 'HTML');
      }
      
      if (messageThreadId) {
        formData.append('message_thread_id', messageThreadId.toString());
      }

      const response = await fetch(`https://api.telegram.org/bot${TELEGRAM_BOT_TOKEN}/sendPhoto`, {
        method: 'POST',
        body: formData,
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(`Telegram API Error: ${errorData.description || response.statusText}`);
      }

      const data = await response.json();
      if (!data.ok) {
        throw new Error(`Telegram API Error: ${data.description}`);
      }
    } catch (error) {
      console.error('Error sending photo to Telegram:', error);
      throw error;
    }
  }

  /**
   * Tests connection to the Telegram API
   * Useful for client-side troubleshooting
   */
  static async testConnection(): Promise<boolean> {
    try {
      await this.sendMessage({
        chat_id: TELEGRAM_GROUP_ID,
        message_thread_id: CHANGELOG_TOPIC_ID,
        text: '🔄 Bot connection test successful (Client)',
        parse_mode: 'HTML',
      });
      return true;
    } catch (error) {
      console.error('Telegram bot connection test failed:', error);
      return false;
    }
  }

  /**
   * Uploads a screenshot for a pending or cancelled ticket
   * This is kept client-side because it handles File objects from the browser
   */
  static async uploadScreenshotForTicket(application: Application, files: File[], isPending: boolean): Promise<void> {
    try {
      const topicId = isPending ? PENDING_TICKETS_TOPIC_ID : CANCELLED_TICKETS_TOPIC_ID;
      const ticketType = isPending ? "Pending" : "Cancelled";
      
      if (files && files.length > 0) {
        for (const file of files) {
          await this.sendPhoto(
            TELEGRAM_GROUP_ID,
            file,
            `📎 Screenshot for ${ticketType} Application ID: ${application.id}`,
            topicId
          );
        }
      }
    } catch (error) {
      console.error(`Error uploading screenshot for ${isPending ? 'pending' : 'cancelled'} ticket:`, error);
    }
  }
  
  /**
   * Helper function to format duration between two dates
   * Used by both client and server implementations
   */
  static formatDuration(startDate: Date, endDate: Date): string {
    const diffInMs = endDate.getTime() - startDate.getTime();
    const diffInDays = Math.floor(diffInMs / (1000 * 60 * 60 * 24));
    const diffInHours = Math.floor((diffInMs % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const diffInMinutes = Math.floor((diffInMs % (1000 * 60 * 60)) / (1000 * 60));
    
    if (diffInDays > 0) {
      return `${diffInDays} days, ${diffInHours} hours`;
    } else if (diffInHours > 0) {
      return `${diffInHours} hours, ${diffInMinutes} minutes`;
    } else {
      return `${diffInMinutes} minutes`;
    }
  }
} 