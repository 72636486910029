import { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { db } from '../lib/firebase';
import { doc, getDoc, collection, getDocs, query, where, limit } from 'firebase/firestore';
import { useAuth } from '../hooks/useAuth';
import { GeneralApplication } from '../types/generalApplication';
import { FaRegCopy } from 'react-icons/fa';
import { toast } from 'react-hot-toast';
import { UserNotes } from '../components/comments/UserNotes';

export function UserInformationPage() {
  const { userId } = useParams();
  const navigate = useNavigate();
  const { user, userProfile, loading: authLoading } = useAuth();
  const [loading, setLoading] = useState(true);
  const [userInfo, setUserInfo] = useState<any>(null);
  const [generalApplication, setGeneralApplication] = useState<GeneralApplication | null>(null);
  const [error, setError] = useState<string | null>(null);
  const authChecked = useRef(false);

  // Only check auth once to prevent flashing between states
  useEffect(() => {
    // Only proceed when authLoading is complete
    if (authLoading) {
      return;
    }
    
    if (!authChecked.current) {
      authChecked.current = true;
      console.log("Initial auth check:", { 
        userId, 
        isLoggedIn: !!user, 
        userType: userProfile?.userType,
        currentUserId: user?.uid,
        authLoading
      });
      
      if (!user) {
        setError('Please log in to continue');
        setLoading(false);
        return;
      }
      
      // Ensure userProfile is loaded before checking type
      if (!userProfile) {
        console.log("User profile not loaded yet");
        // Don't set error, just wait for profile to load
        return;
      }
      
      // Check for agent or admin permissions
      if (userProfile.userType !== 'agent' && userProfile.userType !== 'admin') {
        setError('You do not have permission to view this information');
        setLoading(false);
        return;
      }
      
      if (userId) {
        // Only load if we have a userId and are authenticated
        loadUserInformation();
      } else {
        setError('User ID is missing');
        setLoading(false);
      }
    }
  }, [user, userProfile, userId, authLoading]);

  const loadUserInformation = async () => {
    try {
      console.log("Starting to load user information for userId:", userId);
      
      // Load user data
      const userRef = doc(db, 'users', userId!);
      console.log("Fetching user document from:", userRef.path);
      const userSnapshot = await getDoc(userRef);
      
      if (!userSnapshot.exists()) {
        console.log("User document not found");
        throw new Error('User not found');
      }
      
      const userData = userSnapshot.data();
      console.log("User document found:", userSnapshot.id);
      setUserInfo(userData);
      
      // Try to find the general application - first in subcollection
      try {
        console.log("Attempting to find generalApplication from subcollection");
        const userGeneralAppsRef = collection(db, `users/${userId}/userGeneralApplications`);
        const userGeneralAppsSnapshot = await getDocs(userGeneralAppsRef);
        
        if (!userGeneralAppsSnapshot.empty) {
          const generalAppDoc = userGeneralAppsSnapshot.docs[0];
          console.log("Found generalApp reference in subcollection:", generalAppDoc.id);
          
          // Get the actual general application
          const generalAppRef = doc(db, 'generalApplications', generalAppDoc.id);
          const generalAppSnapshot = await getDoc(generalAppRef);
          
          if (generalAppSnapshot.exists()) {
            console.log("Successfully retrieved general application document");
            setGeneralApplication({ 
              id: generalAppSnapshot.id, 
              ...generalAppSnapshot.data() 
            } as GeneralApplication);
          } else {
            console.log("Referenced general application document not found");
          }
        } else {
          console.log("No documents in subcollection");
        }
      } catch (subError) {
        console.error("Error accessing subcollection:", subError);
      }
      
      // If we didn't find it in the subcollection, try the main collection
      if (!generalApplication) {
        try {
          console.log("Trying main collection lookup for generalApplication");
          const generalAppsRef = collection(db, 'generalApplications');
          const generalAppsQuery = query(
            generalAppsRef,
            where('userId', '==', userId),
            limit(1)
          );
          
          const generalAppsSnapshot = await getDocs(generalAppsQuery);
          
          if (!generalAppsSnapshot.empty) {
            console.log("Found general application in main collection");
            setGeneralApplication({ 
              id: generalAppsSnapshot.docs[0].id, 
              ...generalAppsSnapshot.docs[0].data() 
            } as GeneralApplication);
          } else {
            console.log("No general application found in main collection");
          }
        } catch (mainError) {
          console.error("Error querying main collection:", mainError);
        }
      }
    } catch (error: any) {
      console.error('Error loading user information:', error);
      setError(error.message || 'Failed to load user information');
    } finally {
      setLoading(false);
    }
  };

  const handleCopyToClipboard = (text: string, label: string) => {
    navigator.clipboard.writeText(text);
    toast.success(`${label} copied to clipboard`);
  };

  const renderCopyableField = (value: string | undefined, label: string) => {
    if (!value) return 'Not provided';
    
    return (
      <button
        onClick={() => handleCopyToClipboard(value, label)}
        className="group flex items-center space-x-2 w-full text-left"
        title={`Click to copy ${label}`}
      >
        <code className="text-sm bg-gray-100 dark:bg-gray-700 px-3 py-2 rounded group-hover:bg-gray-200 dark:group-hover:bg-gray-600 transition-colors flex-grow">
          {value}
        </code>
        <FaRegCopy className="h-4 w-4 text-gray-500 group-hover:text-gray-700 dark:text-gray-400 dark:group-hover:text-gray-300 transition-colors flex-shrink-0" />
      </button>
    );
  };

  // Display loading state when auth is still loading
  if (authLoading || (loading && !error)) {
    return (
      <div className="flex justify-center py-12">
        <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-indigo-600 dark:border-indigo-400"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="bg-white dark:bg-gray-800 shadow rounded-lg p-6">
          <div className="text-center">
            <h3 className="mt-2 text-lg font-medium text-gray-900 dark:text-white">Error</h3>
            <p className="mt-1 text-gray-500 dark:text-gray-400">{error}</p>
            <button
              onClick={() => navigate(-1)}
              className="mt-4 inline-flex items-center px-4 py-2 border border-gray-300 dark:border-gray-600 rounded-md shadow-sm text-sm font-medium text-gray-700 dark:text-gray-200 bg-white dark:bg-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
            >
              Go Back
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-white dark:bg-gray-900">
      <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="mb-6 flex items-center justify-between">
          <h1 className="text-2xl font-bold text-gray-900 dark:text-white">
            User Information
          </h1>
          <button
            onClick={() => navigate(-1)}
            className="px-4 py-2 border border-gray-300 dark:border-gray-600 rounded-md shadow-sm text-sm font-medium text-gray-700 dark:text-gray-200 bg-white dark:bg-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
          >
            Go Back
          </button>
        </div>

        {/* Basic User Information from Auth */}
        {userInfo && (
          <div className="bg-white dark:bg-gray-800 shadow rounded-lg overflow-hidden">
            <div className="px-4 py-5 sm:px-6 bg-gray-50 dark:bg-gray-700">
              <h2 className="text-lg font-medium text-gray-900 dark:text-white">Basic User Information</h2>
            </div>
            <div className="px-4 py-5 sm:p-6 grid grid-cols-1 md:grid-cols-2 gap-6">
              {userInfo.firstName && (
                <div>
                  <dt className="text-sm font-medium text-gray-500 dark:text-gray-400">Name</dt>
                  <dd className="mt-1 text-sm text-gray-900 dark:text-gray-100">
                    {renderCopyableField(`${userInfo.firstName} ${userInfo.lastName || ''}`.trim(), 'Name')}
                  </dd>
                </div>
              )}
              {userInfo.email && (
                <div>
                  <dt className="text-sm font-medium text-gray-500 dark:text-gray-400">Email</dt>
                  <dd className="mt-1 text-sm text-gray-900 dark:text-gray-100">
                    {renderCopyableField(userInfo.email, 'Email')}
                  </dd>
                </div>
              )}
              {userInfo.phoneNumber && (
                <div>
                  <dt className="text-sm font-medium text-gray-500 dark:text-gray-400">Phone</dt>
                  <dd className="mt-1 text-sm text-gray-900 dark:text-gray-100">
                    {renderCopyableField(userInfo.phoneNumber, 'Phone')}
                  </dd>
                </div>
              )}
            </div>
          </div>
        )}

        {!generalApplication && !loading && (
          <div className="bg-white dark:bg-gray-800 shadow rounded-lg p-6 mt-6">
            <div className="flex items-center justify-center text-center">
              <div>
                <h3 className="text-lg font-medium text-gray-900 dark:text-white mb-2">No Detailed Application Information</h3>
                <p className="text-gray-500 dark:text-gray-400">
                  This user hasn't completed their general application form yet, or the data couldn't be accessed.
                </p>
                <p className="text-gray-500 dark:text-gray-400 mt-2">
                  Only the basic user information is available.
                </p>
              </div>
            </div>
          </div>
        )}

        {generalApplication && (
          <>
            {/* Personal Information */}
            <div className="bg-white dark:bg-gray-800 shadow rounded-lg overflow-hidden mt-6">
              <div className="px-4 py-5 sm:px-6 bg-gray-50 dark:bg-gray-700">
                <h2 className="text-lg font-medium text-gray-900 dark:text-white">Personal Information</h2>
              </div>
              <div className="px-4 py-5 sm:p-6 grid grid-cols-1 md:grid-cols-2 gap-6">
                <div>
                  <dt className="text-sm font-medium text-gray-500 dark:text-gray-400">First Name</dt>
                  <dd className="mt-1 text-sm text-gray-900 dark:text-gray-100">
                    {renderCopyableField(generalApplication.firstName, 'First Name')}
                  </dd>
                </div>
                <div>
                  <dt className="text-sm font-medium text-gray-500 dark:text-gray-400">Middle Name</dt>
                  <dd className="mt-1 text-sm text-gray-900 dark:text-gray-100">
                    {renderCopyableField(generalApplication.middleName, 'Middle Name')}
                  </dd>
                </div>
                <div>
                  <dt className="text-sm font-medium text-gray-500 dark:text-gray-400">Last Name</dt>
                  <dd className="mt-1 text-sm text-gray-900 dark:text-gray-100">
                    {renderCopyableField(generalApplication.lastName, 'Last Name')}
                  </dd>
                </div>
                <div>
                  <dt className="text-sm font-medium text-gray-500 dark:text-gray-400">Preferred Name</dt>
                  <dd className="mt-1 text-sm text-gray-900 dark:text-gray-100">
                    {renderCopyableField(generalApplication.preferredFirstName, 'Preferred Name')}
                  </dd>
                </div>
              </div>
            </div>

            {/* Contact Information */}
            <div className="bg-white dark:bg-gray-800 shadow rounded-lg overflow-hidden mt-6">
              <div className="px-4 py-5 sm:px-6 bg-gray-50 dark:bg-gray-700">
                <h2 className="text-lg font-medium text-gray-900 dark:text-white">Contact Information</h2>
              </div>
              <div className="px-4 py-5 sm:p-6 grid grid-cols-1 md:grid-cols-2 gap-6">
                <div>
                  <dt className="text-sm font-medium text-gray-500 dark:text-gray-400">Primary Email</dt>
                  <dd className="mt-1 text-sm text-gray-900 dark:text-gray-100">
                    {renderCopyableField(generalApplication.primaryEmail, 'Primary Email')}
                  </dd>
                </div>
                <div>
                  <dt className="text-sm font-medium text-gray-500 dark:text-gray-400">Secondary Email</dt>
                  <dd className="mt-1 text-sm text-gray-900 dark:text-gray-100">
                    {renderCopyableField(generalApplication.secondaryEmail, 'Secondary Email')}
                  </dd>
                </div>
                <div>
                  <dt className="text-sm font-medium text-gray-500 dark:text-gray-400">Phone Number</dt>
                  <dd className="mt-1 text-sm text-gray-900 dark:text-gray-100">
                    {renderCopyableField(`${generalApplication.countryCallingCode} ${generalApplication.phoneNumber}`, 'Phone Number')}
                  </dd>
                </div>
              </div>
            </div>

            {/* Address Information */}
            <div className="bg-white dark:bg-gray-800 shadow rounded-lg overflow-hidden mt-6">
              <div className="px-4 py-5 sm:px-6 bg-gray-50 dark:bg-gray-700">
                <h2 className="text-lg font-medium text-gray-900 dark:text-white">Address Information</h2>
              </div>
              <div className="px-4 py-5 sm:p-6 grid grid-cols-1 md:grid-cols-2 gap-6">
                <div className="md:col-span-2">
                  <dt className="text-sm font-medium text-gray-500 dark:text-gray-400">Street Address</dt>
                  <dd className="mt-1 text-sm text-gray-900 dark:text-gray-100">
                    {renderCopyableField(generalApplication.street, 'Street Address')}
                  </dd>
                </div>
                <div>
                  <dt className="text-sm font-medium text-gray-500 dark:text-gray-400">Apt/Suite/Unit</dt>
                  <dd className="mt-1 text-sm text-gray-900 dark:text-gray-100">
                    {renderCopyableField(generalApplication.aptSuiteUnit, 'Apt/Suite/Unit')}
                  </dd>
                </div>
                <div>
                  <dt className="text-sm font-medium text-gray-500 dark:text-gray-400">City</dt>
                  <dd className="mt-1 text-sm text-gray-900 dark:text-gray-100">
                    {renderCopyableField(generalApplication.city, 'City')}
                  </dd>
                </div>
                <div>
                  <dt className="text-sm font-medium text-gray-500 dark:text-gray-400">State</dt>
                  <dd className="mt-1 text-sm text-gray-900 dark:text-gray-100">
                    {renderCopyableField(generalApplication.state, 'State')}
                  </dd>
                </div>
                <div>
                  <dt className="text-sm font-medium text-gray-500 dark:text-gray-400">Zip Code</dt>
                  <dd className="mt-1 text-sm text-gray-900 dark:text-gray-100">
                    {renderCopyableField(generalApplication.zipCode, 'Zip Code')}
                  </dd>
                </div>
                <div>
                  <dt className="text-sm font-medium text-gray-500 dark:text-gray-400">Country</dt>
                  <dd className="mt-1 text-sm text-gray-900 dark:text-gray-100">
                    {renderCopyableField(generalApplication.country, 'Country')}
                  </dd>
                </div>
              </div>
            </div>

            {/* Work Authorization */}
            <div className="bg-white dark:bg-gray-800 shadow rounded-lg overflow-hidden mt-6">
              <div className="px-4 py-5 sm:px-6 bg-gray-50 dark:bg-gray-700">
                <h2 className="text-lg font-medium text-gray-900 dark:text-white">Work Authorization</h2>
              </div>
              <div className="px-4 py-5 sm:p-6 grid grid-cols-1 md:grid-cols-2 gap-6">
                <div>
                  <dt className="text-sm font-medium text-gray-500 dark:text-gray-400">Citizenship</dt>
                  <dd className="mt-1 text-sm text-gray-900 dark:text-gray-100">
                    {renderCopyableField(generalApplication.currentCitizenship, 'Citizenship')}
                  </dd>
                </div>
                <div>
                  <dt className="text-sm font-medium text-gray-500 dark:text-gray-400">Work Authorization Status</dt>
                  <dd className="mt-1 text-sm text-gray-900 dark:text-gray-100">
                    {renderCopyableField(generalApplication.workAuthorization, 'Work Authorization Status')}
                  </dd>
                </div>
                <div>
                  <dt className="text-sm font-medium text-gray-500 dark:text-gray-400">Authorized to Work in US</dt>
                  <dd className="mt-1 text-sm text-gray-900 dark:text-gray-100">
                    {generalApplication.isAuthorizedToWork ? 'Yes' : 'No'}
                  </dd>
                </div>
                <div>
                  <dt className="text-sm font-medium text-gray-500 dark:text-gray-400">Requires Sponsorship</dt>
                  <dd className="mt-1 text-sm text-gray-900 dark:text-gray-100">
                    {generalApplication.requiresSponsorship ? 'Yes' : 'No'}
                  </dd>
                </div>
                {generalApplication.employmentAuthStartDate && (
                  <div>
                    <dt className="text-sm font-medium text-gray-500 dark:text-gray-400">Authorization Start Date</dt>
                    <dd className="mt-1 text-sm text-gray-900 dark:text-gray-100">
                      {renderCopyableField(generalApplication.employmentAuthStartDate, 'Authorization Start Date')}
                    </dd>
                  </div>
                )}
                {generalApplication.employmentAuthEndDate && (
                  <div>
                    <dt className="text-sm font-medium text-gray-500 dark:text-gray-400">Authorization End Date</dt>
                    <dd className="mt-1 text-sm text-gray-900 dark:text-gray-100">
                      {renderCopyableField(generalApplication.employmentAuthEndDate, 'Authorization End Date')}
                    </dd>
                  </div>
                )}
              </div>
            </div>

            {/* Education History */}
            {generalApplication.educationHistory && generalApplication.educationHistory.length > 0 && (
              <div className="bg-white dark:bg-gray-800 shadow rounded-lg overflow-hidden mt-6">
                <div className="px-4 py-5 sm:px-6 bg-gray-50 dark:bg-gray-700">
                  <h2 className="text-lg font-medium text-gray-900 dark:text-white">Education History</h2>
                </div>
                <div className="px-4 py-5 sm:p-6">
                  {generalApplication.educationHistory.map((education, index) => (
                    <div key={index} className="mb-6 pb-6 border-b border-gray-200 dark:border-gray-700 last:border-0 last:mb-0 last:pb-0">
                      <h3 className="text-md font-medium text-gray-900 dark:text-white mb-4">
                        {education.school} - {education.education}
                      </h3>
                      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        <div>
                          <dt className="text-sm font-medium text-gray-500 dark:text-gray-400">Degree/Major</dt>
                          <dd className="mt-1 text-sm text-gray-900 dark:text-gray-100">
                            {renderCopyableField(education.degreeMajor, 'Degree/Major')}
                          </dd>
                        </div>
                        {education.minor && (
                          <div>
                            <dt className="text-sm font-medium text-gray-500 dark:text-gray-400">Minor</dt>
                            <dd className="mt-1 text-sm text-gray-900 dark:text-gray-100">
                              {renderCopyableField(education.minor, 'Minor')}
                            </dd>
                          </div>
                        )}
                        <div>
                          <dt className="text-sm font-medium text-gray-500 dark:text-gray-400">GPA</dt>
                          <dd className="mt-1 text-sm text-gray-900 dark:text-gray-100">
                            {renderCopyableField(education.gpa, 'GPA')}
                          </dd>
                        </div>
                        <div>
                          <dt className="text-sm font-medium text-gray-500 dark:text-gray-400">Date Range</dt>
                          <dd className="mt-1 text-sm text-gray-900 dark:text-gray-100">
                            {renderCopyableField(`${education.startDate} - ${education.graduationDate}`, 'Education Date Range')}
                          </dd>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}

            {/* Work History */}
            {generalApplication.workHistory && generalApplication.workHistory.length > 0 && (
              <div className="bg-white dark:bg-gray-800 shadow rounded-lg overflow-hidden mt-6">
                <div className="px-4 py-5 sm:px-6 bg-gray-50 dark:bg-gray-700">
                  <h2 className="text-lg font-medium text-gray-900 dark:text-white">Work History</h2>
                </div>
                <div className="px-4 py-5 sm:p-6">
                  {generalApplication.workHistory.map((work, index) => (
                    <div key={index} className="mb-6 pb-6 border-b border-gray-200 dark:border-gray-700 last:border-0 last:mb-0 last:pb-0">
                      <h3 className="text-md font-medium text-gray-900 dark:text-white mb-4">
                        {work.jobTitle} at {work.company}
                      </h3>
                      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        <div>
                          <dt className="text-sm font-medium text-gray-500 dark:text-gray-400">Job Type</dt>
                          <dd className="mt-1 text-sm text-gray-900 dark:text-gray-100">
                            {renderCopyableField(work.jobType, 'Job Type')}
                          </dd>
                        </div>
                        <div>
                          <dt className="text-sm font-medium text-gray-500 dark:text-gray-400">Hours per Week</dt>
                          <dd className="mt-1 text-sm text-gray-900 dark:text-gray-100">
                            {renderCopyableField(work.hoursPerWeek, 'Hours per Week')}
                          </dd>
                        </div>
                        <div>
                          <dt className="text-sm font-medium text-gray-500 dark:text-gray-400">Date Range</dt>
                          <dd className="mt-1 text-sm text-gray-900 dark:text-gray-100">
                            {renderCopyableField(`${work.startDate} - ${work.endDate}`, 'Work Date Range')}
                          </dd>
                        </div>
                        <div>
                          <dt className="text-sm font-medium text-gray-500 dark:text-gray-400">Reason for Leaving</dt>
                          <dd className="mt-1 text-sm text-gray-900 dark:text-gray-100">
                            {renderCopyableField(work.reasonForLeaving, 'Reason for Leaving')}
                          </dd>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
            
            {/* Online Presence */}
            {(generalApplication.linkedinUrl || generalApplication.githubUrl || (generalApplication.customUrls && generalApplication.customUrls.length > 0)) && (
              <div className="bg-white dark:bg-gray-800 shadow rounded-lg overflow-hidden mt-6">
                <div className="px-4 py-5 sm:px-6 bg-gray-50 dark:bg-gray-700">
                  <h2 className="text-lg font-medium text-gray-900 dark:text-white">Online Presence</h2>
                </div>
                <div className="px-4 py-5 sm:p-6 grid grid-cols-1 md:grid-cols-2 gap-6">
                  {generalApplication.linkedinUrl && (
                    <div>
                      <dt className="text-sm font-medium text-gray-500 dark:text-gray-400">LinkedIn</dt>
                      <dd className="mt-1 text-sm text-gray-900 dark:text-gray-100">
                        <div className="flex items-center space-x-2">
                          <button
                            onClick={() => handleCopyToClipboard(generalApplication.linkedinUrl!, 'LinkedIn URL')}
                            className="group flex items-center space-x-2 flex-grow text-left"
                            title="Click to copy LinkedIn URL"
                          >
                            <code className="text-sm bg-gray-100 dark:bg-gray-700 px-3 py-2 rounded group-hover:bg-gray-200 dark:group-hover:bg-gray-600 transition-colors flex-grow">
                              {generalApplication.linkedinUrl}
                            </code>
                            <FaRegCopy className="h-4 w-4 text-gray-500 group-hover:text-gray-700 dark:text-gray-400 dark:group-hover:text-gray-300 transition-colors flex-shrink-0" />
                          </button>
                          <a 
                            href={generalApplication.linkedinUrl} 
                            target="_blank" 
                            rel="noopener noreferrer"
                            className="text-indigo-600 hover:text-indigo-500 dark:text-indigo-400 dark:hover:text-indigo-300 flex-shrink-0"
                          >
                            View
                          </a>
                        </div>
                      </dd>
                    </div>
                  )}
                  {generalApplication.githubUrl && (
                    <div>
                      <dt className="text-sm font-medium text-gray-500 dark:text-gray-400">GitHub</dt>
                      <dd className="mt-1 text-sm text-gray-900 dark:text-gray-100">
                        <div className="flex items-center space-x-2">
                          <button
                            onClick={() => handleCopyToClipboard(generalApplication.githubUrl!, 'GitHub URL')}
                            className="group flex items-center space-x-2 flex-grow text-left"
                            title="Click to copy GitHub URL"
                          >
                            <code className="text-sm bg-gray-100 dark:bg-gray-700 px-3 py-2 rounded group-hover:bg-gray-200 dark:group-hover:bg-gray-600 transition-colors flex-grow">
                              {generalApplication.githubUrl}
                            </code>
                            <FaRegCopy className="h-4 w-4 text-gray-500 group-hover:text-gray-700 dark:text-gray-400 dark:group-hover:text-gray-300 transition-colors flex-shrink-0" />
                          </button>
                          <a 
                            href={generalApplication.githubUrl} 
                            target="_blank" 
                            rel="noopener noreferrer"
                            className="text-indigo-600 hover:text-indigo-500 dark:text-indigo-400 dark:hover:text-indigo-300 flex-shrink-0"
                          >
                            View
                          </a>
                        </div>
                      </dd>
                    </div>
                  )}
                  {generalApplication.customUrls && generalApplication.customUrls.map((customUrl, index) => (
                    <div key={index}>
                      <dt className="text-sm font-medium text-gray-500 dark:text-gray-400">{customUrl.label}</dt>
                      <dd className="mt-1 text-sm text-gray-900 dark:text-gray-100">
                        <div className="flex items-center space-x-2">
                          <button
                            onClick={() => handleCopyToClipboard(customUrl.url, `${customUrl.label} URL`)}
                            className="group flex items-center space-x-2 flex-grow text-left"
                            title={`Click to copy ${customUrl.label} URL`}
                          >
                            <code className="text-sm bg-gray-100 dark:bg-gray-700 px-3 py-2 rounded group-hover:bg-gray-200 dark:group-hover:bg-gray-600 transition-colors flex-grow">
                              {customUrl.url}
                            </code>
                            <FaRegCopy className="h-4 w-4 text-gray-500 group-hover:text-gray-700 dark:text-gray-400 dark:group-hover:text-gray-300 transition-colors flex-shrink-0" />
                          </button>
                          <a 
                            href={customUrl.url} 
                            target="_blank" 
                            rel="noopener noreferrer"
                            className="text-indigo-600 hover:text-indigo-500 dark:text-indigo-400 dark:hover:text-indigo-300 flex-shrink-0"
                          >
                            View
                          </a>
                        </div>
                      </dd>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </>
        )}

        {/* Agent Notes Section - Always visible for agents and admins, even when there's no general application */}
        {userProfile?.userType !== 'customer' && userId && (
          <div className="bg-white dark:bg-gray-800 shadow rounded-lg overflow-hidden mt-6">
            <div className="px-4 py-5 sm:px-6 bg-gray-50 dark:bg-gray-700">
              <h2 className="text-lg font-medium text-gray-900 dark:text-white">Agent Notes</h2>
              <p className="mt-1 text-sm text-gray-500 dark:text-gray-400">
                Add notes about this user that might be helpful for other agents
              </p>
            </div>
            <div className="px-4 py-5 sm:p-6">
              <UserNotes userId={userId} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
} 
