import { useState, useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';
import { useApplications } from '../hooks/useApplications';
import { useApplicationActions } from '../hooks/useApplicationActions';
import { Application } from '../types/application';
import { Comments } from '../components/comments/Comments';
import { JobProfileDetails } from '../components/applications/details/JobProfileDetails';
import { ApplicationDetails } from '../components/applications/details/ApplicationDetails';
import { ApplicationHeader } from '../components/applications/details/ApplicationHeader';
import { sections } from '../components/applications/details/SectionNav';
import { useScrollSpy } from '../hooks/useScrollSpy';
import toast from 'react-hot-toast';
import { CancellationReasonModal } from '../components/applications/details/CancellationReasonModal';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { storage } from '../lib/firebase';
import { Button } from '../components/ui/Button';
import { writeBatch, doc, increment, query, collection, where, getDocs, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../lib/firebase';

export function ApplicationDetailsPage() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { user, userProfile } = useAuth();
  const { fetchApplicationById, updateApplication } = useApplications();
  const { updateApplicationStatus } = useApplicationActions();
  const [application, setApplication] = useState<Application | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [updating, setUpdating] = useState(false);
  const [showCancellationModal, setShowCancellationModal] = useState(false);

  const activeSection = useScrollSpy(sections.map(s => s.id));

  useEffect(() => {
    if (user && id) {
      loadApplication();
    }
  }, [id, user]);

  const loadApplication = async () => {
    if (!id || !user) return;
    
    try {
      setLoading(true);
      setError(null);
      const app = await fetchApplicationById(id);
      setApplication(app);
    } catch (error: any) {
      console.error('Error loading application:', error);
      setError(error.message || 'Failed to load application details');
      if (error.message === 'Application not found') {
        toast.error('Application not found');
        navigate('/applications');
      } else {
        toast.error(error.message || 'Failed to load application details');
      }
    } finally {
      setLoading(false);
    }
  };

  const handleBack = () => {
    navigate(-1);
  };

  const verifyAgentAssignment = async () => {
    if (!user || !application) return false;
    
    const appRef = doc(db, 'applications', application.id);
    const appSnap = await getDoc(appRef);
    const currentApp = appSnap.data();

    if (!currentApp || currentApp.agentId !== user.uid) {
      toast.error('You are no longer assigned to this application');
      navigate('/agent/applications');
      return false;
    }
    return true;
  };

  const handleCancel = async (reason: string, files?: File[]) => {
    if (!user || !application) return;

    try {
      setUpdating(true);

      // Verify agent is still assigned if the current user is an agent
      if (userProfile?.userType === 'agent' && !(await verifyAgentAssignment())) {
        return;
      }

      const now = new Date().toISOString();

      // Prepare application update data
      const updateData: any = { 
        status: 'cancelled',
        notes: reason,
        agentId: null,
        lastActionBy: user.uid,
        lastActionAt: now,
        updatedAt: now,
        paymentStatus: null
      };

      // Handle file uploads if files are provided
      if (files && files.length > 0) {
        const uploadPromises = files.map(async (file) => {
          const fileRef = ref(storage, `applications/${application.id}/cancellation/${Date.now()}_${file.name}`);
          const snapshot = await uploadBytes(fileRef, file);
          const downloadURL = await getDownloadURL(snapshot.ref);
          
          return {
            id: Date.now().toString(),
            name: file.name,
            url: downloadURL,
            type: file.type,
            size: file.size
          };
        });

        const uploadedFiles = await Promise.all(uploadPromises);
        updateData.cancellationAttachments = uploadedFiles;
      }

      // Update application - do this as a separate operation from user credits
      const applicationRef = doc(db, 'applications', application.id);
      await updateDoc(applicationRef, updateData);
      
      try {
        // Only admins or the user themselves can refund credits
        if (userProfile?.userType === 'admin' || user.uid === application.userId) {
          // Refund credits to user when cancelling
          const userRef = doc(db, 'users', application.userId);
          await updateDoc(userRef, {
            credits: increment(3), // Refund 3 credits
            updatedAt: now
          });
        }
        // If the agent is cancelling and doesn't have permission to update the user,
        // we still want to mark the application as cancelled
      } catch (creditError) {
        console.error('Error refunding credits:', creditError);
        // Don't throw an error here - the application has already been cancelled
        toast.error('Application cancelled, but credits could not be refunded. An admin will handle this.');
      }
      
      toast.success('Application cancelled successfully');
      await loadApplication();
    } catch (error) {
      console.error('Error cancelling application:', error);
      toast.error('Failed to cancel application: ' + ((error as Error)?.message || ''));
    } finally {
      setUpdating(false);
    }
  };

  // Check if the agent already has active in-progress applications
  const hasActiveInProgressApplications = async (): Promise<boolean> => {
    if (!user || userProfile?.userType === 'admin') return false; // Admins can pick up multiple applications
    
    try {
      // Query for applications assigned to the current agent
      const applicationsRef = collection(db, 'applications');
      const q = query(
        applicationsRef,
        where('agentId', '==', user.uid),
        where('status', '==', 'in_progress')
      );
      
      const querySnapshot = await getDocs(q);
      
      // Return true if the agent has at least one in-progress application
      return !querySnapshot.empty;
    } catch (error) {
      console.error('Error checking agent applications:', error);
      return false; // If there's an error, allow them to pick up (better UX than blocking when uncertain)
    }
  };

  const handlePickUp = async () => {
    if (!user || !application || !userProfile) return;

    try {
      setUpdating(true);
      
      // Check if agent already has in-progress applications
      if (userProfile.userType === 'agent' && await hasActiveInProgressApplications()) {
        toast.error('You already have an application in progress. Please complete it before picking up a new one.');
        setUpdating(false);
        return;
      }
      
      // Get fresh application data from database to check current state
      const appRef = doc(db, 'applications', application.id);
      const appSnapshot = await getDoc(appRef);
      const currentAppData = appSnapshot.data();
      
      // Check if application is still available based on fresh data
      // The agentId field might not exist at all, so we check if it's explicitly undefined or null
      if (!currentAppData || 
          currentAppData.status !== 'submitted' || 
          ('agentId' in currentAppData && currentAppData.agentId !== null && currentAppData.agentId !== undefined)) {
        toast.error('This application has been assigned to another agent. Please pick up another one.');
        navigate('/agent/applications');
        return;
      }
      
      // Update application status and assign to agent
      // Create a complete object with all required fields
      const updateData = {
        status: 'in_progress',
        agentId: user.uid,
        agentName: userProfile.firstName + ' ' + userProfile.lastName,
        updatedAt: new Date().toISOString(),
        lastActionBy: user.uid,
        lastActionAt: new Date().toISOString(),
        lastInProgressAt: new Date().toISOString()
      };
      
      await updateDoc(appRef, updateData);
      
      toast.success('Application assigned to you');
      await loadApplication();
    } catch (error) {
      console.error('Error picking up application:', error);
      // Handle specific error for when another agent picks up the application
      // This can happen if there's a race condition between our check and update
      if ((error as any).code === 'permission-denied') {
        toast.error('This application has been assigned to another agent. Please pick up another one.');
      } else {
        toast.error('Failed to pick up application. ' + ((error as Error)?.message || ''));
      }
      navigate('/agent/applications');
    } finally {
      setUpdating(false);
    }
  };

  const handleUpdateStatus = async (newStatus: 'completed' | 'pending' | 'in_progress', notes?: string, files?: File[]) => {
    if (!user || !application) return;

    try {
      setUpdating(true);
      
      // Verify agent is still assigned for any status change
      if ((userProfile?.userType === 'agent' || newStatus === 'in_progress') && !(await verifyAgentAssignment())) {
        return;
      }

      // If we're setting to pending and have files, handle it in one operation
      if (newStatus === 'pending' && files && files.length > 0) {
        await updateApplicationStatus(application.id, newStatus, notes, files);
      } else {
        // Otherwise, perform normal update
        const updateData: any = { 
          status: newStatus,
          updatedAt: new Date().toISOString(),
          lastActionBy: user.uid,
          lastActionAt: new Date().toISOString()
        };

        // Only include notes if they are provided
        if (notes) {
          updateData.notes = notes;
        }

        // If completing the application
        if (newStatus === 'completed') {
          updateData.completedAt = new Date().toISOString();
          updateData.fulfillmentTime = Date.now() - new Date(application.createdAt).getTime();
          updateData.paymentStatus = 'pending';
        }

        // Use skipSuccessToast to avoid duplicate toasts
        const skipSuccessToast = true;
        await updateApplication(application.id, updateData, skipSuccessToast);
        
        // If status is pending and we have files, update with files
        // But skip the notification since we already sent one in updateApplication
        if (newStatus === 'pending' && files) {
          await updateApplicationStatus(application.id, newStatus, notes, files, true);
        }
      }
      
      toast.success('Application status updated successfully');
      await loadApplication();
    } catch (error) {
      console.error('Error updating application status:', error);
      toast.error('Failed to update application status');
    } finally {
      setUpdating(false);
    }
  };

  const handleUnassign = async () => {
    if (!user || !application) return;

    try {
      setUpdating(true);
      const batch = writeBatch(db);
      const now = new Date().toISOString();

      // Update application
      const applicationRef = doc(db, 'applications', application.id);
      batch.update(applicationRef, {
        status: 'submitted',
        agentId: null,
        agentName: null,
        updatedAt: now,
        lastActionBy: user.uid,
        lastActionAt: now,
        paymentStatus: null
      });

      await batch.commit();
      
      toast.success('Agent unassigned successfully');
      await loadApplication();
    } catch (error) {
      console.error('Error unassigning agent:', error);
      toast.error('Failed to unassign agent');
    } finally {
      setUpdating(false);
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center py-12">
        <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-indigo-600 dark:border-indigo-400"></div>
      </div>
    );
  }

  if (error || !application) {
    return (
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="bg-white dark:bg-gray-800 shadow rounded-lg p-6">
          <div className="text-center">
            <h3 className="mt-2 text-lg font-medium text-gray-900 dark:text-white">Error Loading Application</h3>
            <p className="mt-1 text-gray-500 dark:text-gray-400">{error || 'Application not found'}</p>
            <button
              onClick={handleBack}
              className="mt-4 inline-flex items-center px-4 py-2 border border-gray-300 dark:border-gray-600 rounded-md shadow-sm text-sm font-medium text-gray-700 dark:text-gray-200 bg-white dark:bg-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
            >
              Back to Previous Page
            </button>
          </div>
        </div>
      </div>
    );
  }

  const isCustomer = user?.uid === application.userId;
  const isAssignedAgent = userProfile?.userType === 'agent' && application.agentId === user?.uid;
  const isAdmin = userProfile?.userType === 'admin';

  return (
    <div className="min-h-screen bg-white dark:bg-gray-900">
      <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <ApplicationHeader
          application={application}
          isCustomer={isCustomer}
          isAssignedAgent={isAssignedAgent}
          updating={updating}
          userType={userProfile?.userType}
          onBack={handleBack}
          onCancel={handleCancel}
          onPickUp={handlePickUp}
          onUpdateStatus={handleUpdateStatus}
          onUnassign={handleUnassign}
        />

        <div className="bg-white dark:bg-gray-800 rounded-lg overflow-hidden">
          <div className="border-b border-gray-200 dark:border-gray-700 overflow-x-auto overflow-y-hidden">
            <nav className="-mb-px flex space-x-4 md:space-x-8 px-4 md:px-6" aria-label="Tabs">
              {sections.map((section) => (
                <button
                  key={section.id}
                  onClick={() => {
                    const element = document.getElementById(section.id);
                    if (element) {
                      element.scrollIntoView({ behavior: 'smooth' });
                    }
                  }}
                  className={`
                    py-3 md:py-4 px-1 border-b-2 font-medium text-sm whitespace-nowrap flex-shrink-0
                    ${activeSection === section.id
                      ? 'border-indigo-500 text-indigo-600 dark:text-indigo-400 dark:border-indigo-400'
                      : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 dark:text-gray-400 dark:hover:text-gray-300 dark:hover:border-gray-600'
                    }
                  `}
                >
                  {section.name}
                </button>
              ))}
            </nav>
          </div>
        </div>

        <div className="mt-6 md:mt-8 space-y-6 md:space-y-8">
          {/* Application Details Section */}
          <section id="details" className="bg-white dark:bg-gray-800 shadow rounded-lg p-4 md:p-6">
            <h2 className="text-lg md:text-xl font-semibold text-gray-900 dark:text-white mb-4 md:mb-6">
              Application Details
            </h2>
            <ApplicationDetails application={application} />
          </section>

          {/* Job Profile Section */}
          <section id="profile" className="bg-white dark:bg-gray-800 shadow rounded-lg p-4 md:p-6">
            <div className="flex justify-between items-center mb-4 md:mb-6">
              <h2 className="text-lg md:text-xl font-semibold text-gray-900 dark:text-white">
                User Info
              </h2>
              {(userProfile?.userType === 'agent' || userProfile?.userType === 'admin') && (
                <Link 
                  to={`/user-information/${application.userId}`}
                  className="text-base font-medium text-indigo-600 hover:text-indigo-500 dark:text-indigo-400 dark:hover:text-indigo-300 flex items-center"
                >
                  View all Info
                </Link>
              )}
            </div>
            <JobProfileDetails application={application} />
          </section>

          {/* Comments Section - Always visible but with restricted posting */}
          <section id="proof-and-notes" className="bg-white dark:bg-gray-800 shadow rounded-lg p-4 md:p-6">
            <h2 className="text-lg md:text-xl font-semibold text-gray-900 dark:text-white mb-4 md:mb-6">
              Application Proof & Notes
            </h2>
            <Comments 
              applicationId={application.id}
            />
          </section>

          {/* Cancellation Section - Updated visibility conditions */}
          {((isAdmin || isAssignedAgent) && application.status !== 'cancelled' && application.status !== 'completed') && 
           (isAdmin || application.status !== 'submitted') && (
            <section className="bg-red-50 dark:bg-red-900/20 shadow rounded-lg p-4 md:p-6 mt-6 md:mt-8">
              <div className="border-l-4 border-red-500 dark:border-red-400 pl-4">
                <p className="text-sm md:text-base text-red-600 dark:text-red-300 mb-4 md:mb-6">
                  Only cancel this application if you are absolutely sure. This action cannot be undone and will require proper documentation.
                </p>
                <Button
                  variant="primary"
                  onClick={() => setShowCancellationModal(true)}
                  disabled={updating}
                  className="w-full sm:w-auto bg-[#800000] hover:bg-[#660000] text-white"
                >
                  Cancel This Application
                </Button>
              </div>
            </section>
          )}
        </div>

        {/* Cancellation Modal */}
        {showCancellationModal && (
          <CancellationReasonModal
            isOpen={showCancellationModal}
            onClose={() => setShowCancellationModal(false)}
            onConfirm={handleCancel}
            isSubmitting={updating}
          />
        )}
      </div>
    </div>
  );
}