import { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { db } from '../lib/firebase';
import { collection, query, getDocs, orderBy, where } from 'firebase/firestore';
import { Application } from '../types/application';
import { format } from 'date-fns';
import { Loader2, ExternalLink, Search, X } from 'lucide-react';
import { Link } from 'react-router-dom';
import toast from 'react-hot-toast';

type TabType = 'all' | 'submitted' | 'in_progress' | 'pending' | 'completed' | 'cancelled';

export function AdminApplications() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [applications, setApplications] = useState<Application[]>([]);
  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState<TabType>(searchParams.get('status') as TabType || 'all');
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    loadApplications();
  }, []);

  const loadApplications = async () => {
    try {
      setLoading(true);
      const applicationsRef = collection(db, 'applications');
      const q = query(applicationsRef, orderBy('updatedAt', 'desc'));
      const snapshot = await getDocs(q);

      // Get unique user IDs (both customers and agents)
      const userIds = new Set<string>();
      snapshot.docs.forEach(doc => {
        const data = doc.data();
        if (data.userId) userIds.add(data.userId);
        if (data.agentId) userIds.add(data.agentId);
      });

      // Fetch all user data in parallel
      const usersRef = collection(db, 'users');
      const userSnapshots = await Promise.all(
        Array.from(userIds).map(uid => 
          getDocs(query(usersRef, where('__name__', '==', uid)))
        )
      );

      // Create a map of user data
      const userMap = new Map();
      userSnapshots.forEach(userSnap => {
        userSnap.docs.forEach(doc => {
          const userData = doc.data();
          userMap.set(doc.id, {
            name: `${userData.firstName || ''} ${userData.lastName || ''}`.trim() || 'Unknown',
            email: userData.email || ''
          });
        });
      });

      // Map applications with user data
      const apps = snapshot.docs.map(doc => {
        const data = doc.data() as Application;
        const customer = userMap.get(data.userId);
        const agent = userMap.get(data.agentId);
        
        return {
          ...data,
          id: doc.id,
          customerName: customer ? customer.name : 'Unknown',
          customerEmail: customer ? customer.email : '',
          agentName: agent ? agent.name : 'Unassigned',
          agentEmail: agent ? agent.email : ''
        };
      });

      setApplications(apps);
    } catch (error) {
      console.error('Error loading applications:', error);
      toast.error('Failed to load applications');
    } finally {
      setLoading(false);
    }
  };

  const filteredApplications = applications.filter(app => {
    const matchesSearch = searchTerm === '' || 
      (app.company?.toLowerCase().includes(searchTerm.toLowerCase())) ||
      (app.position?.toLowerCase().includes(searchTerm.toLowerCase())) ||
      app.id.toLowerCase().includes(searchTerm.toLowerCase()) ||
      (app.customerName?.toLowerCase().includes(searchTerm.toLowerCase())) ||
      (app.agentName?.toLowerCase().includes(searchTerm.toLowerCase()));

    switch (activeTab) {
      case 'submitted':
        return matchesSearch && app.status === 'submitted';
      case 'in_progress':
        return matchesSearch && app.status === 'in_progress';
      case 'pending':
        return matchesSearch && app.status === 'pending';
      case 'completed':
        return matchesSearch && app.status === 'completed';
      case 'cancelled':
        return matchesSearch && app.status === 'cancelled';
      default:
        return matchesSearch;
    }
  });

  const tabs: { id: TabType; name: string; count: number }[] = [
    { 
      id: 'all', 
      name: 'All', 
      count: applications.length
    },
    { 
      id: 'submitted', 
      name: 'Submitted', 
      count: applications.filter(app => app.status === 'submitted').length
    },
    { 
      id: 'in_progress', 
      name: 'In Progress', 
      count: applications.filter(app => app.status === 'in_progress').length
    },
    { 
      id: 'pending', 
      name: 'Pending', 
      count: applications.filter(app => app.status === 'pending').length
    },
    { 
      id: 'completed', 
      name: 'Completed', 
      count: applications.filter(app => app.status === 'completed').length
    },
    { 
      id: 'cancelled', 
      name: 'Cancelled', 
      count: applications.filter(app => app.status === 'cancelled').length
    }
  ];

  if (loading) {
    return (
      <div className="flex justify-center py-12">
        <Loader2 className="h-8 w-8 animate-spin text-indigo-600 dark:text-indigo-400" />
      </div>
    );
  }

  const getStatusDisplay = (status: string) => {
    const statusConfig = {
      completed: 'bg-green-100 text-green-800 dark:bg-green-900/50 dark:text-green-300',
      in_progress: 'bg-yellow-100 text-yellow-800 dark:bg-yellow-900/50 dark:text-yellow-300',
      pending: 'bg-orange-100 text-orange-800 dark:bg-orange-900/50 dark:text-orange-300',
      cancelled: 'bg-red-100 text-red-800 dark:bg-red-900/50 dark:text-red-300',
      submitted: 'bg-blue-100 text-blue-800 dark:bg-blue-900/50 dark:text-blue-300'
    };
    return statusConfig[status as keyof typeof statusConfig] || 'bg-gray-100 text-gray-800 dark:bg-gray-900/30 dark:text-gray-300';
  };

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <div className="mb-8">
        <h1 className="text-3xl font-bold text-gray-900 dark:text-white">All Applications</h1>
        <p className="mt-2 text-gray-600 dark:text-gray-400">
          Monitor and manage all applications in the system
        </p>
      </div>

      {/* Search Bar */}
      <div className="mb-6">
        <div className="relative">
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <Search className="h-5 w-5 text-gray-400" />
          </div>
          <input
            type="text"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder="Search by company, position, application ID, customer, or agent..."
            className="block w-full pl-10 pr-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md leading-5 bg-white dark:bg-gray-700 text-gray-900 dark:text-white placeholder-gray-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
          {searchTerm && (
            <button
              onClick={() => setSearchTerm('')}
              className="absolute inset-y-0 right-0 pr-3 flex items-center"
            >
              <X className="h-5 w-5 text-gray-400 hover:text-gray-500" />
            </button>
          )}
        </div>
      </div>

      {/* Tabs */}
      <div className="border-b border-gray-200 dark:border-gray-700 mb-6">
        <nav className="-mb-px flex space-x-8">
          {tabs.map((tab) => (
            <button
              key={tab.id}
              onClick={() => {
                setActiveTab(tab.id);
                navigate(`/admin/applications?status=${tab.id}`);
              }}
              className={`
                whitespace-nowrap pb-4 px-1 border-b-2 font-medium text-sm
                ${activeTab === tab.id
                  ? 'border-indigo-500 text-indigo-600 dark:text-indigo-400'
                  : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 dark:text-gray-400 dark:hover:text-gray-300'
                }
              `}
            >
              {tab.name} ({tab.count})
            </button>
          ))}
        </nav>
      </div>

      {/* Applications Table */}
      <div className="bg-white dark:bg-gray-800 shadow overflow-hidden sm:rounded-lg">
        <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
          <thead className="bg-gray-100 dark:bg-gray-700/50">
            <tr>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-700 dark:text-gray-100 uppercase tracking-wider">
                Application Details
              </th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-700 dark:text-gray-100 uppercase tracking-wider">
                Customer
              </th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-700 dark:text-gray-100 uppercase tracking-wider">
                Agent
              </th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-700 dark:text-gray-100 uppercase tracking-wider">
                Status
              </th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-700 dark:text-gray-100 uppercase tracking-wider">
                Dates
              </th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-700 dark:text-gray-100 uppercase tracking-wider">
                Actions
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 dark:divide-gray-700">
            {filteredApplications.length === 0 ? (
              <tr>
                <td colSpan={6} className="px-6 py-4 text-center text-gray-500 dark:text-gray-400">
                  No applications found
                </td>
              </tr>
            ) : (
              filteredApplications.map((application) => (
                <tr key={application.id}>
                  <td className="px-6 py-4">
                    <Link
                      to={`/applications/${application.id}`}
                      className="flex flex-col hover:bg-gray-50 dark:hover:bg-gray-700/50 -mx-6 -my-4 px-6 py-4 transition-colors duration-200"
                    >
                      <span className="text-sm font-medium text-gray-900 dark:text-white">
                        {application.company || 'Unknown Company'}
                      </span>
                      <span className="text-sm text-gray-500 dark:text-gray-400">
                        {application.position || 'Unknown Position'}
                      </span>
                      <span className="text-xs text-gray-400 dark:text-gray-500 font-mono mt-1">
                        {application.id}
                      </span>
                    </Link>
                  </td>
                  <td className="px-6 py-4">
                    <span className="text-sm text-gray-900 dark:text-white">
                      {application.customerName || 'Unknown'}
                    </span>
                  </td>
                  <td className="px-6 py-4">
                    <span className="text-sm text-gray-900 dark:text-white">
                      {application.agentName || 'Unassigned'}
                    </span>
                  </td>
                  <td className="px-6 py-4">
                    <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium
                      ${getStatusDisplay(application.status)}`}
                    >
                      {application.status.charAt(0).toUpperCase() + application.status.slice(1)}
                    </span>
                  </td>
                  <td className="px-6 py-4">
                    <div className="flex flex-col text-sm text-gray-500 dark:text-gray-400">
                      <span>Created: {format(new Date(application.createdAt), 'MMM d, yyyy')}</span>
                      <span>Updated: {format(new Date(application.updatedAt), 'MMM d, yyyy')}</span>
                      {application.completedAt && (
                        <span>Completed: {format(new Date(application.completedAt), 'MMM d, yyyy')}</span>
                      )}
                    </div>
                  </td>
                  <td className="px-6 py-4">
                    <Link
                      to={`/applications/${application.id}`}
                      className="text-indigo-600 hover:text-indigo-900 dark:text-indigo-400 dark:hover:text-indigo-300 inline-flex items-center"
                    >
                      View Details
                      <ExternalLink className="ml-1 h-4 w-4" />
                    </Link>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}