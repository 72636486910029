import { Link } from 'react-router-dom';
import { Clock, User, ArrowRight } from 'lucide-react';
import { useState } from 'react';
import { sendContactEmail } from '../services/email';
import toast from 'react-hot-toast';

interface BlogPost {
  id: string;
  title: string;
  description: string;
  author: string;
  date: string;
  readTime: string;
  slug: string;
  category: 'Job Search' | 'Interview Tips' | 'Career Advice';
}

const BLOG_POSTS: BlogPost[] = [
  {
    id: '1',
    title: 'Top 10 Tips for Efficient Job Applications',
    description: 'Learn how to streamline your job application process and increase your chances of getting noticed by recruiters.',
    author: 'Sarah Johnson',
    date: '2024-01-15',
    readTime: '5 min read',
    slug: 'job-application-tips',
    category: 'Job Search'
  },
  {
    id: '2',
    title: 'Mastering Your Resume: A Complete Guide',
    description: 'Everything you need to know about creating a compelling resume that stands out in today\'s competitive job market.',
    author: 'Michael Chen',
    date: '2024-01-10',
    readTime: '8 min read',
    slug: 'resume-writing-guide',
    category: 'Career Advice'
  },
  {
    id: '3',
    title: 'Common Interview Questions and How to Answer Them',
    description: 'Prepare for your next job interview with our comprehensive guide to the most common interview questions.',
    author: 'Emily Rodriguez',
    date: '2024-01-05',
    readTime: '6 min read',
    slug: 'interview-preparation',
    category: 'Interview Tips'
  }
];

export function Blog() {
  const [email, setEmail] = useState('');
  const [submitting, setSubmitting] = useState(false);

  const handleSubscribe = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!email) {
      toast.error('Please enter your email address');
      return;
    }
    
    setSubmitting(true);
    
    try {
      // Execute reCAPTCHA with subscribe action
      const token = await window.grecaptcha.execute(
        import.meta.env.VITE_RECAPTCHA_SITE_KEY,
        { action: 'subscribe' }
      );

      // Send email as if it came from contact form
      await sendContactEmail({
        name: 'Blog Subscriber',
        email: email,
        subject: 'Blog Subscription',
        message: `This user clicked subscribe on the /blog page. Email: ${email}`,
        recaptchaToken: token
      });
      
      setEmail('');
      toast.success('Thanks for subscribing to our newsletter!');
    } catch (error: any) {
      console.error('Error subscribing:', error);
      toast.error('Failed to subscribe. Please try again later.');
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div className="bg-gray-50 dark:bg-gray-900 min-h-screen">
      <div className="bg-white dark:bg-gray-800 py-16">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center">
            <h1 className="text-4xl font-extrabold text-gray-900 dark:text-white sm:text-5xl">
              BlitzApply Blog
            </h1>
            <p className="mt-4 text-xl text-gray-600 dark:text-gray-300">
              Expert insights on job applications, career growth, and professional development
            </p>
          </div>
        </div>
      </div>

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="flex justify-center space-x-4">
          {['All Posts', 'Job Search', 'Interview Tips', 'Career Advice'].map((category) => (
            <button
              key={category}
              className="px-4 py-2 rounded-full text-sm font-medium text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700"
            >
              {category}
            </button>
          ))}
        </div>
      </div>

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-3">
          {BLOG_POSTS.map((post) => (
            <article
              key={post.id}
              className="bg-white dark:bg-gray-800 rounded-lg shadow-md overflow-hidden hover:shadow-lg transition-shadow duration-300"
            >
              <div className="p-6">
                <div className="flex items-center text-sm text-gray-500 dark:text-gray-400 mb-2">
                  <span className="px-3 py-1 rounded-full bg-indigo-100 dark:bg-indigo-900/30 text-indigo-800 dark:text-indigo-300 text-xs font-medium">
                    {post.category}
                  </span>
                </div>
                
                <Link 
                  to={`/blog/${post.slug}`}
                  className="block mt-3"
                >
                  <h2 className="text-xl font-semibold text-gray-900 dark:text-white hover:text-indigo-600 dark:hover:text-indigo-400">
                    {post.title}
                  </h2>
                  <p className="mt-3 text-gray-600 dark:text-gray-300">
                    {post.description}
                  </p>
                </Link>

                <div className="mt-6 flex items-center">
                  <div className="flex-shrink-0">
                    <User className="h-5 w-5 text-gray-400" />
                  </div>
                  <div className="ml-3">
                    <p className="text-sm font-medium text-gray-900 dark:text-white">
                      {post.author}
                    </p>
                    <div className="flex space-x-4 text-sm text-gray-500 dark:text-gray-400">
                      <span className="flex items-center">
                        <Clock className="h-4 w-4 mr-1" />
                        {post.readTime}
                      </span>
                      <time dateTime={post.date}>
                        {new Date(post.date).toLocaleDateString('en-US', {
                          month: 'long',
                          day: 'numeric',
                          year: 'numeric'
                        })}
                      </time>
                    </div>
                  </div>
                </div>
              </div>
            </article>
          ))}
        </div>

        <div className="mt-16 bg-indigo-50 dark:bg-indigo-900/30 rounded-2xl p-8">
          <div className="text-center">
            <h3 className="text-2xl font-bold text-gray-900 dark:text-white">
              Get Job Search Tips in Your Inbox
            </h3>
            <p className="mt-2 text-gray-600 dark:text-gray-300">
              Subscribe to our newsletter for weekly insights on job searching and career development.
            </p>
            <form onSubmit={handleSubscribe} className="mt-6 flex max-w-md mx-auto gap-x-4">
              <input
                type="email"
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="min-w-0 flex-auto rounded-md border-0 bg-white dark:bg-gray-800 px-3.5 py-2 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-gray-700 focus:ring-2 focus:ring-inset focus:ring-indigo-600 dark:focus:ring-indigo-500"
                placeholder="Enter your email"
                disabled={submitting}
              />
              <button
                type="submit"
                disabled={submitting}
                className="flex-none rounded-md bg-indigo-600 dark:bg-indigo-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 dark:hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 dark:focus-visible:outline-indigo-500 disabled:opacity-50"
              >
                {submitting ? 'Subscribing...' : 'Subscribe'}
                {!submitting && <ArrowRight className="ml-2 h-4 w-4" />}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
} 