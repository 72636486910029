import { useState, useEffect } from 'react';
import { useAuth } from '../hooks/useAuth';
import { collection, query, where, orderBy, getDocs } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { Application } from '../types/application';
import { Link } from 'react-router-dom';
import { Alert } from '../components/ui/Alert';
import { Loader2, PlusCircle } from 'lucide-react';
import { ApplicationList } from '../components/applications/ApplicationList';
import { EmailVerificationBanner } from '../components/EmailVerificationBanner';

export function Applications() {
  const { user } = useAuth();
  const [applications, setApplications] = useState<Application[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!user) return;
    loadApplications();
  }, [user]);

  const loadApplications = async () => {
    if (!user) return;
    
    try {
      setLoading(true);
      
      const applicationsRef = collection(db, 'applications');
      const userApplicationsQuery = query(
        applicationsRef,
        where('userId', '==', user.uid),
        orderBy('updatedAt', 'desc')
      );
      
      const querySnapshot = await getDocs(userApplicationsQuery);
      
      const applicationsData = querySnapshot.docs
        .map(doc => ({
          id: doc.id,
          ...doc.data(),
        })) as Application[];
      
      setApplications(applicationsData);
    } catch (error) {
      console.error('Error loading applications:', error);
    } finally {
      setLoading(false);
    }
  };
  
  // Group applications by their status
  const getGroupedApplications = () => {
    const inProgress = applications.filter(app => app.status === 'in_progress');
    const pending = applications.filter(app => app.status === 'pending');
    const submitted = applications.filter(app => app.status === 'submitted');
    
    return { inProgress, pending, submitted };
  };

  const { inProgress, pending, submitted } = getGroupedApplications();
  
  const hasEmailVerificationBanner = user && !user.emailVerified;
  const hasPendingApplications = pending.length > 0;

  if (loading) {
    return (
      <div className="flex justify-center py-12">
        <Loader2 className="h-8 w-8 animate-spin text-indigo-600 dark:text-indigo-400" />
      </div>
    );
  }

  return (
    <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      {hasEmailVerificationBanner && (
        <EmailVerificationBanner />
      )}
      
      <div className="flex justify-between items-center mb-6">
        <div>
          <h1 className="text-3xl font-bold text-gray-900 dark:text-white">My Applications in Progress</h1>
          <p className="mt-2 text-gray-600 dark:text-gray-400">
            Manage your job applications. Click on an Application to view Application Details.
          </p>
          <Link 
            to="/completions" 
            className="mt-2 inline-block text-sm font-medium text-indigo-600 hover:text-indigo-500 dark:text-indigo-400 dark:hover:text-indigo-300"
          >
            View all my applications →
          </Link>
        </div>
        {(inProgress.length > 0 || pending.length > 0 || submitted.length > 0) && (
          <Link
            to="/submit-application"
            className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 dark:bg-indigo-500 dark:hover:bg-indigo-600 dark:focus:ring-offset-gray-900"
          >
            <PlusCircle className="h-5 w-5 mr-2" />
            New Application
          </Link>
        )}
      </div>

      {hasPendingApplications && (
        <Alert 
          type="info" 
          title="Action needed" 
          message="You have pending applications that require your attention." 
          className="mb-6"
        />
      )}

      <div className="space-y-6">
        {inProgress.length > 0 && (
          <div>
            <h2 className="text-xl font-semibold text-gray-900 dark:text-white mb-2">
              In Progress
            </h2>
            <ApplicationList applications={inProgress} onRefresh={loadApplications} />
          </div>
        )}

        {pending.length > 0 && (
          <div>
            <h2 className="text-xl font-semibold text-gray-900 dark:text-white mb-2">
              Pending
            </h2>
            <ApplicationList applications={pending} onRefresh={loadApplications} />
          </div>
        )}

        {submitted.length > 0 && (
          <div>
            <ApplicationList applications={submitted} onRefresh={loadApplications} />
          </div>
        )}

        {inProgress.length === 0 && pending.length === 0 && submitted.length === 0 && (
          <div className="text-center py-8">
            <h3 className="text-lg font-medium text-gray-900 dark:text-white">No applications found</h3>
            <p className="mt-1 text-gray-500 dark:text-gray-400">Start by creating a new application</p>
            <div className="mt-6">
              <Link
                to="/submit-application"
                className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 dark:bg-indigo-500 dark:hover:bg-indigo-600 dark:focus:ring-offset-gray-900"
              >
                <PlusCircle className="h-5 w-5 mr-2" />
                Create Application
              </Link>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}