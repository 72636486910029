import { PhoneVerificationBanner } from '../components/PhoneVerificationBanner';

export function PhoneVerification() {
  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <div className="max-w-3xl mx-auto">
        <h1 className="text-3xl font-bold text-gray-900 dark:text-white mb-8">Phone Verification Required</h1>
        <PhoneVerificationBanner />
      </div>
    </div>
  );
} 