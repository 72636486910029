import { Link } from 'react-router-dom';
import { DarkModeToggle } from './DarkModeToggle';

interface AuthLinksProps {
  isMobile?: boolean;
}

export function AuthLinks({ isMobile = false }: AuthLinksProps) {
  if (isMobile) {
    return (
      <div className="flex flex-col w-full py-2">
        <Link
          to="/blog"
          className="block w-full px-4 py-3 text-base font-medium text-gray-100 hover:bg-gray-700/50 dark:hover:bg-gray-600/50 border-b border-gray-700/50 dark:border-gray-600/50"
        >
          Blog
        </Link>
        <Link
          to="/register"
          className="block w-full px-4 py-3 text-base font-medium text-gray-100 hover:bg-gray-700/50 dark:hover:bg-gray-600/50 border-b border-gray-700/50 dark:border-gray-600/50"
        >
          Register
        </Link>
        <Link
          to="/login"
          className="block w-full px-4 py-3 text-base font-medium text-gray-100 hover:bg-gray-700/50 dark:hover:bg-gray-600/50"
        >
          Sign In
        </Link>
      </div>
    );
  }

  return (
    <div className="flex items-center">
      {/* Public Navigation Links */}
      <div className="flex items-center space-x-4 mr-4">
        <Link
          to="/blog"
          className="text-white hover:text-white px-3 py-2 rounded-md text-sm font-medium hover:bg-gray-700 dark:hover:bg-gray-600 transition-colors duration-200"
        >
          Blog
        </Link>
        <Link
          to="/pricing"
          className="text-white hover:text-white px-3 py-2 rounded-md text-sm font-medium hover:bg-gray-700 dark:hover:bg-gray-600 transition-colors duration-200"
        >
          Pricing
        </Link>
        <Link
          to="/careers"
          className="text-white hover:text-white px-3 py-2 rounded-md text-sm font-medium hover:bg-gray-700 dark:hover:bg-gray-600 transition-colors duration-200"
        >
          Careers
        </Link>
        <Link
          to="/about"
          className="text-white hover:text-white px-3 py-2 rounded-md text-sm font-medium hover:bg-gray-700 dark:hover:bg-gray-600 transition-colors duration-200"
        >
          About
        </Link>
        <Link
          to="/contact"
          className="text-white hover:text-white px-3 py-2 rounded-md text-sm font-medium hover:bg-gray-700 dark:hover:bg-gray-600 transition-colors duration-200"
        >
          Contact
        </Link>
      </div>

      {/* Vertical Divider */}
      <div className="h-6 w-px bg-gray-700 mx-4" />

      {/* Dark Mode Toggle */}
      <div className="hover:bg-gray-700 dark:hover:bg-gray-600 rounded-full transition-colors duration-200">
        <DarkModeToggle />
      </div>

      {/* Vertical Divider */}
      <div className="h-6 w-px bg-gray-700 mx-4" />

      {/* Auth Link */}
      <div className="flex items-center space-x-4">
        <Link
          to="/register"
          className="bg-indigo-500 text-white hover:bg-indigo-600 dark:hover:bg-indigo-700 px-4 py-2 rounded-md text-sm font-medium transition-colors duration-200"
        >
          Register
        </Link>
        <Link
          to="/login"
          className="text-white hover:text-indigo-400 dark:hover:text-indigo-300 text-sm font-medium transition-colors duration-200"
        >
          Sign In
        </Link>
      </div>
    </div>
  );
}