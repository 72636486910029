import { useState } from 'react';
import { X, Upload, XCircle, Link } from 'lucide-react';

interface PendingReasonModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: (reason: string, files?: File[]) => void;
  isSubmitting?: boolean;
}

export function PendingReasonModal({ isOpen, onClose, onConfirm, isSubmitting = false }: PendingReasonModalProps) {
  const [reason, setReason] = useState('');
  const [files, setFiles] = useState<File[]>([]);
  const [previews, setPreviews] = useState<string[]>([]);

  if (!isOpen) return null;

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = Array.from(e.target.files || []);
    const validFiles = selectedFiles.filter(file => 
      file.type.startsWith('image/') && file.size <= 5 * 1024 * 1024 // 5MB limit
    );

    if (validFiles.length < selectedFiles.length) {
      alert('Some files were not added. Please ensure all files are images under 5MB.');
    }

    setFiles(prevFiles => [...prevFiles, ...validFiles]);
    
    // Create previews for the new files
    validFiles.forEach(file => {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviews(prev => [...prev, reader.result as string]);
      };
      reader.readAsDataURL(file);
    });
  };

  const removeFile = (index: number) => {
    setFiles(files.filter((_, i) => i !== index));
    setPreviews(previews.filter((_, i) => i !== index));
  };

  const openPreview = (preview: string) => {
    // Create a new window with proper HTML content
    const previewWindow = window.open('', '_blank');
    if (previewWindow) {
      previewWindow.document.write(`
        <!DOCTYPE html>
        <html>
          <head>
            <title>Image Preview</title>
            <style>
              body {
                margin: 0;
                padding: 20px;
                display: flex;
                justify-content: center;
                align-items: center;
                min-height: 100vh;
                background: #1a1a1a;
              }
              img {
                max-width: 100%;
                max-height: 90vh;
                object-fit: contain;
                border-radius: 4px;
              }
            </style>
          </head>
          <body>
            <img src="${preview}" alt="Preview" />
          </body>
        </html>
      `);
      previewWindow.document.close();
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onConfirm(reason, files);
  };

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto">
      <div className="flex min-h-screen items-center justify-center p-4 text-center">
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" onClick={onClose} />

        <div className="relative transform overflow-hidden rounded-lg bg-white dark:bg-gray-800 px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
          <div className="absolute right-0 top-0 pr-4 pt-4">
            <button
              type="button"
              className="rounded-md bg-white dark:bg-gray-800 text-gray-400 hover:text-gray-500 focus:outline-none"
              onClick={onClose}
            >
              <X className="h-6 w-6" />
            </button>
          </div>

          <div className="mt-3 text-center sm:mt-0 sm:text-left w-full">
            <h3 className="text-lg font-semibold leading-6 text-gray-900 dark:text-white">
              Additional Information Required
            </h3>
            <div className="mt-2">
              <p className="text-sm text-gray-500 dark:text-gray-400">
                Please specify what additional information is needed from the customer to proceed with this application. Write it in detail so that the customer can understand.
              </p>
              <div className="mt-2 text-sm text-gray-500 dark:text-gray-400">
                Example:
                <ul className="list-disc ml-5 mt-1">
                  <li>Please provide your current work authorization status (e.g., citizen, permanent resident, visa type)</li>
                </ul>
              </div>
            </div>

            <form onSubmit={handleSubmit} className="mt-4">
              <textarea
                value={reason}
                onChange={(e) => setReason(e.target.value)}
                className="w-full rounded-md border-gray-300 dark:border-gray-600 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 dark:bg-gray-700 dark:text-white p-3"
                rows={4}
                placeholder="Enter the required information in detail..."
                required
              />

              {/* File Upload Section */}
              <div className="mt-4">
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                  Attach Screenshots (Optional)
                </label>
                <div className="flex items-center gap-2">
                  <label className="inline-flex items-center px-3 py-2 rounded-md border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700 text-sm font-medium text-gray-700 dark:text-gray-200 hover:bg-gray-50 dark:hover:bg-gray-600 cursor-pointer">
                    <Upload className="w-4 h-4 mr-2" />
                    Add Screenshots
                    <input
                      type="file"
                      className="hidden"
                      accept="image/*"
                      multiple
                      onChange={handleFileChange}
                      disabled={isSubmitting}
                    />
                  </label>
                </div>
                {files.length === 0 && (
                  <p className="mt-1 text-sm text-gray-500 dark:text-gray-400">
                    Screenshots help the customer understand what information is needed
                  </p>
                )}
              </div>

              {/* File List Section */}
              {files.length > 0 && (
                <div className="mt-4">
                  <div className="space-y-2">
                    {files.map((file, index) => (
                      <div key={index} className="flex items-center justify-between group">
                        <button
                          type="button"
                          onClick={() => openPreview(previews[index])}
                          className="flex items-center text-sm text-indigo-600 dark:text-indigo-400 hover:text-indigo-500"
                        >
                          <Link className="w-4 h-4 mr-2" />
                          {file.name}
                        </button>
                        <button
                          type="button"
                          onClick={() => removeFile(index)}
                          className="text-red-500 hover:text-red-600 p-1 rounded-full"
                        >
                          <XCircle className="w-4 h-4" />
                        </button>
                      </div>
                    ))}
                  </div>
                </div>
              )}

              <div className="mt-5 sm:mt-6 sm:flex sm:flex-row-reverse gap-3">
                <button
                  type="submit"
                  disabled={isSubmitting || !reason.trim()}
                  className="inline-flex w-full justify-center rounded-md bg-yellow-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-yellow-500 sm:w-auto disabled:opacity-50"
                >
                  {isSubmitting ? 'Requesting Info...' : 'Request Info'}
                </button>
                <button
                  type="button"
                  className="mt-3 inline-flex w-full justify-center rounded-md bg-white dark:bg-gray-700 px-3 py-2 text-sm font-semibold text-gray-900 dark:text-gray-200 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-gray-600 hover:bg-gray-50 dark:hover:bg-gray-600 sm:mt-0 sm:w-auto"
                  onClick={onClose}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
} 