import { Mail, Check, X, Coins, Loader2 } from 'lucide-react';
import { useAuth } from '../hooks/useAuth';
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';
import { useState, useEffect } from 'react';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { toast } from 'react-hot-toast';

const CREDIT_PACKAGES = {
  plus: {
    price: 12,
    credits: 150,
    name: 'Plus Package'
  },
  pro: {
    price: 25,
    credits: 375,
    name: 'Pro Package'
  }
};

export function Credits() {
  const { userProfile, user } = useAuth();
  const [selectedPackage, setSelectedPackage] = useState<keyof typeof CREDIT_PACKAGES | null>(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [paypalKey, setPaypalKey] = useState(Date.now());

  useEffect(() => {
    setPaypalKey(Date.now());
  }, [selectedPackage]);

  const handlePaymentSuccess = async (orderId: string, packageType: keyof typeof CREDIT_PACKAGES) => {
    if (!user) {
      toast.error('You must be logged in to purchase credits');
      return;
    }
    
    try {
      const functions = getFunctions();
      const verifyPayment = httpsCallable(functions, 'verifyPaymentAndAddCredits');
      
      await verifyPayment({
        orderId,
        packageType
      });

      // Show success toast and refresh page after it completes
      const toastId = toast.success(
        `Successfully added ${CREDIT_PACKAGES[packageType].credits} credits to your account!`,
        {
          duration: 2000
        }
      );

      // Wait for toast duration, reset PayPal component, then refresh
      setTimeout(() => {
        toast.dismiss(toastId);
        setPaypalKey(Date.now());
        setSelectedPackage(null);
        window.location.reload();
      }, 2000);
    } catch (error: any) {
      console.error('Error verifying payment:', error);
      toast.error('There was an error verifying your payment. Please contact support.');
      setPaypalKey(Date.now());
      setIsProcessing(false);
      setSelectedPackage(null);
    }
  };

  const renderPayPalButtons = (packageType: keyof typeof CREDIT_PACKAGES) => (
    // PayPal integration temporarily disabled
    <div className="w-full p-4 text-center border border-indigo-200 dark:border-indigo-700 rounded-lg bg-indigo-50 dark:bg-indigo-900/30">
      <p className="text-indigo-800 dark:text-indigo-200 font-medium mb-2">Payment System Under Maintenance</p>
      <p className="text-gray-700 dark:text-gray-300">Please send a message on our phone number to purchase this plan.</p>
      <p className="text-gray-700 dark:text-gray-300 mt-1">
        <a href="tel:+17043636326" className="text-indigo-600 dark:text-indigo-400 hover:underline">
          +1 (704) 363-6326
        </a>
      </p>
    </div>

    /* PayPal integration temporarily disabled
    <PayPalScriptProvider 
      key={paypalKey}
      options={{ 
        clientId: import.meta.env.VITE_PAYPAL_CLIENT_ID,
        currency: "USD",
        intent: "capture",
        components: "buttons",
        disableFunding: import.meta.env.VITE_PAYPAL_MODE === 'production' ? "paylater" : "credit,card,paylater"
      }}
    >
      <PayPalButtons 
        style={{ 
          layout: "vertical",
          shape: "rect",
          label: "pay",
          height: 45,
          color: "blue"
        }}
        fundingSource="paypal"
        createOrder={(_, actions) => {
          return actions.order.create({
            intent: "CAPTURE",
            purchase_units: [{
              amount: {
                value: CREDIT_PACKAGES[packageType].price.toString(),
                currency_code: "USD"
              },
              description: CREDIT_PACKAGES[packageType].name
            }]
          });
        }}
        onApprove={async (_, actions) => {
          setIsProcessing(true);
          try {
            if (actions.order) {
              const order = await actions.order.capture();
              if (order.status === 'COMPLETED' && order.id) {
                await handlePaymentSuccess(order.id, packageType);
              } else {
                throw new Error('Payment not completed or order ID missing');
              }
            }
          } catch (error) {
            console.error('Payment processing error:', error);
            toast.error('Payment processing failed. Please try again or contact support.');
            setPaypalKey(Date.now());
            setIsProcessing(false);
            setSelectedPackage(null);
          }
        }}
        onError={(err) => {
          console.error('PayPal error:', err);
          toast.error('There was an error processing your payment. Please try again.');
          setPaypalKey(Date.now());
          setIsProcessing(false);
          setSelectedPackage(null);
        }}
        onCancel={() => {
          toast('Payment cancelled', { icon: '❌' });
          setPaypalKey(Date.now());
          setIsProcessing(false);
          setSelectedPackage(null);
        }}
      />
    </PayPalScriptProvider>
    */
  );

  const renderPackageButton = (packageType: keyof typeof CREDIT_PACKAGES) => {
    if (selectedPackage === packageType) {
      return (
        <div className="space-y-4">
          {renderPayPalButtons(packageType)}
          {isProcessing && (
            <div className="flex items-center justify-center text-indigo-600 dark:text-indigo-400">
              <Loader2 className="h-5 w-5 animate-spin mr-2" />
              <span>Processing payment...</span>
            </div>
          )}
          <button
            onClick={() => setSelectedPackage(null)}
            className="w-full px-4 py-2 bg-gray-200 dark:bg-gray-700 text-gray-700 dark:text-gray-200 rounded-lg hover:bg-gray-300 dark:hover:bg-gray-600 transition-colors"
          >
            Cancel
          </button>
        </div>
      );
    }

    return (
      <button
        onClick={() => {
          // Show message to contact admin for purchase
          toast('Please contact us at +1 (704) 363-6326 to purchase this plan.', {
            duration: 5000,
            icon: '📱',
            style: {
              background: document.documentElement.classList.contains('dark') ? '#1e293b' : '#fff',
              color: document.documentElement.classList.contains('dark') ? '#e2e8f0' : '#334155',
              border: '1px solid',
              borderColor: document.documentElement.classList.contains('dark') ? '#334155' : '#e2e8f0',
            }
          });
          setSelectedPackage(packageType);
        }}
        className="w-full px-4 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 transition-colors"
        disabled={isProcessing}
      >
        Buy Now
      </button>
    );
  };

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12 bg-gray-50 dark:bg-gray-900 min-h-screen">
      <div className="text-center mb-12">
        <h1 className="text-5xl font-extrabold text-gray-900 dark:text-gray-100 mb-4">Buy Credit Points (CP)</h1>
        <div className="flex items-center justify-center mb-4">
          <div className="inline-flex items-center px-4 py-2 rounded-lg bg-amber-100 dark:bg-amber-900/30 border border-amber-200 dark:border-amber-700">
            <Coins className="h-5 w-5 text-amber-500 dark:text-amber-400 mr-2" />
            <span className="text-amber-700 dark:text-amber-300 font-medium">
              Current Balance: {userProfile?.credits || 0} CP
            </span>
          </div>
        </div>
        <p className="mt-5 text-xl text-gray-500 dark:text-gray-400">
          Buy Application Credits for your job search journey. These are one time payments.
        </p>
      </div>

      <div className="mt-12 space-y-4 sm:mt-16 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-8 lg:max-w-5xl lg:mx-auto xl:max-w-6xl xl:mx-auto xl:grid-cols-3 xl:gap-12">
        {/* Free Package */}
        <div className="border border-gray-200 dark:border-gray-700 rounded-lg shadow-sm divide-y divide-gray-200 dark:divide-gray-700 bg-white dark:bg-gray-800 max-w-sm mx-auto w-full">
          <div className="p-6">
            <h2 className="text-2xl font-semibold leading-6 text-gray-900 dark:text-white">
              Free
            </h2>
            <p className="mt-4 text-sm text-gray-500 dark:text-gray-400">
              Perfect for trying out our service.
            </p>
            <p className="mt-8">
              <span className="text-4xl font-extrabold text-gray-900 dark:text-gray-100">$0</span>
              <span className="text-gray-500 dark:text-gray-500 ml-2">USD</span>
            </p>
            <p className="mt-1 text-base text-gray-600 dark:text-gray-400">
              15 Application Credits
            </p>
            <p className="mt-2 text-sm font-semibold text-green-600 dark:text-green-400">
              $0.00 per credit point
            </p>
          </div>
          <div className="pt-6 pb-8 px-6">
            <h3 className="text-sm font-medium text-gray-900 dark:text-white tracking-wide uppercase">
              What's included
            </h3>
            <ul className="mt-6 space-y-4">
              <li className="flex space-x-3">
                <Check className="flex-shrink-0 h-5 w-5 text-green-500" />
                <span className="text-sm text-gray-500 dark:text-gray-400">15 Application Credits</span>
              </li>
              <li className="flex space-x-3">
                <Check className="flex-shrink-0 h-5 w-5 text-green-500" />
                <span className="text-sm text-gray-500 dark:text-gray-400">5 Applications</span>
              </li>
              <li className="flex space-x-3">
                <Check className="flex-shrink-0 h-5 w-5 text-amber-500" />
                <span className="text-sm text-gray-500 dark:text-gray-400">Basic Agent Processing</span>
              </li>
              <li className="flex space-x-3">
                <X className="flex-shrink-0 h-5 w-5 text-red-500" />
                <span className="text-sm text-gray-500 dark:text-gray-400">Support</span>
              </li>
            </ul>
          </div>
        </div>

        {/* Plus Package */}
        <div className="border border-gray-200 dark:border-gray-700 rounded-lg shadow-sm divide-y divide-gray-200 dark:divide-gray-700 bg-white dark:bg-gray-800 max-w-sm mx-auto w-full">
          <div className="p-6">
            <h2 className="text-2xl font-semibold leading-6 text-gray-900 dark:text-white">
              Plus
            </h2>
            <p className="mt-4 text-sm text-gray-500 dark:text-gray-400">
              Most popular for active job seekers.
            </p>
            <p className="mt-8">
              <span className="text-4xl font-extrabold text-gray-900 dark:text-gray-100">$12</span>
              <span className="text-gray-500 dark:text-gray-500 ml-2">USD</span>
            </p>
            <p className="mt-1 text-base text-gray-600 dark:text-gray-400">
              150 Application Credits
            </p>
            <p className="mt-2 text-sm font-semibold text-green-600 dark:text-green-400">
              $0.08 per credit point
            </p>
          </div>
          <div className="pt-6 pb-8 px-6">
            <h3 className="text-sm font-medium text-gray-900 dark:text-white tracking-wide uppercase">
              What's included
            </h3>
            <ul className="mt-6 space-y-4 mb-6">
              <li className="flex space-x-3">
                <Check className="flex-shrink-0 h-5 w-5 text-green-500" />
                <span className="text-sm text-gray-500 dark:text-gray-400">150 Application Credits</span>
              </li>
              <li className="flex space-x-3">
                <Check className="flex-shrink-0 h-5 w-5 text-green-500" />
                <span className="text-sm text-gray-500 dark:text-gray-400">50 Applications</span>
              </li>
              <li className="flex space-x-3">
                <Check className="flex-shrink-0 h-5 w-5 text-green-500" />
                <span className="text-sm text-gray-500 dark:text-gray-400">Priority Agent Processing</span>
              </li>
              <li className="flex space-x-3">
                <Check className="flex-shrink-0 h-5 w-5 text-green-500" />
                <span className="text-sm text-gray-500 dark:text-gray-400">Support</span>
              </li>
            </ul>
            {renderPackageButton('plus')}
          </div>
        </div>

        {/* Pro Package */}
        <div className="border border-gray-200 dark:border-gray-700 rounded-lg shadow-sm divide-y divide-gray-200 dark:divide-gray-700 bg-white dark:bg-gray-800 max-w-sm mx-auto w-full">
          <div className="p-6">
            <h2 className="text-2xl font-semibold leading-6 text-gray-900 dark:text-white">
              Pro
            </h2>
            <p className="mt-4 text-sm text-gray-500 dark:text-gray-400">
              Best value for serious job hunters.
            </p>
            <p className="mt-8">
              <span className="text-4xl font-extrabold text-gray-900 dark:text-gray-100">$25</span>
              <span className="text-gray-500 dark:text-gray-500 ml-2">USD</span>
            </p>
            <p className="mt-1 text-base text-gray-600 dark:text-gray-400">
              375 Application Credits
            </p>
            <p className="mt-2 text-sm font-semibold text-green-600 dark:text-green-400">
              $0.067 per credit point
            </p>
          </div>
          <div className="pt-6 pb-8 px-6">
            <h3 className="text-sm font-medium text-gray-900 dark:text-white tracking-wide uppercase">
              What's included
            </h3>
            <ul className="mt-6 space-y-4 mb-6">
              <li className="flex space-x-3">
                <Check className="flex-shrink-0 h-5 w-5 text-green-500" />
                <span className="text-sm text-gray-500 dark:text-gray-400">375 Application Credits</span>
              </li>
              <li className="flex space-x-3">
                <Check className="flex-shrink-0 h-5 w-5 text-green-500" />
                <span className="text-sm text-gray-500 dark:text-gray-400">125 Applications</span>
              </li>
              <li className="flex space-x-3">
                <Check className="flex-shrink-0 h-5 w-5 text-green-500" />
                <span className="text-sm text-gray-500 dark:text-gray-400">VIP Agent Processing</span>
              </li>
              <li className="flex space-x-3">
                <Check className="flex-shrink-0 h-5 w-5 text-green-500" />
                <span className="text-sm text-gray-500 dark:text-gray-400">Priority Support</span>
              </li>
            </ul>
            {renderPackageButton('pro')}
          </div>
        </div>
      </div>

      <div className="max-w-3xl mx-auto mt-16">
        <div className="text-center mb-8">
          <p className="text-lg text-gray-600 dark:text-gray-400">
            <strong>Note:</strong> Each application submission costs 3 Application Credits.
          </p>
        </div>
        
        <div className="bg-white dark:bg-gray-800 rounded-lg shadow-sm p-8 border border-gray-200 dark:border-gray-700">
          <div className="flex items-center mb-4">
            <h2 className="text-xl font-semibold text-gray-900 dark:text-white">Need Help?</h2>
          </div>
          <p className="mb-6 text-gray-600 dark:text-gray-400">
            If you have any questions about our credit packages or need assistance with your purchase, our support team is here to help.
          </p>
          
          <div className="flex justify-center">
            <a
              href="mailto:support@blitzapply.com"
              className="inline-flex items-center px-6 py-3 rounded-lg text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700 transition-colors duration-200"
            >
              <Mail className="h-5 w-5 mr-2" />
              Contact Support
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}