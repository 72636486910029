type LoadingSpinnerProps = {
  size?: 'sm' | 'md' | 'lg';
}

export function LoadingSpinner({ size = 'lg' }: LoadingSpinnerProps) {
  const sizeClasses = {
    sm: 'h-4 w-4 border-t-1 border-b-1',
    md: 'h-6 w-6 border-t-2 border-b-2',
    lg: 'h-8 w-8 border-t-2 border-b-2'
  };
  
  return (
    <div className={`animate-spin rounded-full ${sizeClasses[size]} border-indigo-600 dark:border-indigo-400`}></div>
  );
} 