import { useState } from 'react';
import { Trash2, ToggleLeft, ChevronLeft, ChevronRight, Eye } from 'lucide-react';
import { UserProfile } from '../../types/user';
import { useNavigate, Link } from 'react-router-dom';
import { auth, db } from '../../lib/firebase';
import { sendPasswordResetEmail } from 'firebase/auth';
import { doc, updateDoc } from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { toast } from 'react-hot-toast';
import { ConfirmDeleteAgentModal } from '../modals/ConfirmDeleteAgentModal';
import { ChangeAgentPasswordModal } from '../modals/ChangeAgentPasswordModal';

interface AgentsTableProps {
  agents: (UserProfile & { id: string })[];
  onAgentUpdated: () => void;
  pageSize?: number;
}

export function AgentsTable({ agents, onAgentUpdated, pageSize = 10 }: AgentsTableProps) {
  const navigate = useNavigate();
  const [agentToDelete, setAgentToDelete] = useState<UserProfile & { id: string } | null>(null);
  const [agentToChangePassword, setAgentToChangePassword] = useState<UserProfile & { id: string } | null>(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [, setUpdatingStatus] = useState<string | null>(null);
  const [currentPage, setCurrentPage] = useState(1);

  const totalPages = Math.ceil(agents.length / pageSize);
  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const currentAgents = agents.slice(startIndex, endIndex);

  const handlePageClick = (page: number) => {
    setCurrentPage(page);
  };

  const renderPageNumbers = () => {
    const pages = [];
    const maxVisiblePages = 5;
    
    // Always show first page
    pages.push(
      <button
        key={1}
        onClick={() => handlePageClick(1)}
        className={`px-3 py-1 rounded-md ${
          currentPage === 1
            ? 'bg-indigo-600 text-white'
            : 'text-gray-600 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700'
        }`}
      >
        1
      </button>
    );

    let startPage = Math.max(2, currentPage - 1);
    let endPage = Math.min(totalPages - 1, currentPage + 1);

    // Adjust when near the beginning
    if (currentPage <= 3) {
      endPage = Math.min(maxVisiblePages - 1, totalPages - 1);
    }

    // Adjust when near the end
    if (currentPage >= totalPages - 2) {
      startPage = Math.max(2, totalPages - (maxVisiblePages - 2));
    }

    // Add ellipsis after first page
    if (startPage > 2) {
      pages.push(
        <span key="ellipsis1" className="px-2 text-gray-500 dark:text-gray-400">
          ...
        </span>
      );
    }

    // Add middle pages
    for (let i = startPage; i <= endPage; i++) {
      pages.push(
        <button
          key={i}
          onClick={() => handlePageClick(i)}
          className={`px-3 py-1 rounded-md ${
            currentPage === i
              ? 'bg-indigo-600 text-white'
              : 'text-gray-600 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700'
          }`}
        >
          {i}
        </button>
      );
    }

    // Add ellipsis before last page
    if (endPage < totalPages - 1) {
      pages.push(
        <span key="ellipsis2" className="px-2 text-gray-500 dark:text-gray-400">
          ...
        </span>
      );
    }

    // Always show last page if there is more than one page
    if (totalPages > 1) {
      pages.push(
        <button
          key={totalPages}
          onClick={() => handlePageClick(totalPages)}
          className={`px-3 py-1 rounded-md ${
            currentPage === totalPages
              ? 'bg-indigo-600 text-white'
              : 'text-gray-600 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700'
          }`}
        >
          {totalPages}
        </button>
      );
    }

    return pages;
  };

  const handleDeleteAgent = async () => {
    if (!agentToDelete) return;

    try {
      const functions = getFunctions(undefined, 'us-central1');
      const deleteUserAccount = httpsCallable(functions, 'deleteUserAccount');
      
      await deleteUserAccount({ uid: agentToDelete.id });
      toast.success('Agent deleted successfully');
      onAgentUpdated();
    } catch (error: any) {
      console.error('Error deleting agent:', error);
      toast.error(error.message || 'Failed to delete agent');
    } finally {
      setAgentToDelete(null);
      setShowDeleteModal(false);
    }
  };

  const handleToggleStatus = async (agent: UserProfile & { id: string }) => {
    try {
      setUpdatingStatus(agent.id);
      await updateDoc(doc(db, 'users', agent.id), {
        isKaput: !agent.isKaput
      });
      toast.success(`Agent ${agent.isKaput ? 'reactivated' : 'decommissioned'} successfully`);
      onAgentUpdated();
    } catch (error) {
      console.error('Error updating agent status:', error);
      toast.error('Failed to update agent status');
    } finally {
      setUpdatingStatus(null);
    }
  };

  return (
    <>
      <div className="bg-gray-50 dark:bg-gray-800 shadow-sm rounded-lg overflow-hidden">
        <div className="overflow-x-auto">
          <div className="min-w-full">
            {agents.length > 0 ? (
              <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                <thead className="bg-gray-100 dark:bg-gray-600">
                  <tr>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-white uppercase tracking-wider">
                      Name
                    </th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-white uppercase tracking-wider">
                      Email
                    </th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-white uppercase tracking-wider">
                      Phone
                    </th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-white uppercase tracking-wider">
                      Status
                    </th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-white uppercase tracking-wider">
                      Joined
                    </th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-white uppercase tracking-wider">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white dark:bg-gray-800 divide-y divide-gray-200 dark:divide-gray-700">
                  {currentAgents.map((agent) => (
                    <tr 
                      key={agent.id}
                      className="hover:bg-gray-50 dark:hover:bg-gray-700 transition-colors cursor-pointer"
                    >
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 dark:text-gray-100">
                        <Link
                          to={`/admin/agents/${agent.id}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-indigo-600 dark:text-indigo-400 hover:text-indigo-500 dark:hover:text-indigo-300"
                          onClick={(e) => e.stopPropagation()}
                        >
                          {agent.firstName} {agent.lastName}
                        </Link>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-100">
                        <Link
                          to={`/admin/agents/${agent.id}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-indigo-600 dark:text-indigo-400 hover:text-indigo-500 dark:hover:text-indigo-300"
                          onClick={(e) => e.stopPropagation()}
                        >
                          {agent.email}
                        </Link>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-100">
                        {agent.phoneNumber ? (
                          <button
                            onClick={(e) => {
                              e.stopPropagation();
                              navigator.clipboard.writeText(agent.phoneNumber!);
                              toast.success('Phone number copied to clipboard');
                            }}
                            className="text-indigo-600 dark:text-indigo-400 hover:text-indigo-500 dark:hover:text-indigo-300 cursor-pointer"
                          >
                            {agent.phoneNumber}
                          </button>
                        ) : (
                          '-'
                        )}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                          agent.isVerified ? 'bg-green-100 text-green-800' : 'bg-yellow-100 text-yellow-800'
                        }`}>
                          {agent.isVerified ? 'Verified' : 'Pending'}
                        </span>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-100">
                        {new Date(agent.createdAt).toLocaleDateString()}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium space-x-3 flex items-center">
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            navigate(`/admin/agents/${agent.id}`);
                          }}
                          className="text-blue-500 hover:text-blue-600 dark:text-blue-400 dark:hover:text-blue-300 transition-colors"
                          title="View Agent Details"
                        >
                          <Eye className="h-5 w-5" />
                        </button>
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            handleToggleStatus(agent);
                          }}
                          className={`transition-colors ${
                            !agent.isKaput 
                              ? 'text-green-500 hover:text-green-600 dark:text-green-400 dark:hover:text-green-300' 
                              : 'text-yellow-500 hover:text-yellow-600 dark:text-yellow-400 dark:hover:text-yellow-300'
                          }`}
                          title={agent.isKaput ? "Reactivate Agent" : "Deactivate Agent"}
                        >
                          <ToggleLeft className={`h-5 w-5 transform transition-transform duration-200 ${
                            !agent.isKaput ? 'rotate-180' : 'rotate-0'
                          }`} />
                        </button>
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            setAgentToDelete(agent);
                            setShowDeleteModal(true);
                          }}
                          className="text-red-500 hover:text-red-600 dark:text-red-400 dark:hover:text-red-300 transition-colors"
                          title="Delete Agent"
                        >
                          <Trash2 className="h-5 w-5" />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <div className="text-center py-4 text-gray-500 dark:text-gray-400">
                No agents found
              </div>
            )}
          </div>
        </div>
      </div>

      {agents.length > pageSize && (
        <div className="mt-4 flex justify-center">
          <nav className="flex items-center space-x-1">
            <button
              onClick={() => handlePageClick(Math.max(1, currentPage - 1))}
              disabled={currentPage === 1}
              className="p-1 rounded hover:bg-gray-100 dark:hover:bg-gray-700 disabled:opacity-50"
            >
              <ChevronLeft className="h-4 w-4" />
            </button>
            {renderPageNumbers()}
            <button
              onClick={() => handlePageClick(Math.min(totalPages, currentPage + 1))}
              disabled={currentPage === totalPages}
              className="p-1 rounded hover:bg-gray-100 dark:hover:bg-gray-700 disabled:opacity-50"
            >
              <ChevronRight className="h-4 w-4" />
            </button>
          </nav>
        </div>
      )}

      {agentToDelete && (
        <ConfirmDeleteAgentModal
          isOpen={showDeleteModal}
          onClose={() => {
            setShowDeleteModal(false);
            setAgentToDelete(null);
          }}
          onConfirm={handleDeleteAgent}
          agent={agentToDelete}
        />
      )}

      {agentToChangePassword && (
        <ChangeAgentPasswordModal
          isOpen={showPasswordModal}
          onClose={() => {
            setShowPasswordModal(false);
            setAgentToChangePassword(null);
          }}
          onConfirm={async () => {
            try {
              await sendPasswordResetEmail(auth, agentToChangePassword.email);
              toast.success('Password reset email sent successfully');
              setShowPasswordModal(false);
            } catch (error) {
              toast.error('Failed to send password reset email');
            }
          }}
          agent={agentToChangePassword}
        />
      )}
    </>
  );
}