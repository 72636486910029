import { useCallback } from 'react';
import { useAuth } from './useAuth';
import { db, storage } from '../lib/firebase';
import { collection, doc, writeBatch, increment, updateDoc, getDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { Application, ApplicationStatus } from '../types/application';
import toast from 'react-hot-toast';

export function useApplicationActions() {
  const { user } = useAuth();

  const submitApplication = useCallback(async (data: Omit<Application, 'id' | 'createdAt' | 'updatedAt'>) => {
    if (!user) {
      throw new Error('User not authenticated');
    }

    const batch = writeBatch(db);
    const now = new Date().toISOString();

    // Create application document
    const applicationRef = doc(collection(db, 'applications'));
    const applicationData = {
      ...data,
      createdAt: now,
      updatedAt: now,
      lastActionAt: now,
      lastActionBy: user.uid,
      comments: [],
      paymentStatus: null  // Initialize paymentStatus as null for new applications
    };
    batch.set(applicationRef, applicationData);

    // Deduct credit from the user
    const userRef = doc(db, 'users', user.uid);
    batch.update(userRef, {
      credits: increment(-3),  // Deduct 3 credits
      updatedAt: now
    });

    try {
      await batch.commit();

      const newApplication = {
        id: applicationRef.id,
        ...applicationData
      } as Application;

      // Telegram notifications are now handled server-side via Firebase Functions
      // The following code has been removed as it's no longer needed:
      // await TelegramService.logApplicationChange(
      //   newApplication,
      //   'created',
      //   undefined,
      //   'New application submitted'
      // );

      toast.success('Application submitted successfully');
      return newApplication;
    } catch (error: any) {
      console.error('Error submitting application:', error);
      throw error;
    }
  }, [user]);

  const updateApplicationStatus = async (
    applicationId: string, 
    status: ApplicationStatus,
    notes?: string,
    files?: File[],
    skipNotification: boolean = false
  ) => {
    if (!user) {
      throw new Error('User not authenticated');
    }

    try {
      const appRef = doc(db, 'applications', applicationId);
      const appSnap = await getDoc(appRef);
      const currentApp = appSnap.data() as Application;
      if (!currentApp) {
        throw new Error('Application not found');
      }

      const now = new Date().toISOString();
      const updateData: any = {
        status,
        updatedAt: now,
        lastActionBy: user.uid,
        lastActionAt: now
      };

      // Add specific fields based on status
      if (status === 'completed') {
        updateData.completedAt = now;
        // Calculate time from creation to completion
        updateData.fulfillmentTime = new Date(now).getTime() - new Date(currentApp.createdAt).getTime();
        updateData.paymentStatus = 'pending';
      } else if (status === 'in_progress') {
        updateData.lastInProgressAt = now;
      }

      // Add notes if provided
      if (notes) {
        updateData.notes = notes;
      }

      await updateDoc(appRef, updateData);

      // Telegram notifications are now handled server-side via Firebase Functions
      // The following code has been removed as it's no longer needed:
      // if (!skipNotification) {
      //   if (status === 'pending') {
      //     await TelegramService.logPendingTicket(
      //       { ...currentApp, ...updateData },
      //       files
      //     );
      //   } else {
      //     await TelegramService.logApplicationChange(
      //       { ...currentApp, ...updateData },
      //       'updated',
      //       currentApp.agentName,
      //       `Status changed from ${currentApp.status} to ${status}${notes ? `\nNotes: ${notes}` : ''}`
      //     );
      //   }
      // }

      // If we have files to upload
      if (files && files.length > 0) {
        const uploadPromises = files.map(async (file) => {
          const fileRef = ref(storage, `applications/${applicationId}/pending/${Date.now()}_${file.name}`);
          const snapshot = await uploadBytes(fileRef, file);
          const downloadURL = await getDownloadURL(snapshot.ref);
          
          return {
            id: Date.now().toString(),
            name: file.name,
            url: downloadURL,
            type: file.type,
            size: file.size
          };
        });

        const uploadedFiles = await Promise.all(uploadPromises);
        
        // Update the application with the file attachments
        await updateDoc(appRef, {
          pendingAttachments: uploadedFiles
        });
      }

      toast.success(`Application status updated to ${status}`);
    } catch (error: any) {
      console.error('Error updating application status:', error);
      toast.error(`Failed to update application status: ${error.message}`);
      throw error;
    }
  };

  return {
    submitApplication,
    updateApplicationStatus
  };
}