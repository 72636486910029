import { ArrowLeft } from 'lucide-react';
import { Link } from 'react-router-dom';

export function RefundPage() {
  return (
    <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8 py-8 bg-gray-50 dark:bg-gray-900 min-h-screen">
      <div className="mb-8">
        <Link
          to="/credits"
          className="flex items-center text-gray-600 hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-200 transition-colors"
        >
          <ArrowLeft className="h-5 w-5 mr-2" />
          Back to Credits
        </Link>
      </div>

      <div className="bg-white dark:bg-gray-800 rounded-lg shadow-sm p-8 border border-gray-200 dark:border-gray-700">
        <h1 className="text-3xl font-bold text-gray-900 dark:text-white mb-8">Refund Policy</h1>
        
        <div className="space-y-8 text-gray-600 dark:text-gray-400">
          <section>
            <h2 className="text-xl font-semibold text-gray-900 dark:text-white mb-4">Overview</h2>
            <p>
              At BlitzApply, we strive to provide the best possible service to our customers. Our refund policy is designed 
              to be fair, transparent, and hassle-free. We understand that circumstances may arise where a refund is necessary, 
              and we're committed to making this process as smooth as possible.
            </p>
          </section>

          <section>
            <h2 className="text-xl font-semibold text-gray-900 dark:text-white mb-4">When You're Eligible for a Refund</h2>
            <ul className="list-disc ml-6 space-y-3">
              <li>We are unable to complete your application due to technical issues</li>
              <li>The job posting becomes unavailable before we can complete your application</li>
              <li>Your application was incorrectly filled by our agents (must provide screenshots showing the discrepancies)</li>
            </ul>
          </section>

          <section>
            <h2 className="text-xl font-semibold text-gray-900 dark:text-white mb-4">Refund Process</h2>
            <ul className="list-disc ml-6 space-y-3">
              <li>All refunds are provided as BlitzApply credits</li>
              <li>Refunded credits are processed within 24-48 hours after approval</li>
              <li>Credits can be used immediately for new applications once credited</li>
            </ul>
          </section>

          <section>
            <h2 className="text-xl font-semibold text-gray-900 dark:text-white mb-4">How to Request a Refund</h2>
            <p className="mb-4">
              To request a refund, contact our support team at support@blitzapply.com. If disputing an incorrectly filled application,
              please include screenshots highlighting where the information was filled incorrectly.
            </p>
          </section>

          <section>
            <h2 className="text-xl font-semibold text-gray-900 dark:text-white mb-4">Non-Refundable Cases</h2>
            <ul className="list-disc ml-6 space-y-3">
              <li>Applications that have already been completed correctly</li>
              <li>Applications older than 30 days from the day of submission</li>
              <li>Disputes without supporting evidence (screenshots) of incorrect information</li>
            </ul>
          </section>

          <section className="bg-blue-50 dark:bg-blue-900/20 rounded-lg p-6">
            <h2 className="text-xl font-semibold text-blue-900 dark:text-blue-100 mb-4">Need Help?</h2>
            <p className="text-blue-800 dark:text-blue-200">
              If you have any questions about our refund policy or need assistance with a refund request, please don't 
              hesitate to contact our support team at{' '}
              <a 
                href="mailto:support@blitzapply.com" 
                className="underline hover:text-blue-600 dark:hover:text-blue-300"
              >
                support@blitzapply.com
              </a>
            </p>
          </section>
        </div>
      </div>
    </div>
  );
} 