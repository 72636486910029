import { initializeApp } from 'firebase/app';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { firebaseConfig } from '../lib/firebase';

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const functions = getFunctions(app);

export interface EmailData {
  name: string;
  email: string;
  subject: string;
  message: string;
  recaptchaToken: string;
}

export interface DeletionRequestData {
  userId: string;
  name: string;
  email: string;
  credits: number;
  reason: string;
  note: string;
}

export interface CancellationRequestData {
  userId: string;
  name: string;
  email: string;
  credits: number;
  reason: string;
  note: string;
}

export const sendContactEmail = async (data: EmailData) => {
  try {
    const sendEmail = httpsCallable(functions, 'sendContactEmail');
    const result = await sendEmail(data);
    return result.data;
  } catch (error: any) {
    console.error('Error sending email:', error);
    throw new Error(error.message);
  }
};

export const sendDeletionRequest = async (data: DeletionRequestData) => {
  try {
    const sendEmail = httpsCallable(functions, 'sendDeletionRequest');
    const result = await sendEmail(data);
    return result.data;
  } catch (error: any) {
    console.error('Error sending deletion request:', error);
    throw new Error(error.message);
  }
};

export const sendCancellationRequest = async (data: CancellationRequestData) => {
  try {
    const sendEmail = httpsCallable(functions, 'sendCancellationRequest');
    const result = await sendEmail(data);
    return result.data;
  } catch (error: any) {
    console.error('Error sending cancellation request:', error);
    throw new Error(error.message);
  }
}; 