import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';
import { useApplicationActions } from '../hooks/useApplicationActions';
import { db } from '../lib/firebase';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { Application } from '../types/application';
import { FileText, UserCircle, ArrowLeft, Search } from 'lucide-react';
import toast from 'react-hot-toast';
import { Link } from 'react-router-dom';
import { TelegramService } from '../services/telegram';

interface Resume {
  id: string;
  profileName: string;
  firstName: string;
  middleName?: string;
  lastName: string;
  resumeURL?: string;
}

interface Customer {
  id: string;
  email: string;
  phoneNumber?: string;
  firstName: string;
  lastName: string;
  credits: number;
}

export function AdminSubmitApplication() {
  const { user } = useAuth();
  const { submitApplication } = useApplicationActions();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState<Customer[]>([]);
  const [selectedCustomer, setSelectedCustomer] = useState<Customer | null>(null);
  const [profiles, setProfiles] = useState<Resume[]>([]);
  const [selectedProfileId, setSelectedProfileId] = useState('');
  const [formData, setFormData] = useState({
    jobUrl: '',
    company: '',
    position: '',
    notes: ''
  });

  // Search for customers
  const searchCustomers = async (searchTerm: string) => {
    if (!searchTerm.trim()) {
      setSearchResults([]);
      return;
    }

    try {
      setLoading(true);
      const customersRef = collection(db, 'users');
      // Create a simple query that only filters by userType
      const firestoreQuery = query(customersRef, where('userType', '==', 'customer'));
      
      const querySnapshot = await getDocs(firestoreQuery);
      const customers = querySnapshot.docs
        .map(doc => ({
          id: doc.id,
          ...doc.data()
        })) as Customer[];
      
      // Filter results client-side based on email or name
      const searchLower = searchTerm.toLowerCase();
      const filteredCustomers = customers.filter(customer => 
        customer.email?.toLowerCase().includes(searchLower) ||
        `${customer.firstName} ${customer.lastName}`.toLowerCase().includes(searchLower)
      );
      
      setSearchResults(filteredCustomers);
    } catch (error) {
      console.error('Error searching customers:', error);
      toast.error('Failed to search customers');
    } finally {
      setLoading(false);
    }
  };

  // Debounced search effect
  useEffect(() => {
    if (!searchQuery.trim()) {
      setSearchResults([]);
      return;
    }

    const timeoutId = setTimeout(() => {
      searchCustomers(searchQuery);
    }, 300);

    return () => clearTimeout(timeoutId);
  }, [searchQuery]);

  // Load customer's resumes
  const loadProfiles = async (customerId: string) => {
    try {
      setLoading(true);
      const q = query(
        collection(db, 'jobProfiles'),
        where('userId', '==', customerId)
      );
      const querySnapshot = await getDocs(q);
      const profilesList = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      })) as Resume[];
      
      setProfiles(profilesList);
      if (profilesList.length === 1) {
        setSelectedProfileId(profilesList[0].id);
      }
    } catch (error) {
      console.error('Error loading profiles:', error);
      toast.error('Failed to load profiles');
    } finally {
      setLoading(false);
    }
  };

  // Handle customer selection
  const handleCustomerSelect = (customer: Customer) => {
    setSelectedCustomer(customer);
    setSearchResults([]);
    setSearchQuery('');
    loadProfiles(customer.id);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!selectedProfileId || !selectedCustomer) {
      toast.error('Please select a customer and resume');
      return;
    }

    // Check if customer has enough credits
    if (selectedCustomer.credits < 3) {
      toast.error('Customer has insufficient credits');
      return;
    }

    try {
      setSubmitting(true);

      // Get selected profile data
      const selectedProfile = profiles.find(p => p.id === selectedProfileId);
      if (!selectedProfile) throw new Error('Profile not found');

      // Create application data
      const applicationData: Omit<Application, 'id' | 'createdAt' | 'updatedAt'> = {
        userId: selectedCustomer.id,
        profileId: selectedProfileId,
        profileName: selectedProfile.profileName,
        jobUrl: formData.jobUrl,
        company: formData.company,
        position: formData.position,
        notes: formData.notes,
        status: 'submitted',
        priority: 0,
        lastActionBy: user!.uid,
        lastActionAt: new Date().toISOString(),
        paymentStatus: null,
        comments: []
      };

      const newApplication = await submitApplication(applicationData);
      
      // Telegram notifications are now handled server-side via Firebase Functions
      // The following code has been removed as it's no longer needed:
      // if (newApplication && user) {
      //   await TelegramService.logNewApplicationSubmission(
      //     newApplication,
      //     {
      //       id: user.uid,
      //       userType: 'admin',
      //       name: `${user.displayName || 'Admin'}`
      //     }
      //   );
      // }
      
      navigate('/admin/applications');
      toast.success('Application submitted successfully');
    } catch (error: any) {
      console.error('Error submitting application:', error);
      toast.error(error.message || 'Failed to submit application');
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12 bg-gray-50 dark:bg-gray-900 min-h-screen">
      <Link
        to="/admin/applications"
        className="flex items-center text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-white mb-6"
      >
        <ArrowLeft className="h-5 w-5 mr-2" />
        Back to Applications
      </Link>

      <div className="max-w-3xl mx-auto">
        <h1 className="text-3xl font-bold text-gray-900 dark:text-white mb-8">Submit Application for Customer</h1>

        <div className="bg-white dark:bg-gray-800 shadow rounded-lg p-6 mb-6">
          <div className="space-y-6">
            {!selectedCustomer ? (
              <div>
                <label htmlFor="search" className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                  Search Customer by Email
                </label>
                <div className="relative">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <Search className="h-5 w-5 text-gray-400" />
                  </div>
                  <input
                    type="email"
                    id="search"
                    className="pl-10 mt-1 block w-full rounded-md border-gray-300 dark:border-gray-600 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 bg-gray-50 dark:bg-gray-700 text-gray-900 dark:text-white h-12"
                    placeholder="Enter customer email..."
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                  />
                </div>
                {loading && (
                  <div className="flex justify-center py-4">
                    <div className="animate-spin rounded-full h-6 w-6 border-t-2 border-b-2 border-indigo-600"></div>
                  </div>
                )}
                {searchResults.length > 0 && (
                  <div className="mt-2 bg-white dark:bg-gray-700 shadow-lg rounded-md overflow-hidden">
                    {searchResults.map((customer) => (
                      <button
                        key={customer.id}
                        onClick={() => handleCustomerSelect(customer)}
                        className="w-full px-4 py-3 text-left hover:bg-gray-50 dark:hover:bg-gray-600 border-b border-gray-100 dark:border-gray-600 last:border-0"
                      >
                        <div className="flex justify-between items-center">
                          <div>
                            <p className="font-medium text-gray-900 dark:text-white">
                              {customer.firstName} {customer.lastName}
                            </p>
                            <p className="text-sm text-gray-500 dark:text-gray-400">{customer.email}</p>
                          </div>
                          <div className="text-sm text-gray-500 dark:text-gray-400">
                            {customer.credits} CP
                          </div>
                        </div>
                      </button>
                    ))}
                  </div>
                )}
              </div>
            ) : (
              <div className="flex justify-between items-center bg-gray-50 dark:bg-gray-700 p-4 rounded-lg">
                <div>
                  <p className="font-medium text-gray-900 dark:text-white">
                    {selectedCustomer.firstName} {selectedCustomer.lastName}
                  </p>
                  <p className="text-sm text-gray-500 dark:text-gray-400">{selectedCustomer.email}</p>
                </div>
                <div className="flex items-center">
                  <span className="text-sm text-gray-500 dark:text-gray-400 mr-4">
                    {selectedCustomer.credits} CP
                  </span>
                  <button
                    onClick={() => {
                      setSelectedCustomer(null);
                      setProfiles([]);
                      setSelectedProfileId('');
                    }}
                    className="text-sm text-indigo-600 dark:text-indigo-400 hover:text-indigo-500"
                  >
                    Change Customer
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>

        {selectedCustomer && (
          <form onSubmit={handleSubmit} className="space-y-6">
            <div className="bg-white dark:bg-gray-800 shadow rounded-lg p-6">
              <div className="space-y-6">
                <div>
                  <label htmlFor="profile" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                    Select Resume
                  </label>
                  <select
                    id="profile"
                    required
                    className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 dark:border-gray-600 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 rounded-md bg-gray-50 dark:bg-gray-700 text-gray-900 dark:text-white"
                    value={selectedProfileId}
                    onChange={(e) => setSelectedProfileId(e.target.value)}
                  >
                    <option value="">Choose a resume</option>
                    {profiles.map((profile) => (
                      <option key={profile.id} value={profile.id}>
                        {profile.profileName}
                      </option>
                    ))}
                  </select>
                </div>

                <div>
                  <label htmlFor="jobUrl" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                    Job URL
                  </label>
                  <input
                    type="url"
                    id="jobUrl"
                    required
                    className="mt-1 px-4 h-12 block w-full rounded-md border-gray-300 dark:border-gray-600 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 bg-gray-50 dark:bg-gray-700 text-gray-900 dark:text-white"
                    value={formData.jobUrl}
                    onChange={(e) => setFormData({ ...formData, jobUrl: e.target.value })}
                  />
                </div>

                <div>
                  <label htmlFor="company" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                    Company Name
                  </label>
                  <input
                    type="text"
                    id="company"
                    required
                    className="mt-1 px-4 h-12 block w-full rounded-md border-gray-300 dark:border-gray-600 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 bg-gray-50 dark:bg-gray-700 text-gray-900 dark:text-white"
                    value={formData.company}
                    onChange={(e) => setFormData({ ...formData, company: e.target.value })}
                  />
                </div>

                <div>
                  <label htmlFor="position" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                    Position Title
                  </label>
                  <input
                    type="text"
                    id="position"
                    required
                    className="mt-1 px-4 h-12 block w-full rounded-md border-gray-300 dark:border-gray-600 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 bg-gray-50 dark:bg-gray-700 text-gray-900 dark:text-white"
                    value={formData.position}
                    onChange={(e) => setFormData({ ...formData, position: e.target.value })}
                  />
                </div>

                <div>
                  <label htmlFor="notes" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                    Additional Notes
                  </label>
                  <textarea
                    id="notes"
                    rows={4}
                    className="mt-1 px-4 py-3 block w-full rounded-md border-gray-300 dark:border-gray-600 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 bg-gray-50 dark:bg-gray-700 text-gray-900 dark:text-white"
                    value={formData.notes}
                    onChange={(e) => setFormData({ ...formData, notes: e.target.value })}
                    placeholder="Add any specific requirements or notes for the agent handling the application..."
                  />
                </div>
              </div>
            </div>

            <div className="flex justify-between items-center">
              <p className="text-sm text-gray-500 dark:text-gray-400">
                This will use 3 credits from customer's balance
              </p>
              <div className="flex space-x-3">
                <button
                  type="button"
                  onClick={() => navigate('/admin/applications')}
                  className="px-4 py-2 text-sm font-medium text-gray-700 dark:text-gray-300 hover:text-gray-500 dark:hover:text-gray-400"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  disabled={submitting || !selectedProfileId || selectedCustomer.credits < 3}
                  className="px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 dark:bg-indigo-500 dark:hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50"
                >
                  {submitting ? 'Submitting...' : 'Submit Application'}
                </button>
              </div>
            </div>
          </form>
        )}
      </div>
    </div>
  );
} 