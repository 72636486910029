import { Check, X } from 'lucide-react';
import { Link } from 'react-router-dom';

const tiers = [
  {
    name: 'Free',
    price: 0,
    credits: 15,
    description: 'Perfect for trying out our service.',
    features: [
      '15 Application Credits',
      '5 Applications',
      { text: 'Basic Agent Processing', color: 'amber' },
      { text: 'Support', included: false }
    ]
  },
  {
    name: 'Plus',
    price: 12,
    credits: 150,
    description: 'Most popular for active job seekers.',
    features: [
      '150 Application Credits',
      '50 Applications',
      'Priority Agent Processing',
      { text: 'Support', included: true }
    ]
  },
  {
    name: 'Pro',
    price: 25,
    credits: 375,
    description: 'Best value for serious job hunters.',
    features: [
      '375 Application Credits',
      '125 Applications',
      'VIP Agent Processing',
      { text: 'Priority Support', included: true }
    ]
  }
];

export function Pricing() {
  return (
    <div className="bg-gray-50 dark:bg-gray-900">
      <div className="max-w-7xl mx-auto py-24 px-4 sm:px-6 lg:px-8">
        <div className="sm:flex sm:flex-col sm:align-center">
          <h1 className="text-5xl font-extrabold text-gray-900 dark:text-white sm:text-center">
            Simple, Transparent Pricing
          </h1>
          <p className="mt-5 text-xl text-gray-500 dark:text-gray-400 sm:text-center">
            Buy Application Credits for your job search journey. These are one time payments.
          </p>
        </div>
        <div className="mt-12 space-y-4 sm:mt-16 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-8 lg:max-w-5xl lg:mx-auto xl:max-w-6xl xl:mx-auto xl:grid-cols-3 xl:gap-12">
          {tiers.map((tier) => (
            <div
              key={tier.name}
              className="border border-gray-200 dark:border-gray-700 rounded-lg shadow-sm divide-y divide-gray-200 dark:divide-gray-700 bg-white dark:bg-gray-800 max-w-sm mx-auto w-full"
            >
              <div className="p-6">
                <h2 className="text-2xl font-semibold leading-6 text-gray-900 dark:text-white">
                  {tier.name}
                </h2>
                <p className="mt-4 text-sm text-gray-500 dark:text-gray-400">
                  {tier.description}
                </p>
                <p className="mt-8">
                  <span className="text-4xl font-extrabold text-gray-900 dark:text-white">
                    ${tier.price}
                  </span>
                  <span className="text-gray-500 dark:text-gray-500 ml-2">USD</span>
                </p>
                <p className="mt-1 text-base text-gray-600 dark:text-gray-400">
                  {tier.credits} Application Credits
                </p>
                <p className="mt-2 text-sm font-semibold text-green-600 dark:text-green-400">
                  ${(tier.price / tier.credits).toFixed(3)} per credit point
                </p>
                <Link
                  to="/register"
                  className="mt-8 block w-full bg-indigo-600 dark:bg-indigo-500 hover:bg-indigo-700 dark:hover:bg-indigo-600 text-white font-medium py-2 px-4 rounded-md text-center"
                >
                  Get Started
                </Link>
              </div>
              <div className="pt-6 pb-8 px-6">
                <h3 className="text-sm font-medium text-gray-900 dark:text-white tracking-wide uppercase">
                  What's included
                </h3>
                <ul className="mt-6 space-y-4">
                  {tier.features.map((feature) => (
                    <li key={typeof feature === 'string' ? feature : feature.text} className="flex space-x-3">
                      {typeof feature === 'string' ? (
                        <Check className="flex-shrink-0 h-5 w-5 text-green-500" />
                      ) : feature.color === 'amber' ? (
                        <Check className="flex-shrink-0 h-5 w-5 text-amber-500" />
                      ) : feature.included ? (
                        <Check className="flex-shrink-0 h-5 w-5 text-green-500" />
                      ) : (
                        <X className="flex-shrink-0 h-5 w-5 text-red-500" />
                      )}
                      <span className="text-sm text-gray-500 dark:text-gray-400">
                        {typeof feature === 'string' ? feature : feature.text}
                      </span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </div>
        <div className="mt-16 sm:text-center">
          <p className="text-lg text-gray-600 dark:text-gray-400 mb-4">
            <strong>Note:</strong> Each application submission costs 3 Application Credits.
          </p>
          <p className="text-gray-500 dark:text-gray-400">
            Need a custom plan? {' '}
            <Link to="/contact" className="text-indigo-600 dark:text-indigo-400 hover:underline">
              Contact us
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
} 