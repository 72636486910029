import { Pencil, CheckCircle2, Linkedin } from 'lucide-react';
import { Spinner } from '../ui/Spinner';

interface ProfileFormProps {
  firstName: string;
  lastName: string;
  email: string | null | undefined;
  phoneNumber: string | null;
  linkedinUrl: string | null;
  isPhoneVerified: boolean;
  isVerified: boolean;
  editMode: boolean;
  loading: boolean;
  onEdit: () => void;
  onCancel: () => void;
  onSubmit: (e: React.FormEvent) => Promise<void>;
  onChange: (field: 'firstName' | 'lastName' | 'linkedinUrl', value: string) => void;
  onVerify: () => Promise<void>;
}

export function ProfileForm({
  firstName,
  lastName,
  email,
  phoneNumber,
  linkedinUrl,
  isPhoneVerified,
  isVerified,
  editMode,
  loading,
  onEdit,
  onCancel,
  onSubmit,
  onChange,
  onVerify
}: ProfileFormProps) {
  return (
    <div className="bg-white dark:bg-gray-800 shadow rounded-lg p-6">
      <div className="flex items-center justify-between mb-6">
        <h2 className="text-xl font-semibold text-gray-900 dark:text-white">Personal Information</h2>
        {!editMode && (
          <button
            type="button"
            onClick={onEdit}
            className="inline-flex items-center px-4 py-2 rounded-md text-sm font-medium text-indigo-600 dark:text-indigo-400 hover:bg-indigo-50 dark:hover:bg-gray-700 transition-colors duration-200"
          >
            Edit Profile
            <Pencil className="ml-2 h-4 w-4" />
          </button>
        )}
      </div>

      <form onSubmit={onSubmit} className="space-y-4">
        <div className="grid grid-cols-2 gap-4">
          <div>
            <label htmlFor="firstName" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
              First Name
            </label>
            <input
              type="text"
              id="firstName"
              disabled={!editMode}
              className={`mt-1 px-4 h-12 block w-full rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 
                ${editMode 
                  ? 'bg-white dark:bg-gray-700 border-gray-300 dark:border-gray-600 text-gray-900 dark:text-white' 
                  : 'bg-gray-100 dark:bg-gray-700 border-transparent text-gray-900 dark:text-white'}`}
              value={firstName}
              onChange={(e) => onChange('firstName', e.target.value)}
            />
          </div>
          <div>
            <label htmlFor="lastName" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
              Last Name
            </label>
            <input
              type="text"
              id="lastName"
              disabled={!editMode}
              className={`mt-1 px-4 h-12 block w-full rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 
                ${editMode 
                  ? 'bg-white dark:bg-gray-700 border-gray-300 dark:border-gray-600 text-gray-900 dark:text-white' 
                  : 'bg-gray-100 dark:bg-gray-700 border-transparent text-gray-900 dark:text-white'}`}
              value={lastName}
              onChange={(e) => onChange('lastName', e.target.value)}
            />
          </div>
        </div>

        <div>
          <label htmlFor="email" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
            Email
          </label>
          <div className="mt-1 flex items-center">
            <input
              type="email"
              id="email"
              disabled
              className="px-4 h-12 block w-full rounded-md border-transparent bg-gray-100 dark:bg-gray-700 text-gray-900 dark:text-white"
              value={email || ''}
            />
            {isVerified ? (
              <CheckCircle2 className="h-5 w-5 text-green-500 ml-2" />
            ) : (
              <button
                type="button"
                onClick={onVerify}
                className="ml-2 inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Verify
              </button>
            )}
          </div>
        </div>

        <div>
          <label htmlFor="phone" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
            Phone Number
          </label>
          <div className="mt-1 flex items-center">
            <input
              type="tel"
              id="phone"
              disabled
              className="px-4 h-12 block w-full rounded-md border-transparent bg-gray-100 dark:bg-gray-700 text-gray-900 dark:text-white"
              value={phoneNumber || ''}
            />
            {isPhoneVerified && (
              <CheckCircle2 className="h-5 w-5 text-green-500 ml-2" />
            )}
          </div>
        </div>

        <div>
          <label htmlFor="linkedin" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
            LinkedIn Profile
          </label>
          <div className="mt-1 flex items-center">
            <div className="relative flex-grow">
              <input
                type="text"
                id="linkedin"
                placeholder="linkedin.com/in/your-profile"
                disabled={!editMode}
                className={`pl-10 pr-4 h-12 block w-full rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 
                  ${editMode 
                    ? 'bg-white dark:bg-gray-700 border-gray-300 dark:border-gray-600 text-gray-900 dark:text-white' 
                    : 'bg-gray-100 dark:bg-gray-700 border-transparent text-gray-900 dark:text-white'}`}
                value={linkedinUrl || ''}
                onChange={(e) => {
                  // Remove any spaces from the input
                  const value = e.target.value.trim();
                  onChange('linkedinUrl', value);
                }}
                pattern="^(https?:\/\/)?([\w]+\.)?linkedin\.com\/in\/[\w\-\_]+\/?$"
                title="Please enter a valid LinkedIn profile URL (e.g., linkedin.com/in/your-profile)"
              />
              <Linkedin className="absolute left-3 top-3.5 h-5 w-5 text-gray-400" />
            </div>
            {linkedinUrl && !editMode && (
              <a
                href={linkedinUrl.startsWith('http') ? linkedinUrl : `https://${linkedinUrl}`}
                target="_blank"
                rel="noopener noreferrer"
                className="ml-2 inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-white bg-[#0A66C2] hover:bg-[#004182] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#0A66C2]"
              >
                View Profile
              </a>
            )}
          </div>
        </div>

        {editMode && (
          <div className="flex justify-end space-x-3 mt-6">
            <button
              type="button"
              onClick={onCancel}
              disabled={loading}
              className="px-4 py-2 border border-gray-300 dark:border-gray-600 rounded-md shadow-sm text-sm font-medium text-gray-700 dark:text-gray-300 bg-white dark:bg-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600 disabled:opacity-50"
            >
              Cancel
            </button>
            <button
              type="submit"
              disabled={loading}
              className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 disabled:opacity-50 space-x-2"
            >
              {loading ? (
                <>
                  <Spinner size="sm" className="border-2" />
                  <span>Saving...</span>
                </>
              ) : (
                <span>Save Changes</span>
              )}
            </button>
          </div>
        )}
      </form>
    </div>
  );
}