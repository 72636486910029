import { useState, useEffect } from 'react';
import { useAuth } from '../../hooks/useAuth';
import { useCustomerStats } from '../../hooks/useCustomerStats';
import { FileText, Clock, AlertTriangle, CheckCircle, Activity } from 'lucide-react';
import { GiTwoCoins } from 'react-icons/gi';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { EmailVerificationBanner } from '../EmailVerificationBanner';
import { collection, query, where, getDocs, getDoc, doc } from 'firebase/firestore';
import { db } from '../../lib/firebase';
import { useApplications } from '../../hooks/useApplications';
import { Application } from '../../types/application';
import { Plus, Calendar, CheckSquare, X, BarChart } from 'lucide-react';
import { PhoneVerificationBanner } from '../PhoneVerificationBanner';
import toast from 'react-hot-toast';

export function CustomerDashboard() {
  const { user, userProfile } = useAuth();
  const { stats, loading } = useCustomerStats();
  const { fetchUserApplications } = useApplications();
  const navigate = useNavigate();
  const [jobProfilesCount, setJobProfilesCount] = useState(0);
  const [applications, setApplications] = useState<Application[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [hasCompletedOnboarding, setHasCompletedOnboarding] = useState(false);
  const [checkingOnboarding, setCheckingOnboarding] = useState(true);

  useEffect(() => {
    if (user) {
      checkOnboardingStatus();
      loadJobProfilesCount();
      loadApplications();
    }
  }, [user]);

  const checkOnboardingStatus = async () => {
    if (!user) return;
    
    try {
      setCheckingOnboarding(true);
      
      // Check step 1: General Application
      // Get the user document to check userGeneralApplications
      const userDoc = await getDoc(doc(db, 'users', user.uid));
      if (!userDoc.exists()) {
        console.log('User document not found');
        setHasCompletedOnboarding(false);
        sessionStorage.setItem('onboardingStatus', JSON.stringify({
          generalApp: false,
          jobProfile: false,
          application: false
        }));
        navigate('/todo');
        return;
      }
      
      const userData = userDoc.data();
      const userGeneralApps = userData?.userGeneralApplications || {};
      
      // Check if there's at least one complete general application in the references
      // Add safeguards for null/undefined objects
      const hasGeneralApp = Object.values(userGeneralApps).some(
        (app: any) => app && typeof app === 'object' && app.isComplete === true
      );

      // Check step 2: Job Profile
      const profilesQuery = query(
        collection(db, 'jobProfiles'),
        where('userId', '==', user.uid)
      );
      const profilesSnapshot = await getDocs(profilesQuery);
      const hasJobProfile = !profilesSnapshot.empty;

      // Check step 3: Submitted Application
      const applicationsQuery = query(
        collection(db, 'applications'),
        where('userId', '==', user.uid)
      );
      const applicationsSnapshot = await getDocs(applicationsQuery);
      const hasSubmittedApp = !applicationsSnapshot.empty;

      // Check if all steps are completed
      const completed = hasGeneralApp && hasJobProfile && hasSubmittedApp;
      setHasCompletedOnboarding(completed);
      
      // Clear any onboarding flag as we're checking status afresh
      sessionStorage.removeItem('onboardingFlow');
      
      // Redirect to todo page if steps are not completed
      if (!completed) {
        // Save the completion state in session storage for the todo page
        sessionStorage.setItem('onboardingStatus', JSON.stringify({
          generalApp: hasGeneralApp,
          jobProfile: hasJobProfile,
          application: hasSubmittedApp
        }));
        navigate('/todo');
        return; // Exit early since we're redirecting
      }
    } catch (error) {
      console.error('Error checking onboarding status:', error);
      // Safely continue even if there's an error checking onboarding status
      setHasCompletedOnboarding(false);
      setIsLoading(false);
    } finally {
      setCheckingOnboarding(false);
      setIsLoading(false);
    }
  };

  const loadJobProfilesCount = async () => {
    try {
      const q = query(
        collection(db, 'jobProfiles'),
        where('userId', '==', user?.uid)
      );
      const snapshot = await getDocs(q);
      setJobProfilesCount(snapshot.size);
    } catch (error) {
      console.error('Error loading job profiles count:', error);
      setJobProfilesCount(0);
    }
  };

  const loadApplications = async () => {
    if (!user) return;

    try {
      setIsLoading(true);
      const apps = await fetchUserApplications();
      
      // Sort applications by last update, most recent first
      const sortedApps = [...apps].sort((a, b) => 
        new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
      );
      
      setApplications(sortedApps);
    } catch (error: any) {
      console.error('Error loading applications:', error);
      toast.error('Failed to load applications. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const formatTime = (ms: number) => {
    const hours = Math.floor(ms / (1000 * 60 * 60));
    if (hours < 24) return `${hours} hours`;
    const days = Math.floor(hours / 24);
    return `${days} days`;
  };

  // Calculate year-to-date and all-time application counts
  const calculateYearToDateCount = () => {
    const now = new Date();
    const startOfYear = new Date(now.getFullYear(), 0, 1); // January 1st of current year
    return applications.filter(app => new Date(app.createdAt) >= startOfYear).length;
  };

  const yearToDateCount = calculateYearToDateCount();
  const allTimeCount = applications.length;

  if (!userProfile) return null;

  // Redirect non-customer users to their appropriate dashboards
  if (userProfile.userType !== 'customer') {
    switch (userProfile.userType) {
      case 'admin':
        return <Navigate to="/admin/dashboard" />;
      case 'agent':
        return <Navigate to="/agent/dashboard" />;
    }
  }

  // Show phone verification if phone is not verified
  if (!userProfile.isPhoneVerified) {
    return (
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12 bg-gray-50 dark:bg-gray-900 min-h-screen">
        <PhoneVerificationBanner />
      </div>
    );
  }

  // Show loading spinner while checking onboarding status
  if (checkingOnboarding) {
    return (
      <div className="flex justify-center items-center min-h-screen bg-gray-50 dark:bg-gray-900">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-indigo-500"></div>
      </div>
    );
  }

  // If onboarding is not complete, redirect to todo page (handled in useEffect)
  if (!hasCompletedOnboarding) {
    return null;
  }

  // Calculate percentages for application statuses
  const submittedCount = applications.filter(app => app.status === 'submitted').length;
  const inProgressCount = applications.filter(app => app.status === 'in_progress').length;
  const pendingCount = applications.filter(app => app.status === 'pending').length;
  const completedCount = applications.filter(app => app.status === 'completed').length;
  const cancelledCount = applications.filter(app => app.status === 'cancelled').length;
  const totalCount = applications.length;

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12 bg-gray-50 dark:bg-gray-900 min-h-screen">
      {!user?.emailVerified && <EmailVerificationBanner />}
      
      <div className="mb-8">
        <div className="flex justify-between items-center">
          <div>
            <h1 className="text-3xl font-bold text-gray-900 dark:text-white">
              Welcome, {userProfile.firstName}!
            </h1>
            <p className="mt-2 text-gray-600 dark:text-gray-400">
              Track your applications and manage your job search journey.
            </p>
          </div>
          
          <div className="flex items-center space-x-3">
            {/* New Application Button - Desktop only */}
            <Link
              to="/submit-application"
              className="hidden sm:inline-flex items-center px-4 py-2 border border-transparent rounded-md text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 transition-colors duration-200"
            >
              <Plus className="h-4 w-4 mr-1.5" />
              Start New Application
            </Link>
            
            {/* Credits Indicator */}
            <Link
              to="/credits"
              className="inline-flex items-center px-4 py-2 border border-amber-200 dark:border-amber-800 rounded-lg bg-amber-100 dark:bg-amber-900/30 hover:bg-amber-200 dark:hover:bg-amber-800/40 transition-colors"
            >
              <GiTwoCoins className="h-5 w-5 text-amber-500 dark:text-amber-400 mr-2" />
              <span className="text-amber-700 dark:text-amber-300 font-medium">
                {userProfile?.credits || 0} Credits
              </span>
            </Link>
          </div>
        </div>
        
        {/* Mobile CTA Banner - Only visible on mobile */}
        <div className="block sm:hidden mt-6 bg-indigo-50 dark:bg-indigo-900/20 border border-indigo-100 dark:border-indigo-800 shadow rounded-lg p-4 text-center">
          <h3 className="text-base font-medium text-indigo-900 dark:text-indigo-200 mb-2">Ready to apply for more jobs?</h3>
          <p className="text-sm text-indigo-700 dark:text-indigo-300 mb-3">Create a new application and let our agents Blitz Apply for you</p>
          <Link
            to="/submit-application"
            className="inline-flex items-center px-3 py-1.5 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 dark:bg-indigo-500 dark:hover:bg-indigo-600"
          >
            <Plus className="h-4 w-4 mr-1.5" />
            Start New Application
          </Link>
        </div>
      </div>

      {loading || isLoading ? (
        <div className="flex justify-center py-12">
          <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-indigo-600 dark:border-indigo-400"></div>
        </div>
      ) : (
        <div className="space-y-8">
          {/* Customer Info Section */}
          <div>
            <h2 className="text-xl font-bold text-gray-900 dark:text-white mb-4">
              Customer Info
            </h2>
            <div className="grid grid-cols-1 gap-6 md:grid-cols-3 lg:grid-cols-5">
              {/* Left side: 2x2 grid of cards */}
              <div className="col-span-1 md:col-span-2 lg:col-span-3 grid grid-cols-1 sm:grid-cols-2 gap-6">
                {/* Job Profiles - Entire card clickable */}
                <Link 
                  to="/job-profiles"
                  className="bg-white dark:bg-gray-800 shadow rounded-lg overflow-hidden hover:shadow-md transition-all duration-200 hover:bg-indigo-50 dark:hover:bg-indigo-900/20"
                  title="View and manage your saved job profiles"
                >
                  <div className="p-5">
                    <div className="flex items-center">
                      <div className="flex-shrink-0">
                        <FileText className="h-6 w-6 text-indigo-600 dark:text-indigo-400" />
                      </div>
                      <div className="ml-5">
                        <h3 className="text-lg font-medium text-gray-900 dark:text-white">Job Profiles</h3>
                        <p className="text-3xl font-semibold text-indigo-600 dark:text-indigo-400">
                          {jobProfilesCount}
                        </p>
                        <span className="text-sm text-indigo-600 hover:text-indigo-500 dark:text-indigo-400 dark:hover:text-indigo-300 font-medium">
                          View job profiles →
                        </span>
                      </div>
                    </div>
                  </div>
                </Link>

                {/* Application Credits - Entire card clickable */}
                <Link
                  to="/credits"
                  className="bg-white dark:bg-gray-800 shadow rounded-lg overflow-hidden hover:shadow-md transition-all duration-200 hover:bg-amber-50 dark:hover:bg-amber-900/20"
                  title="View and purchase application credits"
                >
                  <div className="p-5">
                    <div className="flex items-center">
                      <div className="flex-shrink-0">
                        <GiTwoCoins className="h-6 w-6 text-amber-500 dark:text-amber-400" />
                      </div>
                      <div className="ml-5">
                        <h3 className="text-lg font-medium text-gray-900 dark:text-white">Application Credits</h3>
                        <p className="text-3xl font-semibold text-amber-500 dark:text-amber-400">
                          {userProfile?.credits || 0}
                        </p>
                        <span className="text-sm text-amber-600 hover:text-amber-500 dark:text-amber-400 dark:hover:text-amber-300 font-medium">
                          Buy more credits →
                        </span>
                      </div>
                    </div>
                  </div>
                </Link>

                {/* Success Rate Card */}
                <div 
                  className="bg-white dark:bg-gray-800 shadow rounded-lg overflow-hidden hover:shadow-md transition-all duration-200 hover:bg-green-50 dark:hover:bg-green-900/20"
                  title="Percentage of completed applications vs. cancelled applications"
                >
                  <div className="p-5">
                    <div className="flex items-center">
                      <div className="flex-shrink-0">
                        <CheckCircle className="h-6 w-6 text-green-600 dark:text-green-400" />
                      </div>
                      <div className="ml-5">
                        <h3 className="text-lg font-medium text-gray-900 dark:text-white">Success Rate</h3>
                        <p className="text-3xl font-semibold text-green-600 dark:text-green-400">
                          {Math.round(stats.successRate)}%
                        </p>
                        <span className="text-sm text-gray-600 dark:text-gray-400">
                          Completed vs. Cancelled
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Average Fulfillment Time Card */}
                <div 
                  className="bg-white dark:bg-gray-800 shadow rounded-lg overflow-hidden hover:shadow-md transition-all duration-200 hover:bg-blue-50 dark:hover:bg-blue-900/20"
                  title="Average time taken to complete your applications"
                >
                  <div className="p-5">
                    <div className="flex items-center">
                      <div className="flex-shrink-0">
                        <Clock className="h-6 w-6 text-blue-600 dark:text-blue-400" />
                      </div>
                      <div className="ml-5">
                        <h3 className="text-lg font-medium text-gray-900 dark:text-white">Avg. Fulfillment Time</h3>
                        <p className="text-3xl font-semibold text-blue-600 dark:text-blue-400">
                          {formatTime(stats.averageCompletionTime)}
                        </p>
                        <span className="text-sm text-gray-600 dark:text-gray-400">
                          For completed applications
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Right side: Application Statistics */}
              <div className="col-span-1 md:col-span-1 lg:col-span-2">
                <div className="bg-white dark:bg-gray-800 shadow rounded-lg overflow-hidden hover:shadow-md transition-all duration-200 hover:bg-gray-50 dark:hover:bg-gray-700/50 h-full">
                  <div className="p-5">
                    <h3 className="text-lg font-medium text-gray-900 dark:text-white mb-4">Application Statistics</h3>
                    <div className="space-y-4">
                      <div className="flex justify-between items-center">
                        <div className="flex items-center">
                          <Calendar className="h-5 w-5 text-gray-400 dark:text-gray-500 mr-2" />
                          <span className="text-sm text-gray-700 dark:text-gray-300">Today</span>
                        </div>
                        <span className="text-lg font-semibold text-gray-900 dark:text-white">{stats.recentActivity.today}</span>
                      </div>
                      <div className="flex justify-between items-center">
                        <div className="flex items-center">
                          <Calendar className="h-5 w-5 text-gray-400 dark:text-gray-500 mr-2" />
                          <span className="text-sm text-gray-700 dark:text-gray-300">This Week</span>
                        </div>
                        <span className="text-lg font-semibold text-gray-900 dark:text-white">{stats.recentActivity.week}</span>
                      </div>
                      <div className="flex justify-between items-center">
                        <div className="flex items-center">
                          <Calendar className="h-5 w-5 text-gray-400 dark:text-gray-500 mr-2" />
                          <span className="text-sm text-gray-700 dark:text-gray-300">This Month</span>
                        </div>
                        <span className="text-lg font-semibold text-gray-900 dark:text-white">{stats.recentActivity.month}</span>
                      </div>
                      <div className="flex justify-between items-center">
                        <div className="flex items-center">
                          <Calendar className="h-5 w-5 text-gray-400 dark:text-gray-500 mr-2" />
                          <span className="text-sm text-gray-700 dark:text-gray-300">Year to Date</span>
                        </div>
                        <span className="text-lg font-semibold text-gray-900 dark:text-white">{yearToDateCount}</span>
                      </div>
                      <div className="flex justify-between items-center">
                        <div className="flex items-center">
                          <Calendar className="h-5 w-5 text-gray-400 dark:text-gray-500 mr-2" />
                          <span className="text-sm text-gray-700 dark:text-gray-300">All Time</span>
                        </div>
                        <span className="text-lg font-semibold text-gray-900 dark:text-white">{allTimeCount}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Applications Section */}
          <div>
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-xl font-bold text-gray-900 dark:text-white">
                Applications
              </h2>
              <Link 
                to="/completions"
                className="text-sm text-indigo-600 hover:text-indigo-500 dark:text-indigo-400 dark:hover:text-indigo-300 font-medium inline-flex items-center"
              >
                View all applications →
              </Link>
            </div>
            <div className="grid grid-cols-2 gap-6 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-6">
              {/* Total Applications - Now First */}
              <Link 
                to="/completions" 
                className="bg-white dark:bg-gray-800 shadow rounded-lg p-5 hover:shadow-md transition-all duration-200 hover:bg-indigo-50 dark:hover:bg-indigo-900/30"
                title="View all your applications"
              >
                <div className="flex flex-col items-center">
                  <div className="rounded-full bg-indigo-100 dark:bg-indigo-900/30 p-3 mb-2">
                    <BarChart className="h-6 w-6 text-indigo-600 dark:text-indigo-400" />
                  </div>
                  <h3 className="text-base font-medium text-gray-700 dark:text-gray-300">Total</h3>
                  <p className="text-2xl font-semibold text-gray-900 dark:text-white mt-1">{totalCount}</p>
                </div>
              </Link>

              {/* Submitted Applications */}
              <Link 
                to="/completions?status=submitted" 
                className="bg-white dark:bg-gray-800 shadow rounded-lg p-5 hover:shadow-md transition-all duration-200 hover:bg-blue-50 dark:hover:bg-blue-900/30"
                title="Applications that have been submitted and are awaiting agent assignment"
              >
                <div className="flex flex-col items-center">
                  <div className="rounded-full bg-blue-100 dark:bg-blue-900/30 p-3 mb-2">
                    <Activity className="h-6 w-6 text-blue-600 dark:text-blue-400" />
                  </div>
                  <h3 className="text-base font-medium text-gray-700 dark:text-gray-300">Submitted</h3>
                  <p className="text-2xl font-semibold text-gray-900 dark:text-white mt-1">{submittedCount}</p>
                </div>
              </Link>

              {/* In Progress Applications */}
              <Link 
                to="/completions?status=in_progress" 
                className="bg-white dark:bg-gray-800 shadow rounded-lg p-5 hover:shadow-md transition-all duration-200 hover:bg-yellow-50 dark:hover:bg-yellow-900/30"
                title="Applications that agents are currently working on"
              >
                <div className="flex flex-col items-center">
                  <div className="rounded-full bg-yellow-100 dark:bg-yellow-900/30 p-3 mb-2">
                    <Clock className="h-6 w-6 text-yellow-600 dark:text-yellow-400" />
                  </div>
                  <h3 className="text-base font-medium text-gray-700 dark:text-gray-300">In Progress</h3>
                  <p className="text-2xl font-semibold text-gray-900 dark:text-white mt-1">{inProgressCount}</p>
                </div>
              </Link>

              {/* Pending Applications */}
              <Link 
                to="/completions?status=pending" 
                className="bg-white dark:bg-gray-800 shadow rounded-lg p-5 hover:shadow-md transition-all duration-200 hover:bg-orange-50 dark:hover:bg-orange-900/30"
                title="Applications waiting for your input or additional information"
              >
                <div className="flex flex-col items-center">
                  <div className="rounded-full bg-orange-100 dark:bg-orange-900/30 p-3 mb-2">
                    <AlertTriangle className="h-6 w-6 text-orange-600 dark:text-orange-400" />
                  </div>
                  <h3 className="text-base font-medium text-gray-700 dark:text-gray-300">Pending</h3>
                  <p className="text-2xl font-semibold text-gray-900 dark:text-white mt-1">{pendingCount}</p>
                </div>
              </Link>

              {/* Completed Applications */}
              <Link 
                to="/completions?status=completed" 
                className="bg-white dark:bg-gray-800 shadow rounded-lg p-5 hover:shadow-md transition-all duration-200 hover:bg-green-50 dark:hover:bg-green-900/30"
                title="Applications that have been successfully completed"
              >
                <div className="flex flex-col items-center">
                  <div className="rounded-full bg-green-100 dark:bg-green-900/30 p-3 mb-2">
                    <CheckSquare className="h-6 w-6 text-green-600 dark:text-green-400" />
                  </div>
                  <h3 className="text-base font-medium text-gray-700 dark:text-gray-300">Completed</h3>
                  <p className="text-2xl font-semibold text-gray-900 dark:text-white mt-1">{completedCount}</p>
                </div>
              </Link>

              {/* Cancelled Applications */}
              <Link 
                to="/completions?status=cancelled" 
                className="bg-white dark:bg-gray-800 shadow rounded-lg p-5 hover:shadow-md transition-all duration-200 hover:bg-red-50 dark:hover:bg-red-900/30"
                title="Applications that have been cancelled"
              >
                <div className="flex flex-col items-center">
                  <div className="rounded-full bg-red-100 dark:bg-red-900/30 p-3 mb-2">
                    <X className="h-6 w-6 text-red-600 dark:text-red-400" />
                  </div>
                  <h3 className="text-base font-medium text-gray-700 dark:text-gray-300">Cancelled</h3>
                  <p className="text-2xl font-semibold text-gray-900 dark:text-white mt-1">{cancelledCount}</p>
                </div>
              </Link>
            </div>
          </div>

          {/* Add New Application CTA - Hidden on mobile, shown on larger screens */}
          <div className="hidden sm:block bg-indigo-50 dark:bg-indigo-900/20 border border-indigo-100 dark:border-indigo-800 shadow rounded-lg p-6 text-center">
            <h3 className="text-lg font-medium text-indigo-900 dark:text-indigo-200 mb-2">Ready to apply for more jobs?</h3>
            <p className="text-indigo-700 dark:text-indigo-300 mb-4">Create a new application and let our agents Blitz Apply for you</p>
            <Link
              to="/submit-application"
              className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 dark:bg-indigo-500 dark:hover:bg-indigo-600"
            >
              <Plus className="h-5 w-5 mr-2" />
              Start New Application
            </Link>
          </div>
        </div>
      )}
    </div>
  );
}