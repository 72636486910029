import { HelpCircle, FileText, Briefcase, CheckCircle, XCircle, RotateCcw, Clock, MessageCircle, AlertCircle, Send } from 'lucide-react';
import { Link } from 'react-router-dom';

export function HelpPage() {
  return (
    <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8 py-8 bg-gray-50 dark:bg-gray-900 min-h-screen">
      <div className="mb-8">
        <h1 className="text-3xl font-bold text-gray-900 dark:text-white mb-2">Help & Support</h1>
        <p className="text-gray-600 dark:text-gray-400">Learn how to use BlitzApply effectively</p>
      </div>

      <div className="space-y-8">
        {/* Getting Started Section */}
        <section className="bg-white dark:bg-gray-800 shadow rounded-lg p-6">
          <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-4">Getting Started</h2>
          <div className="space-y-4">
            <div>
              <h3 className="text-lg font-medium text-gray-900 dark:text-white mb-2 flex items-center">
                <FileText className="h-5 w-5 mr-2 text-indigo-500" />
                General Application Form
              </h3>
              <p className="text-gray-600 dark:text-gray-400 ml-7">
                Fill out your General Application Form first. This stores your common information like contact details,
                work authorization, and online profiles that can be used across all job applications.
              </p>
            </div>
            <div>
              <h3 className="text-lg font-medium text-gray-900 dark:text-white mb-2 flex items-center">
                <Briefcase className="h-5 w-5 mr-2 text-indigo-500" />
                Job Profiles
              </h3>
              <p className="text-gray-600 dark:text-gray-400 ml-7">
                Create Job Profiles to store different versions of your resume for different types of positions.
                Each profile can have its own resume and customized information.
              </p>
            </div>
          </div>
        </section>

        {/* Application States Section */}
        <section className="bg-white dark:bg-gray-800 shadow rounded-lg p-6">
          <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-4">Application States</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div className="flex items-start space-x-3">
              <Send className="h-5 w-5 text-blue-500 mt-1 flex-shrink-0" />
              <div>
                <h3 className="font-medium text-gray-900 dark:text-white">Submitted</h3>
                <p className="text-sm text-gray-600 dark:text-gray-400">
                  Application submitted. Waiting to be picked up by an agent
                </p>
              </div>
            </div>
            <div className="flex items-start space-x-3">
              <Clock className="h-5 w-5 text-yellow-500 mt-1 flex-shrink-0" />
              <div>
                <h3 className="font-medium text-gray-900 dark:text-white">In Progress</h3>
                <p className="text-sm text-gray-600 dark:text-gray-400">
                  Application is being actively processed by an agent
                </p>
              </div>
            </div>
            <div className="flex items-start space-x-3">
              <AlertCircle className="h-5 w-5 text-orange-500 mt-1 flex-shrink-0" />
              <div>
                <h3 className="font-medium text-gray-900 dark:text-white">Needs Info</h3>
                <p className="text-sm text-gray-600 dark:text-gray-400">
                  Additional Information is required to complete the application
                </p>
              </div>
            </div>
            <div className="flex items-start space-x-3">
              <CheckCircle className="h-5 w-5 text-green-500 mt-1 flex-shrink-0" />
              <div>
                <h3 className="font-medium text-gray-900 dark:text-white">Completed</h3>
                <p className="text-sm text-gray-600 dark:text-gray-400">
                  Application has been successfully submitted
                </p>
              </div>
            </div>
            <div className="flex items-start space-x-3">
              <XCircle className="h-5 w-5 text-red-500 mt-1 flex-shrink-0" />
              <div>
                <h3 className="font-medium text-gray-900 dark:text-white">Cancelled</h3>
                <p className="text-sm text-gray-600 dark:text-gray-400">
                  Application process was cancelled and refunded
                </p>
              </div>
            </div>
          </div>
        </section>

        {/* Tips & Best Practices Section */}
        <section className="bg-white dark:bg-gray-800 shadow rounded-lg p-6">
          <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-4">Tips & Best Practices</h2>
          <ul className="space-y-3 text-gray-600 dark:text-gray-400">
            <li className="flex items-start">
              <span className="font-medium mr-2">•</span>
              Keep your General Application Form up to date with your latest information
            </li>
            <li className="flex items-start">
              <span className="font-medium mr-2">•</span>
              Create different Job Profiles for different types of positions or industries
            </li>
            <li className="flex items-start">
              <span className="font-medium mr-2">•</span>
              Regularly update your resumes to reflect your most recent experience
            </li>
            <li className="flex items-start">
              <span className="font-medium mr-2">•</span>
              Monitor your application status and check for any messages or updates
            </li>
          </ul>
        </section>

        {/* Contact Support Section */}
        <section className="bg-white dark:bg-gray-800 shadow rounded-lg p-6 text-center">
          <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-4">Need Additional Help?</h2>
          <p className="text-gray-600 dark:text-gray-400 mb-6">
            Can't find what you're looking for? Our support team is here to help.
          </p>
          <Link
            to="/contact"
            className="inline-flex items-center px-6 py-3 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700 dark:bg-indigo-500 dark:hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition-colors duration-200"
          >
            <MessageCircle className="h-5 w-5 mr-2" />
            Contact Support
          </Link>
        </section>
      </div>
    </div>
  );
} 