import { Application } from '../../types/application';
import { CheckCircle, Clock, AlertCircle, XCircle, Send, RotateCw } from 'lucide-react';

interface ApplicationStatusBadgeProps {
  status: Application['status'];
}

const STATUS_CONFIG = {
  submitted: {
    icon: Send,
    text: 'Submitted',
    className: 'bg-blue-100 dark:bg-blue-900/50 text-blue-800 dark:text-blue-300',
    tooltip: 'Application submitted. Waiting to be picked up by an agent'
  },
  in_progress: {
    icon: Clock,
    text: 'In Progress',
    className: 'bg-yellow-100 dark:bg-yellow-900/50 text-yellow-800 dark:text-yellow-300',
    tooltip: 'Application is being actively processed by an agent'
  },
  pending: {
    icon: AlertCircle,
    text: 'Needs Info',
    className: 'bg-orange-100 dark:bg-orange-900/50 text-orange-800 dark:text-orange-300',
    tooltip: 'Additional Information is required to complete the application'
  },
  completed: {
    icon: CheckCircle,
    text: 'Completed',
    className: 'bg-green-100 dark:bg-green-900/50 text-green-800 dark:text-green-300',
    tooltip: 'Application has been successfully submitted'
  },
  cancelled: {
    icon: XCircle,
    text: 'Cancelled',
    className: 'bg-red-100 dark:bg-red-900/50 text-red-800 dark:text-red-300',
    tooltip: 'Application process was cancelled and refunded'
  }
} as const;

export function ApplicationStatusBadge({ status }: ApplicationStatusBadgeProps) {
  // Ensure we have a valid status config, fallback to a default if somehow we get an invalid status
  const config = STATUS_CONFIG[status] || {
    icon: AlertCircle,
    text: status,
    className: 'bg-gray-100 dark:bg-gray-800 text-gray-800 dark:text-gray-300',
    tooltip: 'Unknown application status'
  };

  const Icon = config.icon;

  return (
    <span 
      className={`inline-flex items-center px-3 py-1.5 rounded-full text-sm font-medium ${config.className}`}
      title={config.tooltip}
    >
      <Icon className="h-4 w-4 mr-1.5" />
      {config.text}
    </span>
  );
}