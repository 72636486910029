import { ArrowLeft } from 'lucide-react';
import { Application } from '../../../types/application';
import { ApplicationStatusBadge } from '../ApplicationStatusBadge';
import { ApplicationActions } from './ApplicationActions';

interface ApplicationHeaderProps {
  application: Application;
  isCustomer: boolean;
  isAssignedAgent: boolean;
  updating: boolean;
  userType?: string;
  onBack: () => void;
  onCancel: (reason: string) => void;
  onPickUp: () => void;
  onUpdateStatus: (status: 'completed' | 'pending' | 'in_progress', notes?: string, files?: File[]) => void;
  onUnassign?: () => void;
}

export function ApplicationHeader({
  application,
  isCustomer,
  isAssignedAgent,
  updating,
  userType,
  onBack,
  onCancel,
  onPickUp,
  onUpdateStatus,
  onUnassign
}: ApplicationHeaderProps) {
  const getBackText = () => {
    switch (userType) {
      case 'agent':
        return 'Back to Applications';
      case 'admin':
        return 'Back to Applications';
      default:
        return 'Back to Applications';
    }
  };

  return (
    <div className="mb-6 md:mb-8">
      <button
        onClick={onBack}
        className="flex items-center text-gray-600 hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-200 transition-colors text-sm md:text-base"
      >
        <ArrowLeft className="h-4 w-4 md:h-5 md:w-5 mr-1 md:mr-2" />
        {getBackText()}
      </button>
      
      <div className="mt-3 md:mt-4">
        <h1 className="text-xl md:text-3xl font-bold text-gray-900 dark:text-gray-50">
          {application.position}
        </h1>
        <p className="mt-1 md:mt-2 text-base md:text-lg text-gray-600 dark:text-gray-200">
          {application.company}
        </p>
      </div>

      <div className="mt-4 flex flex-col sm:flex-row sm:justify-between sm:items-center gap-3">
        <div className="inline-block">
          <ApplicationStatusBadge status={application.status} />
        </div>
        <div className="w-full sm:w-auto overflow-x-auto pb-1">
          <ApplicationActions
            application={application}
            isCustomer={isCustomer}
            isAssignedAgent={isAssignedAgent}
            userType={userType || 'customer'}
            updating={updating}
            onCancel={onCancel}
            onPickUp={onPickUp}
            onUpdateStatus={onUpdateStatus}
            onUnassign={onUnassign}
          />
        </div>
      </div>
    </div>
  );
}