import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';
import { db, storage, auth } from '../lib/firebase';
import { collection, query, where, getDocs, doc, deleteDoc, updateDoc, getDoc, FieldValue, deleteField } from 'firebase/firestore';
import { ref, deleteObject } from 'firebase/storage';
import { Plus, Pencil, Trash2 } from 'lucide-react';
import { FaRegFilePdf } from 'react-icons/fa6';
import { ConfirmationModal } from '../components/modals/ConfirmationModal';
import toast from 'react-hot-toast';
import { JobProfile } from '../types/jobProfile';
import { GeneralApplication } from '../types/generalApplication';
import { LoadingSpinner } from '../components/LoadingSpinner';
import { Modal } from '../components/Modal';

// Simple retry function with delay
async function retry(fn, maxRetries = 3, delay = 1000) {
  let error;
  
  for (let i = 0; i < maxRetries; i++) {
    try {
      const result = await fn();
      // Explicitly allow null and undefined as valid responses
      return result;
    } catch (err) {
      error = err;
      console.log(`Attempt ${i + 1} failed:`, err);
      
      if (i < maxRetries - 1) {
        await new Promise(r => setTimeout(r, delay));
      }
    }
  }
  
  throw error;
}

export function JobProfiles() {
  const { user, loading: authLoading } = useAuth();
  const navigate = useNavigate();
  
  // Split loading states for different sections
  const [profilesLoading, setProfilesLoading] = useState(true);
  const [generalAppLoading, setGeneralAppLoading] = useState(true);
  const [hasGeneralApp, setHasGeneralApp] = useState<boolean | null>(null);

  const [profiles, setProfiles] = useState<JobProfile[]>([]);
  const [generalApplication, setGeneralApplication] = useState<GeneralApplication | null>(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [profileToDelete, setProfileToDelete] = useState<JobProfile | null>(null);
  const [showDeleteGeneralModal, setShowDeleteGeneralModal] = useState(false);
  const [deleting, setDeleting] = useState<string | null>(null);
  const [deletingGeneral, setDeletingGeneral] = useState(false);

  useEffect(() => {
    // Don't do anything while auth is still loading
    if (authLoading) {
      return;
    }
    
    // If no user, don't try to load data
    if (!user) {
      setProfilesLoading(false);
      setGeneralAppLoading(false);
      return;
    }
    
    // Increase initialization delay to ensure Firebase is fully ready
    // This helps particularly on page refresh when Firebase needs to be reinitialized
    const initDelay = setTimeout(() => {
      // Start loading data
      loadData();
    }, 800); // Longer delay to ensure Firebase is fully initialized
    
    return () => clearTimeout(initDelay);
  }, [user, authLoading]);
  
  // Separate function to load all data
  const loadData = () => {
    // Load profiles first
    loadProfiles();
    // Then check for general application
    checkGeneralApp();
  };
  
  // Load profiles
  const loadProfiles = async () => {
    try {
      await fetchProfiles();
    } catch (error) {
      console.error('Error loading profiles:', error);
      if (isPermissionError(error)) {
        toast.error('Permission error loading resumes. Please check your connection and try again.');
      } else {
        toast.error('Failed to load resumes. Please refresh the page.');
      }
    } finally {
      setProfilesLoading(false);
    }
  };
  
  // Check if user has a general application
  const checkGeneralApp = async () => {
    try {
      // Safety check - don't proceed if user is not available
      if (!user) {
        setHasGeneralApp(false);
        setGeneralAppLoading(false);
        return;
      }
      
      // Quick check if user has a general application
      const userRef = doc(db, 'users', user.uid);
      const userDoc = await getDoc(userRef);
      
      if (userDoc.exists()) {
        const userData = userDoc.data();
        const userGeneralApps = userData?.userGeneralApplications || {};
        
        // Filter out null values if any (could happen after deletion)
        const validGeneralAppIds = Object.entries(userGeneralApps)
          .filter(([_, value]) => value !== null)
          .map(([key]) => key);
        
        const hasApp = validGeneralAppIds.length > 0;
        setHasGeneralApp(hasApp);
        
        // If user has a general application, load it
        if (hasApp) {
          // Add a small delay before fetching the general application
          // to ensure Firestore is ready for the second query
          setTimeout(() => {
            loadGeneralApp(validGeneralAppIds[0]);
          }, 200);
        } else {
          // No general app, so no need to load it
          setGeneralAppLoading(false);
        }
      } else {
        // User doc doesn't exist yet
        setHasGeneralApp(false);
        setGeneralAppLoading(false);
      }
    } catch (error) {
      console.error('Error checking for general application:', error);
      setHasGeneralApp(false);
      setGeneralAppLoading(false);
      
      if (isPermissionError(error)) {
        toast.error('Permission error. Please check your connection and try again.');
      }
    }
  };
  
  // Load general application (separately, only if it exists)
  const loadGeneralApp = async (generalAppId?: string) => {
    try {
      // Safety check - don't proceed if user is not available
      if (!user) {
        setGeneralAppLoading(false);
        return;
      }
      
      // Only do a single attempt with the ID we already know
      if (generalAppId) {
        const generalAppDoc = await getDoc(doc(db, 'generalApplications', generalAppId));
        
        if (generalAppDoc.exists()) {
          const generalApp = {
            id: generalAppDoc.id,
            ...generalAppDoc.data()
          } as GeneralApplication;
          
          setGeneralApplication(generalApp);
        } else {
          // General application document doesn't exist even though we have an ID
          setGeneralApplication(null);
          setHasGeneralApp(false);
        }
      } else {
        // Fallback to the old method if no ID is provided
        await fetchGeneralApplication();
      }
    } catch (error) {
      console.error('Error loading general application:', error);
      
      if (isPermissionError(error)) {
        toast.error('Permission error loading general application. Please check your connection.');
      } else {
        toast.error('Failed to load general application data');
      }
      
      // Reset to empty state on error
      setGeneralApplication(null);
      setHasGeneralApp(false);
    } finally {
      setGeneralAppLoading(false);
    }
  };
  
  // Helper to check if an error is a Firebase permission error
  const isPermissionError = (error: any): boolean => {
    return error?.message?.includes('permission') || 
           error?.message?.includes('insufficient');
  };
  
  // Simple fetch functions with basic retry
  async function fetchProfiles() {
    if (!user) return null;
    
    return retry(async () => {
      const q = query(collection(db, 'jobProfiles'), where('userId', '==', user.uid));
      const querySnapshot = await getDocs(q);
      const loadedProfiles: JobProfile[] = [];
      querySnapshot.forEach((doc) => {
        loadedProfiles.push({ id: doc.id, ...doc.data() } as JobProfile);
      });
      setProfiles(loadedProfiles);
      return loadedProfiles;
    });
  }

  async function fetchGeneralApplication() {
    if (!user) return null;
    
    try {
      // Get the user document to check userGeneralApplications
      const userDoc = await getDoc(doc(db, 'users', user.uid));
      
      // Check if user document exists before proceeding
      if (!userDoc.exists()) {
        setGeneralApplication(null);
        return null;
      }
      
      const userData = userDoc.data();
      const userGeneralApps = userData?.userGeneralApplications || {};
      
      // Get the first general application ID if any exist
      const generalAppIds = Object.keys(userGeneralApps);
      
      if (generalAppIds.length > 0) {
        // Get the first general application
        const generalAppId = generalAppIds[0];
        const generalAppDoc = await getDoc(doc(db, 'generalApplications', generalAppId));
        
        if (generalAppDoc.exists()) {
          const generalApp = {
            id: generalAppDoc.id,
            ...generalAppDoc.data()
          } as GeneralApplication;
          
          setGeneralApplication(generalApp);
          return generalApp;
        }
      }
      
      // If we got here, no general application exists, but this is not an error
      setGeneralApplication(null);
      return null;
    } catch (error) {
      console.error("Error fetching general application:", error);
      throw error; // Rethrow for the calling code to handle
    }
  }

  const handleDeleteConfirm = async () => {
    if (!user || !profileToDelete) return;

    try {
      setDeleting(profileToDelete.id);
      
      // Delete resume from storage if exists
      if (profileToDelete.resumeURL) {
        const resumePath = profileToDelete.resumeURL.split('?')[0].split('/o/')[1];
        if (resumePath) {
          const decodedPath = decodeURIComponent(resumePath);
          const resumeRef = ref(storage, decodedPath);
          try {
            await deleteObject(resumeRef);
          } catch (error) {
            console.error('Error deleting resume:', error);
          }
        }
      }

      // IMPORTANT: We don't delete the actual job profile document from the jobProfiles collection
      // because it might be referenced by existing applications. We only remove the reference
      // from the user's document, effectively hiding it from their view.
      
      // Remove reference from user document
      await updateDoc(doc(db, 'users', user.uid), {
        [`userJobProfiles.${profileToDelete.id}`]: deleteField()
      });
      
      // Update local state
      setProfiles(prev => prev.filter(p => p.id !== profileToDelete.id));
      toast.success('Resume removed from your profile');
    } catch (error) {
      console.error('Error removing profile:', error);
      toast.error('Failed to remove resume from your profile');
    } finally {
      setDeleting(null);
      setProfileToDelete(null);
      setShowDeleteModal(false);
    }
  };

  async function handleDeleteGeneralApplication() {
    if (!generalApplication || !user) return;

    try {
      setDeletingGeneral(true);
      
      // IMPORTANT: We don't delete the actual general application document from the generalApplications collection
      // because it might be referenced by existing applications. We only remove the reference
      // from the user's document, effectively hiding it from their view.
      
      // Remove reference from user document
      const userRef = doc(db, 'users', user.uid);
      await updateDoc(userRef, {
        [`userGeneralApplications.${generalApplication.id}`]: deleteField()
      });
      
      setGeneralApplication(null);
      setHasGeneralApp(false);
      toast.success('General application removed from your profile');
    } catch (error) {
      console.error('Error removing general application:', error);
      toast.error('Failed to remove general application from your profile');
    } finally {
      setDeletingGeneral(false);
      setShowDeleteGeneralModal(false);
    }
  };

  // Show only a loading spinner for the whole page if profiles are still loading
  if (profilesLoading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8 py-8 bg-gray-50 dark:bg-gray-900 min-h-screen">
      <div className="mb-8">
        <div className="flex items-center justify-between mb-6">
          <h2 className="text-2xl font-bold text-gray-900 dark:text-white">General Application Form</h2>
        </div>

        {generalAppLoading ? (
          // Show spinner only in the general application section
          <div className="bg-white dark:bg-gray-800 shadow rounded-lg p-6 flex justify-center items-center" style={{ minHeight: '150px' }}>
            <LoadingSpinner size="md" />
          </div>
        ) : hasGeneralApp && generalApplication ? (
          // Show general application once loaded
          <div className="bg-white dark:bg-gray-800 shadow rounded-lg p-6">
            <div className="flex items-center justify-between mb-4">
              <div>
                <h3 className="text-lg font-medium text-gray-900 dark:text-white">
                  General Application
                </h3>
                <p className="text-sm text-gray-500 dark:text-gray-400">
                  Your default application information
                </p>
              </div>
              <div className="flex items-center space-x-2">
                <button
                  onClick={() => navigate(`/general-application/${generalApplication.id}`)}
                  className="inline-flex items-center p-2 border border-gray-300 dark:border-gray-600 rounded-md text-sm font-medium hover:bg-gray-50 dark:hover:bg-gray-700"
                >
                  <Pencil className="h-5 w-5 text-blue-500 dark:text-blue-400" />
                </button>
                <button
                  onClick={() => setShowDeleteGeneralModal(true)}
                  className="inline-flex items-center p-2 border border-gray-300 dark:border-gray-600 rounded-md text-sm font-medium hover:bg-gray-50 dark:hover:bg-gray-700"
                >
                  <Trash2 className="h-5 w-5 text-red-500 dark:text-red-400" />
                </button>
              </div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <h4 className="text-sm font-medium text-gray-500 dark:text-gray-400 mb-1">Address</h4>
                <p className="text-sm text-gray-900 dark:text-white">
                  {generalApplication.street}
                  {generalApplication.aptSuiteUnit && <><br />{generalApplication.aptSuiteUnit}</>}
                  <br />
                  {generalApplication.city}, {generalApplication.state} {generalApplication.zipCode}<br />
                  {generalApplication.country}
                </p>
              </div>
              <div>
                <h4 className="text-sm font-medium text-gray-500 dark:text-gray-400 mb-1">Work Details</h4>
                <p className="text-sm text-gray-900 dark:text-white">
                  Visa Status: {generalApplication.visaStatus}<br />
                  Availability: {generalApplication.availabilityNoticePeriod}<br />
                  Preferred Work Environment: {generalApplication.preferredWorkEnvironment}
                </p>
              </div>
            </div>
            {(generalApplication.linkedinUrl || generalApplication.githubUrl || generalApplication.portfolioUrl || generalApplication.tableauUrl || generalApplication.blogUrl) && (
              <div className="mt-4">
                <h4 className="text-sm font-medium text-gray-500 dark:text-gray-400 mb-1">Online Presence</h4>
                <div className="flex flex-wrap gap-4">
                  {generalApplication.linkedinUrl && (
                    <a
                      href={generalApplication.linkedinUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-sm text-indigo-600 dark:text-indigo-400 hover:text-indigo-500"
                    >
                      LinkedIn Profile
                    </a>
                  )}
                  {generalApplication.githubUrl && (
                    <a
                      href={generalApplication.githubUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-sm text-indigo-600 dark:text-indigo-400 hover:text-indigo-500"
                    >
                      GitHub Profile
                    </a>
                  )}
                  {generalApplication.tableauUrl && (
                    <a
                      href={generalApplication.tableauUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-sm text-indigo-600 dark:text-indigo-400 hover:text-indigo-500"
                    >
                      Tableau Profile
                    </a>
                  )}
                  {generalApplication.portfolioUrl && (
                    <a
                      href={generalApplication.portfolioUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-sm text-indigo-600 dark:text-indigo-400 hover:text-indigo-500"
                    >
                      Portfolio
                    </a>
                  )}
                  {generalApplication.blogUrl && (
                    <a
                      href={generalApplication.blogUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-sm text-indigo-600 dark:text-indigo-400 hover:text-indigo-500"
                    >
                      Blog
                    </a>
                  )}
                </div>
              </div>
            )}
            {generalApplication.isComplete === false && (
              <div className="mt-4 p-4 bg-yellow-50 dark:bg-yellow-900 rounded-md">
                <p className="text-sm text-yellow-700 dark:text-yellow-200">
                  Your general application is incomplete. Please fill in all required fields to use it in job applications.
                </p>
              </div>
            )}
          </div>
        ) : (
          // Show "create general application" UI when no application exists
          <div className="bg-gray-50 dark:bg-gray-900 border-2 border-dashed border-gray-300 dark:border-gray-700 rounded-lg p-8 text-center">
            <p className="text-gray-500 dark:text-gray-400 mb-4">
              Create a general application to store common information used across all job applications.
            </p>
            <button
              onClick={() => navigate('/general-application/new')}
              className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 dark:bg-indigo-500 dark:hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              <Plus className="-ml-1 mr-2 h-5 w-5" />
              Create General Application
            </button>
          </div>
        )}
      </div>

      <div>
        <div className="flex items-center justify-between mb-6">
          <h2 className="text-2xl font-bold text-gray-900 dark:text-white">Saved Resumes</h2>
          {profiles.length > 0 && (
            <button
              onClick={() => navigate('/job-profiles/new')}
              className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 dark:bg-indigo-500 dark:hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              <Plus className="-ml-1 mr-2 h-5 w-5" />
              Add new Resume
            </button>
          )}
        </div>

        {profiles.length === 0 ? (
          <div className="bg-gray-50 dark:bg-gray-900 border-2 border-dashed border-gray-300 dark:border-gray-700 rounded-lg p-8 text-center">
            <p className="text-gray-500 dark:text-gray-400 mb-4">
              Save different versions of your resume for different types of jobs you're applying to.
            </p>
            <button
              onClick={() => navigate('/job-profiles/new')}
              className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 dark:bg-indigo-500 dark:hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              <Plus className="-ml-1 mr-2 h-5 w-5" />
              Add new Resume
            </button>
          </div>
        ) : (
          <div className="flex flex-col items-center space-y-4 w-full">
            {profiles.map((profile) => (
              <div key={profile.id} className="bg-white dark:bg-gray-800 shadow rounded-lg p-5 w-full">
                <div className="flex items-center justify-between mb-4">
                  <div>
                    <h3 className="text-lg font-medium text-gray-900 dark:text-white">
                      {profile.profileName}
                    </h3>
                  </div>
                  <div className="flex items-center space-x-2">
                    <Link
                      to={`/job-profiles/${profile.id}/edit`}
                      className="inline-flex items-center p-2 border border-gray-300 dark:border-gray-600 rounded-md text-sm font-medium hover:bg-gray-50 dark:hover:bg-gray-700"
                    >
                      <Pencil className="h-5 w-5 text-blue-500 dark:text-blue-400" />
                    </Link>
                    <button
                      onClick={() => {
                        setProfileToDelete(profile);
                        setShowDeleteModal(true);
                      }}
                      className="inline-flex items-center p-2 border border-gray-300 dark:border-gray-600 rounded-md text-sm font-medium hover:bg-gray-50 dark:hover:bg-gray-700"
                    >
                      <Trash2 className="h-5 w-5 text-red-500 dark:text-red-400" />
                    </button>
                  </div>
                </div>
                {profile.resumeURL && (
                  <div className="mt-4 flex items-center space-x-2">
                    <FaRegFilePdf className="h-5 w-5 text-red-500" />
                    <a
                      href={profile.resumeURL}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-sm text-indigo-600 hover:text-indigo-500 dark:text-indigo-400 dark:hover:text-indigo-300"
                    >
                      {profile.resumeFileName || 'Resume'}
                    </a>
                  </div>
                )}
                <p className="mt-4 text-sm text-gray-500 dark:text-gray-400">
                  Last updated: {new Date(profile.updatedAt).toLocaleDateString()}
                </p>
              </div>
            ))}
          </div>
        )}
      </div>

      <ConfirmationModal
        isOpen={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        onConfirm={handleDeleteConfirm}
        title="Remove Saved Resume"
        message="Are you sure you want to remove this resume from your profile? It will no longer appear in your list, but will remain accessible for existing applications."
        isSubmitting={!!deleting}
      />

      <Modal
        isOpen={showDeleteGeneralModal}
        onClose={() => setShowDeleteGeneralModal(false)}
      >
        <div className="sm:flex sm:items-start">
          <div className="mt-3 text-center sm:mt-0 sm:text-left">
            <h3 className="text-lg font-medium leading-6 text-gray-900 dark:text-white">
              Remove General Application
            </h3>
            <div className="mt-2">
              <p className="text-sm text-gray-500 dark:text-gray-400">
                Are you sure you want to remove your general application from your profile? It will no longer appear in your list, but will remain accessible for existing applications.
              </p>
            </div>
          </div>
        </div>
        <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
          <button
            type="button"
            onClick={handleDeleteGeneralApplication}
            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
            disabled={deletingGeneral}
          >
            {deletingGeneral ? 'Removing...' : 'Remove'}
          </button>
          <button
            type="button"
            onClick={() => setShowDeleteGeneralModal(false)}
            className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 dark:border-gray-600 shadow-sm px-4 py-2 bg-white dark:bg-gray-800 text-base font-medium text-gray-700 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
            disabled={deletingGeneral}
          >
            Cancel
          </button>
        </div>
      </Modal>
    </div>
  );
}