import { useState, useEffect, useRef } from 'react';
import { useAuth } from '../../hooks/useAuth';
import { db, storage } from '../../lib/firebase';
import { collection, query, orderBy, onSnapshot, addDoc, serverTimestamp, doc, getDoc, deleteDoc, updateDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { Comment } from '../../types/application';
import { Loader2, Paperclip, X, Edit2, Trash2, Check, XCircle } from 'lucide-react';
import toast from 'react-hot-toast';
import { formatDistanceToNow, format } from 'date-fns';

interface CommentsProps {
  applicationId: string;
  onUpdate?: () => void;
}

interface CommentWithUser extends Comment {
  userDetails?: {
    firstName: string;
    lastName: string;
    photoURL: string | null;
  };
}

export function Comments({ applicationId, onUpdate }: CommentsProps) {
  const { user, userProfile } = useAuth();
  const [comments, setComments] = useState<CommentWithUser[]>([]);
  const [newComment, setNewComment] = useState('');
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [files, setFiles] = useState<File[]>([]);
  const [editingCommentId, setEditingCommentId] = useState<string | null>(null);
  const [editedMessage, setEditedMessage] = useState('');

  useEffect(() => {
    if (!applicationId) {
      setLoading(false);
      setError('Invalid application ID');
      return;
    }

    try {
      const q = query(
        collection(db, `applications/${applicationId}/comments`),
        orderBy('createdAt', 'desc')
      );

      const unsubscribe = onSnapshot(q, 
        async (snapshot) => {
          const commentsPromises = snapshot.docs.map(async (docSnapshot) => {
            const commentData = docSnapshot.data();
            // Fetch user details for each comment
            const userDoc = await getDoc(doc(db, 'users', commentData.userId));
            const userData = userDoc.data();
            
            return {
              id: docSnapshot.id,
              ...commentData,
              userDetails: userData ? {
                firstName: userData.firstName,
                lastName: userData.lastName,
                photoURL: userData.photoURL
              } : undefined
            } as CommentWithUser;
          });

          const commentsList = await Promise.all(commentsPromises);
          setComments(commentsList);
          setLoading(false);
          setError(null);
        },
        (error) => {
          console.error('Error fetching comments:', error);
          setError('Failed to load comments');
          setLoading(false);
        }
      );

      return () => unsubscribe();
    } catch (error) {
      console.error('Error setting up comments listener:', error);
      setError('Failed to load comments');
      setLoading(false);
    }
  }, [applicationId]);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = Array.from(e.target.files || []);
    if (selectedFiles.length + files.length > 5) {
      toast.error('Maximum 5 files allowed');
      return;
    }

    // Filter files by type and size
    const validFiles = selectedFiles.filter(file => {
      if (file.size > 10 * 1024 * 1024) { // 10MB limit
        toast.error(`${file.name} is too large. Maximum size is 10MB`);
        return false;
      }
      if (!file.type.startsWith('image/')) {
        toast.error(`${file.name} is not an image file`);
        return false;
      }
      return true;
    });

    setFiles(prev => [...prev, ...validFiles]);

    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const removeFile = (index: number) => {
    setFiles(files.filter((_, i) => i !== index));
  };

  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!user || !userProfile || (!newComment.trim() && files.length === 0) || !applicationId) return;

    // Only allow admins and agents to post comments
    if (userProfile.userType !== 'admin' && userProfile.userType !== 'agent') return;

    try {
      setSubmitting(true);
      let attachments: { url: string; name: string; type: string; size: number }[] = [];

      if (files.length > 0) {
        const urls = await uploadFiles(files);
        attachments = files.map((file, index) => ({
          url: urls[index],
          name: file.name,
          type: file.type,
          size: file.size
        }));
      }

      await addDoc(collection(db, `applications/${applicationId}/comments`), {
        userId: user.uid,
        userType: userProfile.userType,
        message: newComment.trim(),
        createdAt: serverTimestamp(),
        attachments
      });

      setNewComment('');
      setFiles([]);
      if (onUpdate) onUpdate();
      toast.success('Comment posted successfully');
    } catch (error) {
      console.error('Error adding comment:', error);
      toast.error('Failed to post comment');
    } finally {
      setSubmitting(false);
    }
  };

  const handleEdit = (comment: CommentWithUser) => {
    setEditingCommentId(comment.id);
    setEditedMessage(comment.message);
  };

  const handleSaveEdit = async (commentId: string) => {
    if (!editedMessage.trim()) return;

    try {
      setSubmitting(true);
      await updateDoc(doc(db, `applications/${applicationId}/comments/${commentId}`), {
        message: editedMessage.trim(),
        editedAt: serverTimestamp()
      });
      setEditingCommentId(null);
      toast.success('Comment updated successfully');
    } catch (error) {
      console.error('Error updating comment:', error);
      toast.error('Failed to update comment');
    } finally {
      setSubmitting(false);
    }
  };

  const handleDelete = async (commentId: string) => {
    if (!window.confirm('Are you sure you want to delete this comment?')) return;

    try {
      setSubmitting(true);
      await deleteDoc(doc(db, `applications/${applicationId}/comments/${commentId}`));
      toast.success('Comment deleted successfully');
    } catch (error) {
      console.error('Error deleting comment:', error);
      toast.error('Failed to delete comment');
    } finally {
      setSubmitting(false);
    }
  };

  const uploadFiles = async (files: File[]): Promise<string[]> => {
    const uploadPromises = files.map(async (file) => {
      const fileRef = ref(storage, `applications/${applicationId}/screenshots/${Date.now()}_${file.name}`);
      const snapshot = await uploadBytes(fileRef, file);
      return getDownloadURL(snapshot.ref);
    });

    return Promise.all(uploadPromises);
  };

  if (loading) {
    return (
      <div className="flex justify-center py-8">
        <Loader2 className="h-8 w-8 animate-spin text-indigo-600 dark:text-indigo-400" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-center py-8 text-red-600 dark:text-red-400">
        <p>{error}</p>
      </div>
    );
  }

  return (
    <div>
      {/* Show comment form only for admins and agents */}
      {userProfile?.userType !== 'customer' && (
        <form onSubmit={handleSubmit} className="mb-6 space-y-4">
          <div>
            <label htmlFor="comment" className="sr-only">Add a note or upload proof</label>
            <textarea
              id="comment"
              rows={3}
              className="block w-full rounded-md border-gray-300 dark:border-gray-600 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 dark:bg-gray-700 dark:text-white sm:text-sm p-4"
              placeholder="Add application proof or important notes..."
              value={newComment}
              onChange={(e) => setNewComment(e.target.value)}
              disabled={submitting}
            />
          </div>

          {/* File attachments */}
          {files.length > 0 && (
            <div className="space-y-2">
              {files.map((file, index) => (
                <div key={index} className="flex items-center justify-between p-2 bg-gray-50 dark:bg-gray-700/50 rounded-md group">
                  <button
                    type="button"
                    onClick={() => window.open(URL.createObjectURL(file), '_blank')}
                    className="flex-1 flex items-center space-x-2 text-left hover:text-indigo-600 dark:hover:text-indigo-400"
                  >
                    <Paperclip className="h-4 w-4 text-gray-500 dark:text-gray-400" />
                    <span className="text-sm text-gray-700 dark:text-gray-200 group-hover:text-indigo-600 dark:group-hover:text-indigo-400">{file.name}</span>
                    <span className="text-xs text-gray-500 dark:text-gray-400">
                      ({(file.size / 1024).toFixed(1)} KB)
                    </span>
                  </button>
                  <button
                    type="button"
                    onClick={() => removeFile(index)}
                    className="ml-2 text-gray-400 hover:text-gray-500 dark:hover:text-gray-300"
                  >
                    <X className="h-4 w-4" />
                  </button>
                </div>
              ))}
            </div>
          )}

          <div className="flex justify-between items-center">
            <div className="flex items-center space-x-4">
              <input
                type="file"
                ref={fileInputRef}
                onChange={handleFileChange}
                className="hidden"
                multiple
                accept="image/*"
              />
              <button
                type="button"
                onClick={() => fileInputRef.current?.click()}
                disabled={submitting || files.length >= 5}
                className="inline-flex items-center px-3 py-2 border border-gray-300 dark:border-gray-600 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 dark:text-gray-200 bg-white dark:bg-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50"
              >
                <Paperclip className="h-4 w-4 mr-2" />
                Attach Screenshots
              </button>
              <span className="text-sm text-gray-500 dark:text-gray-400">
                {files.length}/5 files
              </span>
            </div>

            <button
              type="submit"
              disabled={submitting || (!newComment.trim() && files.length === 0)}
              className="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:opacity-50 disabled:cursor-not-allowed"
            >
              {submitting ? (
                <>
                  <Loader2 className="h-4 w-4 mr-2 animate-spin" />
                  Posting...
                </>
              ) : (
                'Post'
              )}
            </button>
          </div>
        </form>
      )}

      <div className="space-y-4">
        {comments.map((comment) => (
          <div key={comment.id} className="bg-gray-50 dark:bg-gray-700/50 rounded-lg p-4">
            <div className="flex items-start space-x-4">
              {/* User Avatar */}
              <div className="flex-shrink-0">
                {comment.userDetails?.photoURL ? (
                  <img
                    src={comment.userDetails.photoURL}
                    alt="Profile"
                    className="h-10 w-10 rounded-full object-cover"
                  />
                ) : (
                  <div className="h-10 w-10 rounded-full bg-gray-200 dark:bg-gray-600 flex items-center justify-center">
                    <span className="text-lg font-medium text-gray-600 dark:text-gray-300">
                      {comment.userDetails?.firstName?.[0]?.toUpperCase() || '?'}
                    </span>
                  </div>
                )}
              </div>

              <div className="flex-1 min-w-0">
                <div className="flex justify-between items-start">
                  <div className="flex items-center space-x-2">
                    <h4 className="text-sm font-medium text-gray-900 dark:text-white">
                      {comment.userDetails ? 
                        `${comment.userDetails.firstName} ${comment.userDetails.lastName}` : 
                        'Unknown User'}
                    </h4>
                    <span className={`text-xs px-2 py-1 rounded-full capitalize
                      ${comment.userType === 'admin' ? 'bg-red-100 text-red-800 dark:bg-red-900/30 dark:text-red-300' : 
                        'bg-blue-100 text-blue-800 dark:bg-blue-900/30 dark:text-blue-300'}`}>
                      {comment.userType}
                    </span>
                  </div>

                  {/* Edit/Delete icons */}
                  {user?.uid === comment.userId && editingCommentId !== comment.id && (
                    <div className="flex space-x-2 ml-4">
                      <button
                        onClick={() => handleEdit(comment)}
                        className="text-gray-400 hover:text-gray-600 dark:hover:text-gray-300"
                        title="Edit comment"
                      >
                        <Edit2 className="h-4 w-4" />
                      </button>
                      <button
                        onClick={() => handleDelete(comment.id)}
                        className="text-gray-400 hover:text-red-600 dark:hover:text-red-400"
                        title="Delete comment"
                      >
                        <Trash2 className="h-4 w-4" />
                      </button>
                    </div>
                  )}
                </div>

                <div className="flex items-center space-x-2 mt-1">
                  <p className="text-xs text-gray-500 dark:text-gray-400">
                    {comment.createdAt?.seconds ? (
                      <>
                        <span title={format(new Date(comment.createdAt.seconds * 1000), 'PPpp')}>
                          {formatDistanceToNow(new Date(comment.createdAt.seconds * 1000), { addSuffix: true })}
                        </span>
                        {comment.editedAt && (
                          <span className="ml-2 text-gray-400 dark:text-gray-500">
                            (edited {formatDistanceToNow(new Date(comment.editedAt.seconds * 1000), { addSuffix: true })})
                          </span>
                        )}
                      </>
                    ) : (
                      'Just now'
                    )}
                  </p>
                </div>

                {editingCommentId === comment.id ? (
                  <div className="mt-2">
                    <textarea
                      value={editedMessage}
                      onChange={(e) => setEditedMessage(e.target.value)}
                      className="w-full rounded-md border-gray-300 dark:border-gray-600 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 dark:bg-gray-700 dark:text-white sm:text-sm p-2"
                      rows={3}
                    />
                    <div className="mt-2 flex space-x-2">
                      <button
                        onClick={() => handleSaveEdit(comment.id)}
                        disabled={submitting}
                        className="inline-flex items-center px-2 py-1 text-sm text-green-600 hover:text-green-700 dark:text-green-400"
                      >
                        <Check className="h-4 w-4 mr-1" />
                        Save
                      </button>
                      <button
                        onClick={() => setEditingCommentId(null)}
                        className="inline-flex items-center px-2 py-1 text-sm text-gray-600 hover:text-gray-700 dark:text-gray-400"
                      >
                        <XCircle className="h-4 w-4 mr-1" />
                        Cancel
                      </button>
                    </div>
                  </div>
                ) : (
                  <p className="mt-2 text-sm text-gray-900 dark:text-white whitespace-pre-wrap">
                    {comment.message}
                  </p>
                )}

                {/* For posted comments, show clickable attachments */}
                {comment.attachments && comment.attachments.length > 0 && (
                  <div className="mt-3 space-y-2">
                    {comment.attachments.map((file, index) => (
                      <a
                        key={index}
                        href={file.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="flex items-center justify-between p-2 bg-gray-50 dark:bg-gray-700/50 rounded-md hover:bg-gray-100 dark:hover:bg-gray-600/50 group"
                      >
                        <div className="flex items-center space-x-2">
                          <Paperclip className="h-4 w-4 text-gray-500 dark:text-gray-400 group-hover:text-indigo-600 dark:group-hover:text-indigo-400" />
                          <span className="text-sm text-gray-700 dark:text-gray-200 group-hover:text-indigo-600 dark:group-hover:text-indigo-400">{file.name}</span>
                          <span className="text-xs text-gray-500 dark:text-gray-400">
                            ({(file.size / 1024).toFixed(1)} KB)
                          </span>
                        </div>
                      </a>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        ))}

        {comments.length === 0 && (
          <div className="text-center p-6 bg-gray-50 dark:bg-gray-800 rounded-lg">
            <p className="text-gray-500 dark:text-gray-400">
              No comments yet. Our Agents will post Job Login Credentials / Screenshot Proofs here.
            </p>
          </div>
        )}
      </div>
    </div>
  );
}