import React from 'react';
import { AlertCircle, AlertTriangle, CheckCircle, Info } from 'lucide-react';

interface AlertProps {
  type: 'success' | 'warning' | 'error' | 'info';
  title: string;
  message: string;
  className?: string;
}

export function Alert({ type, title, message, className = '' }: AlertProps) {
  const typeClasses = {
    success: {
      bg: 'bg-green-50 dark:bg-green-900/30',
      icon: <CheckCircle className="h-5 w-5 text-green-500 dark:text-green-400" />,
      titleColor: 'text-green-800 dark:text-green-200',
      messageColor: 'text-green-700 dark:text-green-100'
    },
    warning: {
      bg: 'bg-yellow-50 dark:bg-yellow-900/30',
      icon: <AlertTriangle className="h-5 w-5 text-yellow-500 dark:text-yellow-400" />,
      titleColor: 'text-yellow-800 dark:text-yellow-200',
      messageColor: 'text-yellow-700 dark:text-yellow-100'
    },
    error: {
      bg: 'bg-red-50 dark:bg-red-900/30',
      icon: <AlertCircle className="h-5 w-5 text-red-500 dark:text-red-400" />,
      titleColor: 'text-red-800 dark:text-red-200',
      messageColor: 'text-red-700 dark:text-red-100'
    },
    info: {
      bg: 'bg-blue-50 dark:bg-blue-900/30',
      icon: <Info className="h-5 w-5 text-blue-500 dark:text-blue-400" />,
      titleColor: 'text-blue-800 dark:text-blue-200',
      messageColor: 'text-blue-700 dark:text-blue-100'
    }
  };

  const { bg, icon, titleColor, messageColor } = typeClasses[type];

  return (
    <div className={`${bg} p-4 rounded-md ${className}`}>
      <div className="flex">
        {icon}
        <div className="ml-3">
          <h3 className={`text-sm font-medium ${titleColor}`}>
            {title}
          </h3>
          <div className={`mt-2 text-sm ${messageColor}`}>
            <p>{message}</p>
          </div>
        </div>
      </div>
    </div>
  );
}