import { useState, useEffect } from 'react';
import { useAuth } from '../hooks/useAuth';
import { collection, query, where, orderBy, getDocs } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { Application } from '../types/application';
import { ApplicationCard } from '../components/applications/ApplicationCard';
import { ClipboardList } from 'lucide-react';
import { toast } from 'react-hot-toast';

export function AgentApplications() {
  const { user, userProfile, loading: authLoading } = useAuth();
  const [applications, setApplications] = useState<Application[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    // Only load applications when auth is complete
    if (authLoading) {
      return;
    }

    const loadApplications = async () => {
      // Clear previous errors
      setError(null);

      if (!user) {
        console.log("User not logged in");
        setError("Please log in to view applications");
        setLoading(false);
        return;
      }

      if (!userProfile) {
        console.log("User profile not loaded yet");
        // Keep loading state, don't set error
        return;
      }

      if (userProfile.userType !== 'agent' && userProfile.userType !== 'admin') {
        console.log("User is not an agent or admin", userProfile.userType);
        setError("You don't have permission to view agent applications");
        setLoading(false);
        return;
      }

      try {
        setLoading(true);
        
        console.log("Loading applications for agent:", user.uid, "userType:", userProfile.userType);
        
        // Get applications assigned to the agent
        const assignedQuery = query(
          collection(db, 'applications'),
          where('agentId', '==', user.uid),
          where('status', 'in', ['in_progress', 'pending']),
          orderBy('updatedAt', 'desc')
        );

        // Get available applications (status: submitted)
        const availableQuery = query(
          collection(db, 'applications'),
          where('status', '==', 'submitted'),
          orderBy('updatedAt', 'desc')
        );

        // Execute both queries with better error handling
        try {
          const [assignedSnapshot, availableSnapshot] = await Promise.all([
            getDocs(assignedQuery),
            getDocs(availableQuery)
          ]);

          console.log("Loaded assigned applications:", assignedSnapshot.size);
          console.log("Loaded available applications:", availableSnapshot.size);

          // Combine results
          const assignedApps = assignedSnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
            paymentStatus: doc.data().paymentStatus || 'pending'
          })) as Application[];

          const availableApps = availableSnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
            paymentStatus: doc.data().paymentStatus || 'pending'
          })) as Application[];

          setApplications([...assignedApps, ...availableApps]);
          setLoading(false);
        } catch (queryError: any) {
          console.error('Error executing Firestore queries:', queryError);
          setError(`Permission error: ${queryError.message}`);
          toast.error("Failed to load applications due to a permission error. Please contact support.");
          setLoading(false);
        }
      } catch (error: any) {
        console.error('Error loading applications:', error);
        setError(`Error: ${error.message}`);
        toast.error("Failed to load applications");
        setLoading(false);
      }
    };

    loadApplications();
  }, [user, userProfile, authLoading]);

  // Group applications by status
  const getGroupedApplications = () => {
    const grouped = applications.reduce((acc, app) => {
      const status = app.status;
      if (!acc[status]) {
        acc[status] = [];
      }
      acc[status].push(app);
      return acc;
    }, {} as Record<string, Application[]>);

    // For submitted applications, randomly select only one
    if (grouped['submitted']?.length > 0) {
      const randomIndex = Math.floor(Math.random() * grouped['submitted'].length);
      grouped['submitted'] = [grouped['submitted'][randomIndex]];
    }

    return grouped;
  };

  const groupedApplications = getGroupedApplications();

  if (loading) {
    return (
      <div className="flex justify-center py-12">
        <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-indigo-600 dark:border-indigo-400"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="bg-white dark:bg-gray-800 shadow rounded-lg p-6">
          <div className="text-center">
            <h3 className="mt-2 text-lg font-medium text-gray-900 dark:text-white">Error Loading Applications</h3>
            <p className="mt-1 text-gray-500 dark:text-gray-400">{error}</p>
            <button
              onClick={() => window.location.reload()}
              className="mt-4 inline-flex items-center px-4 py-2 border border-gray-300 dark:border-gray-600 rounded-md shadow-sm text-sm font-medium text-gray-700 dark:text-gray-200 bg-white dark:bg-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
            >
              Try Again
            </button>
          </div>
        </div>
      </div>
    );
  }

  const totalSubmitted = applications.filter(app => app.status === 'submitted').length;

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <div className="mb-8">
        <h1 className="text-3xl font-bold text-gray-900 dark:text-white">Agent Applications</h1>
        <p className="mt-2 text-gray-600 dark:text-gray-400">
          View and manage your assigned applications
        </p>
      </div>

      <div className="space-y-8">
        {/* In Progress Applications */}
        {groupedApplications['in_progress']?.length > 0 && (
          <div>
            <h2 className="text-xl font-semibold text-gray-900 dark:text-white mb-4">
              In Progress Applications ({groupedApplications['in_progress'].length})
            </h2>
            <div className="space-y-4">
              {groupedApplications['in_progress'].map(application => (
                <ApplicationCard
                  key={application.id}
                  application={application}
                  onRefresh={() => {}}
                />
              ))}
            </div>
          </div>
        )}

        {/* Pending Applications */}
        {groupedApplications['pending']?.length > 0 && (
          <div>
            <h2 className="text-xl font-semibold text-gray-900 dark:text-white mb-4">
              Pending Applications ({groupedApplications['pending'].length})
            </h2>
            <div className="space-y-4">
              {groupedApplications['pending'].map(application => (
                <ApplicationCard
                  key={application.id}
                  application={application}
                  onRefresh={() => {}}
                />
              ))}
            </div>
          </div>
        )}

        {/* Available Applications */}
        {groupedApplications['submitted']?.length > 0 ? (
          <div>
            <h2 className="text-xl font-semibold text-gray-900 dark:text-white mb-4">
              Available Applications ({totalSubmitted})
            </h2>
            <div className="space-y-4">
              {groupedApplications['submitted'].map(application => (
                <ApplicationCard
                  key={application.id}
                  application={application}
                  onRefresh={() => {}}
                />
              ))}
            </div>
          </div>
        ) : (
          <div>
            <h2 className="text-xl font-semibold text-gray-900 dark:text-white mb-4">
              Available Applications
            </h2>
            <div className="text-center py-12 bg-white dark:bg-gray-800 shadow rounded-lg">
              <div className="flex flex-col items-center">
                <ClipboardList className="h-12 w-12 text-gray-400 dark:text-gray-500 mb-4" />
                <h3 className="text-lg font-medium text-gray-900 dark:text-white">No Applications Available</h3>
                <p className="mt-2 text-gray-500 dark:text-gray-400 max-w-sm">
                  There are currently no new applications available to work on. Please check back later for new applications.
                </p>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}