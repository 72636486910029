import { useState, useEffect } from 'react';
import { db } from '../../../lib/firebase';
import { doc, getDoc } from 'firebase/firestore';
import { FaRegFilePdf } from 'react-icons/fa6';
import toast from 'react-hot-toast';
import { Application } from '../../../types/application';

interface JobProfileDetailsProps {
  application: Application;
}

export function JobProfileDetails({ application }: JobProfileDetailsProps) {
  const [profile, setProfile] = useState<any>(null);
  const [userProfile, setUserProfile] = useState<any>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    loadProfile();
    loadUserProfile();
  }, [application.profileId, application.userId]);

  const loadProfile = async () => {
    try {
      const docRef = doc(db, 'jobProfiles', application.profileId);
      const docSnap = await getDoc(docRef);
      
      if (docSnap.exists()) {
        setProfile(docSnap.data());
      }
    } catch (error) {
      console.error('Error loading profile:', error);
      toast.error('Failed to load saved resume details');
    } finally {
      setLoading(false);
    }
  };

  const loadUserProfile = async () => {
    try {
      const userRef = doc(db, 'users', application.userId);
      const userSnap = await getDoc(userRef);
      
      if (userSnap.exists()) {
        setUserProfile(userSnap.data());
      }
    } catch (error) {
      console.error('Error loading user profile:', error);
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center py-12">
        <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-indigo-400"></div>
      </div>
    );
  }

  if (!profile) {
    return (
      <div className="text-gray-400 text-center">
        <p>Saved resume not found</p>
      </div>
    );
  }

  return (
    <div className="divide-y divide-gray-200 dark:divide-gray-700">
      {userProfile && (
        <div className="px-6 py-4">
          <div className="space-y-6">
            <div className="flex items-center space-x-4">
              {userProfile.photoURL ? (
                <img 
                  src={userProfile.photoURL} 
                  alt={`${userProfile.firstName} ${userProfile.lastName}`}
                  className="h-12 w-12 rounded-full object-cover border border-gray-200 dark:border-gray-700"
                />
              ) : (
                <div className="h-12 w-12 rounded-full bg-gray-200 dark:bg-gray-700 flex items-center justify-center">
                  <span className="text-gray-500 dark:text-gray-400 text-lg font-medium">
                    {userProfile.firstName ? userProfile.firstName[0] : 'U'}
                  </span>
                </div>
              )}
              <div>
                <dt className="text-sm font-medium text-gray-500 dark:text-gray-400">Name</dt>
                <dd className="text-sm text-gray-900 dark:text-gray-100">
                  {userProfile.firstName} {userProfile.lastName}
                </dd>
              </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <dt className="text-sm font-medium text-gray-500 dark:text-gray-400">Email</dt>
                <dd className="mt-1 text-sm text-gray-900 dark:text-gray-100">
                  {userProfile.email}
                </dd>
              </div>
              <div>
                <dt className="text-sm font-medium text-gray-500 dark:text-gray-400">Phone</dt>
                <dd className="mt-1 text-sm text-gray-900 dark:text-gray-100">
                  {userProfile.phoneNumber || 'Not provided'}
                </dd>
              </div>
            </div>

            {userProfile.linkedinUrl && (
              <div>
                <dt className="text-sm font-medium text-gray-500 dark:text-gray-400">LinkedIn</dt>
                <dd className="mt-1 text-sm">
                  <a 
                    href={userProfile.linkedinUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-indigo-600 hover:text-indigo-500 dark:text-indigo-400 dark:hover:text-indigo-300"
                  >
                    View Profile
                  </a>
                </dd>
              </div>
            )}
          </div>
        </div>
      )}

      <div className="px-6 py-4">
        <h3 className="text-xl font-semibold text-gray-900 dark:text-gray-100">
          {profile.profileName}
        </h3>
      </div>

      {profile.resumeURL && (
        <div className="px-6 py-4">
          <dt className="text-sm font-medium text-gray-500 dark:text-gray-400 mb-2">Resume</dt>
          <dd>
            <a
              href={profile.resumeURL}
              target="_blank"
              rel="noopener noreferrer"
              className="inline-flex items-center space-x-2 p-3 bg-gray-50 dark:bg-gray-700/50 rounded-md hover:bg-gray-100 dark:hover:bg-gray-700 transition-colors duration-200 group"
            >
              <FaRegFilePdf className="h-6 w-6 text-red-500 dark:text-red-400 group-hover:text-red-600 dark:group-hover:text-red-300" />
              <span className="text-sm font-medium text-gray-900 dark:text-gray-100 group-hover:text-indigo-600 dark:group-hover:text-indigo-300">
                {profile.resumeFileName}
              </span>
            </a>
          </dd>
        </div>
      )}
    </div>
  );
}