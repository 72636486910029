import { useState, useEffect } from 'react';
import { useAuth } from '../../hooks/useAuth';
import { storage, db } from '../../lib/firebase';
import { ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';
import { doc, updateDoc } from 'firebase/firestore';
import { sendEmailVerification } from 'firebase/auth';
import toast from 'react-hot-toast';
import { ProfileForm } from './ProfileForm';
import { PhotoUpload } from './PhotoUpload';
import { PasswordChange } from './PasswordChange';
import { RequestDeletionModal } from '../modals/RequestDeletionModal';
import { EmailVerificationBanner } from '../EmailVerificationBanner';
import { sendDeletionRequest, sendCancellationRequest } from '../../services/email';
import { CancelDeletionModal } from '../modals/CancelDeletionModal';
import { RecentPayments } from '../payments/RecentPayments';

export function CustomerProfile() {
  const { user, userProfile, fetchUserProfile } = useAuth();
  const [loading, setLoading] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [showDeletionModal, setShowDeletionModal] = useState(false);
  const [showCancellationModal, setShowCancellationModal] = useState(false);
  const [hasDeletionRequest, setHasDeletionRequest] = useState(false);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    linkedinUrl: ''
  });

  useEffect(() => {
    if (userProfile) {
      setFormData({
        firstName: userProfile.firstName,
        lastName: userProfile.lastName,
        linkedinUrl: userProfile.linkedinUrl || ''
      });
      setHasDeletionRequest(userProfile.hasDeletionRequest || false);
    }
  }, [userProfile]);

  const handleProfileUpdate = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!user) return;

    try {
      setLoading(true);
      
      // Format LinkedIn URL if provided
      let formattedLinkedinUrl: string | null = formData.linkedinUrl?.trim() || null;
      if (formattedLinkedinUrl) {
        // Remove any trailing slashes and spaces
        formattedLinkedinUrl = formattedLinkedinUrl.replace(/\/+$/, '').trim();

        // Extract the profile path if it exists
        const profileMatch = formattedLinkedinUrl.match(/(?:linkedin\.com\/in\/|^in\/|^)([a-zA-Z0-9\-\_]+)/);
        
        if (profileMatch) {
          // Use the profile ID to construct a proper URL
          const profileId = profileMatch[1];
          formattedLinkedinUrl = `https://www.linkedin.com/in/${profileId}`;
        } else {
          throw new Error('Invalid LinkedIn profile URL format');
        }
      }

      await updateDoc(doc(db, 'users', user.uid), {
        firstName: formData.firstName,
        lastName: formData.lastName,
        linkedinUrl: formattedLinkedinUrl
      });
      
      await fetchUserProfile(user.uid);
      setEditMode(false);
      toast.success('Profile updated successfully');
    } catch (error: any) {
      if (error.message === 'Invalid LinkedIn profile URL format') {
        toast.error('Please enter a valid LinkedIn profile URL (e.g., linkedin.com/in/your-profile)');
      } else {
        toast.error('Failed to update profile');
      }
    } finally {
      setLoading(false);
    }
  };

  const handlePhotoUpload = async (file: File) => {
    if (!user) return;

    try {
      // Delete existing photo if any
      if (userProfile?.photoURL) {
        await handleDeletePhoto();
      }

      const fileExt = file.name.split('.').pop();
      const photoRef = ref(storage, `${user.uid}/photos/profile.${fileExt}`);
      await uploadBytes(photoRef, file);
      const photoURL = await getDownloadURL(photoRef);
      
      await updateDoc(doc(db, 'users', user.uid), {
        photoURL
      });

      await fetchUserProfile(user.uid);
      toast.success('Profile picture updated successfully');
    } catch (error) {
      toast.error('Failed to upload profile picture');
    }
  };

  const handleDeletePhoto = async () => {
    if (!user || !userProfile?.photoURL) return;

    try {
      const photoPath = userProfile.photoURL.split('?')[0].split('/o/')[1];
      if (!photoPath) {
        throw new Error('Invalid photo URL');
      }

      const decodedPath = decodeURIComponent(photoPath);
      const photoRef = ref(storage, decodedPath);

      await deleteObject(photoRef);
      await updateDoc(doc(db, 'users', user.uid), {
        photoURL: null
      });
      await fetchUserProfile(user.uid);
      
      toast.success('Profile picture deleted successfully');
    } catch (error: any) {
      if (error.code === 'storage/object-not-found') {
        try {
          await updateDoc(doc(db, 'users', user.uid), {
            photoURL: null
          });
          await fetchUserProfile(user.uid);
          toast.success('Profile picture removed successfully');
        } catch (dbError) {
          toast.error('Failed to update profile');
        }
      } else {
        toast.error('Failed to delete profile picture');
      }
    }
  };

  const handleVerifyEmail = async () => {
    if (!user) return;

    try {
      await sendEmailVerification(user);
      toast.success('Verification email sent. Please check your inbox.');
    } catch (error) {
      toast.error('Failed to send verification email');
    }
  };

  const handleDeletionRequest = async (reason: string, note: string) => {
    try {
      setLoading(true);
      
      if (!userProfile || !user) {
        throw new Error('User profile not found');
      }

      if (!reason) {
        throw new Error('Please provide a reason for deletion');
      }

      const fullName = `${userProfile.firstName || ''} ${userProfile.lastName || ''}`.trim();
      
      if (!fullName) {
        throw new Error('Name is required. Please update your profile with your full name.');
      }

      if (!user.email) {
        throw new Error('Email is required. Please ensure your email is verified.');
      }

      await sendDeletionRequest({
        userId: user.uid,
        name: fullName,
        email: user.email,
        credits: userProfile.credits || 0,
        reason,
        note: note || ''
      });

      // Update local state and Firestore
      await updateDoc(doc(db, 'users', user.uid), {
        hasDeletionRequest: true
      });
      setHasDeletionRequest(true);

      toast.success('Deletion request sent successfully. Our team will review your request.');
      setShowDeletionModal(false);
    } catch (error: any) {
      console.error('Error sending deletion request:', error);
      toast.error(error.message || 'Failed to send deletion request');
    } finally {
      setLoading(false);
    }
  };

  const handleCancellationRequest = async (reason: string, note: string) => {
    try {
      setLoading(true);
      
      if (!userProfile || !user) {
        throw new Error('User profile not found');
      }

      const fullName = `${userProfile.firstName || ''} ${userProfile.lastName || ''}`.trim();

      await sendCancellationRequest({
        userId: user.uid,
        name: fullName,
        email: user.email || '',
        credits: userProfile.credits || 0,
        reason,
        note: note || ''
      });

      // Update local state and Firestore
      await updateDoc(doc(db, 'users', user.uid), {
        hasDeletionRequest: false
      });
      setHasDeletionRequest(false);

      toast.success('Your deletion request has been cancelled successfully.');
      setShowCancellationModal(false);
    } catch (error: any) {
      console.error('Error sending cancellation request:', error);
      toast.error(error.message || 'Failed to cancel deletion request');
    } finally {
      setLoading(false);
    }
  };

  if (!userProfile) return null;

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12 bg-gray-50 dark:bg-gray-900 min-h-screen">
      <div className="max-w-3xl mx-auto space-y-6">
        <h1 className="text-3xl font-bold text-gray-900 dark:text-white mb-8">User Profile Settings</h1>

        {!user?.emailVerified && <EmailVerificationBanner />}
        
        <PhotoUpload
          photoURL={userProfile?.photoURL || null}
          firstName={userProfile?.firstName || ''}
          lastName={userProfile?.lastName || ''}
          onUpload={handlePhotoUpload}
          onDelete={handleDeletePhoto}
        />

        <ProfileForm
          firstName={formData.firstName}
          lastName={formData.lastName}
          email={user?.email}
          phoneNumber={userProfile?.phoneNumber || null}
          linkedinUrl={formData.linkedinUrl || null}
          isPhoneVerified={userProfile?.isPhoneVerified || false}
          isVerified={userProfile?.isVerified || false}
          editMode={editMode}
          loading={loading}
          onEdit={() => setEditMode(true)}
          onCancel={() => {
            if (userProfile) {
              setFormData({
                firstName: userProfile.firstName,
                lastName: userProfile.lastName,
                linkedinUrl: userProfile.linkedinUrl || ''
              });
            }
            setEditMode(false);
          }}
          onSubmit={handleProfileUpdate}
          onChange={(field, value) => setFormData(prev => ({ ...prev, [field]: value }))}
          onVerify={handleVerifyEmail}
        />

        <div className="bg-white dark:bg-gray-800 shadow rounded-lg p-6">
          <h2 className="text-xl font-semibold text-gray-900 dark:text-white mb-6">Payment History</h2>
          <RecentPayments />
        </div>

        <PasswordChange />

        <div className="bg-white dark:bg-gray-800 shadow rounded-lg p-6">
          <div className="border-b border-gray-200 dark:border-gray-700 pb-6 mb-6">
            <h2 className="text-xl font-semibold text-gray-900 dark:text-white">Request Profile Deletion</h2>
            <p className="mt-1 text-sm text-gray-500 dark:text-gray-400">
              Want to delete your profile? Please submit a deletion request and let us know why.
            </p>
          </div>
          {hasDeletionRequest ? (
            <button
              type="button"
              onClick={() => setShowCancellationModal(true)}
              className="text-green-600 dark:text-green-400 hover:text-green-700 dark:hover:text-green-300 text-sm font-medium focus:outline-none focus:underline"
            >
              Cancel Profile Deletion
            </button>
          ) : (
            <button
              type="button"
              onClick={() => setShowDeletionModal(true)}
              className="text-red-600 dark:text-red-400 hover:text-red-700 dark:hover:text-red-300 text-sm font-medium focus:outline-none focus:underline"
            >
              Request Profile Deletion
            </button>
          )}
        </div>

        {showDeletionModal && (
          <RequestDeletionModal
            isOpen={showDeletionModal}
            onClose={() => setShowDeletionModal(false)}
            onConfirm={handleDeletionRequest}
            userDetails={{
              name: `${userProfile.firstName} ${userProfile.lastName}`,
              email: user?.email || '',
              credits: userProfile.credits || 0
            }}
          />
        )}

        {showCancellationModal && (
          <CancelDeletionModal
            isOpen={showCancellationModal}
            onClose={() => setShowCancellationModal(false)}
            onConfirm={handleCancellationRequest}
            userDetails={{
              name: `${userProfile.firstName} ${userProfile.lastName}`,
              email: user?.email || '',
              credits: userProfile.credits || 0
            }}
          />
        )}
      </div>
    </div>
  );
}