import { ExternalLink, AlertCircle, Paperclip, XCircle } from 'lucide-react';
import { FaRegCopy } from 'react-icons/fa';
import { Application } from '../../../types/application';
import { formatDate } from '../../../utils/date';
import { toast } from 'react-hot-toast';
import { useAuth } from '../../../hooks/useAuth';
import { useEffect, useState } from 'react';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../../lib/firebase';

interface ApplicationDetailsProps {
  application: Application;
}

export function ApplicationDetails({ application }: ApplicationDetailsProps) {
  const { user, userProfile } = useAuth();
  const isAssignedAgent = userProfile?.userType === 'agent' && application.agentId === user?.uid;
  const isAdmin = userProfile?.userType === 'admin';
  const canModifyApplication = isAdmin || isAssignedAgent;
  const [agentDetails, setAgentDetails] = useState<any>(null);

  useEffect(() => {
    if (application.agentId) {
      loadAgentDetails();
    }
  }, [application.agentId]);

  const loadAgentDetails = async () => {
    try {
      const agentDoc = await getDoc(doc(db, 'users', application.agentId));
      if (agentDoc.exists()) {
        setAgentDetails(agentDoc.data());
      }
    } catch (error) {
      console.error('Error loading agent details:', error);
    }
  };

  return (
    <dl className="grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-2">
      <div className="sm:col-span-2 flex items-center space-x-2">
        <span className="text-sm font-medium text-gray-500 dark:text-gray-400">Application ID:</span>
        <button
          onClick={() => {
            navigator.clipboard.writeText(application.id);
            toast.success('Application ID copied to clipboard');
          }}
          className="group flex items-center space-x-2"
          title="Click to copy Application ID"
        >
          <code className="text-sm bg-gray-100 dark:bg-gray-700 px-2 py-1 rounded group-hover:bg-gray-200 dark:group-hover:bg-gray-600 transition-colors">
            {application.id}
          </code>
          <FaRegCopy className="h-4 w-4 text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-300" />
        </button>
      </div>

      {application.status === 'pending' && application.notes && (
        <div className="sm:col-span-2 mb-4">
          <div className="bg-yellow-50 dark:bg-yellow-900/30 p-4 rounded-lg border border-yellow-200 dark:border-yellow-800">
            <div className="flex">
              <AlertCircle className="h-5 w-5 text-yellow-500 dark:text-yellow-400 flex-shrink-0" />
              <div className="ml-3 flex-1">
                <h3 className="text-sm font-medium text-yellow-800 dark:text-yellow-200">
                  Additional Information Required
                </h3>
                <div className="mt-2 text-sm text-yellow-700 dark:text-yellow-100 whitespace-pre-wrap">
                  {application.notes}
                </div>
                {/* Display attachments if they exist */}
                {application.pendingAttachments && application.pendingAttachments.length > 0 && (
                  <div className="mt-4 space-y-2">
                    <h4 className="text-sm font-medium text-yellow-800 dark:text-yellow-200">
                      Reference Screenshots:
                    </h4>
                    {application.pendingAttachments.map((file, index) => (
                      <a
                        key={index}
                        href={file.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="flex items-center space-x-2 text-yellow-700 hover:text-yellow-800 dark:text-yellow-100 dark:hover:text-yellow-50"
                      >
                        <Paperclip className="h-4 w-4" />
                        <span className="text-sm">{file.name}</span>
                      </a>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      {application.status === 'cancelled' && application.notes && (
        <div className="sm:col-span-2 mb-4">
          <div className="bg-red-50 dark:bg-red-900/30 p-4 rounded-lg border border-red-200 dark:border-red-800">
            <div className="flex">
              <XCircle className="h-5 w-5 text-red-500 dark:text-red-400 flex-shrink-0" />
              <div className="ml-3 flex-1">
                <h3 className="text-sm font-medium text-red-800 dark:text-red-200">
                  Application Cancelled
                </h3>
                <div className="mt-2 text-sm text-red-700 dark:text-red-100 whitespace-pre-wrap">
                  {application.notes}
                </div>
                {application.cancellationAttachments && application.cancellationAttachments.length > 0 && (
                  <div className="mt-4 space-y-2">
                    <h4 className="text-sm font-medium text-red-800 dark:text-red-200">
                      Cancellation Screenshots:
                    </h4>
                    {application.cancellationAttachments.map((file, index) => (
                      <a
                        key={index}
                        href={file.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="flex items-center space-x-2 text-red-700 hover:text-red-800 dark:text-red-100 dark:hover:text-red-50"
                      >
                        <Paperclip className="h-4 w-4" />
                        <span className="text-sm">{file.name}</span>
                      </a>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      <div>
        <dt className="text-sm font-medium text-gray-600 dark:text-gray-400">Job URL</dt>
        <dd className="mt-1">
          <a
            href={application.jobUrl}
            target="_blank"
            rel="noopener noreferrer"
            className="inline-flex items-center text-sm text-indigo-600 hover:text-indigo-500 dark:text-indigo-400 dark:hover:text-indigo-300"
          >
            View Job Posting
            <ExternalLink className="h-4 w-4 ml-1" />
          </a>
        </dd>
      </div>

      <div>
        <dt className="text-sm font-medium text-gray-600 dark:text-gray-400">Submitted</dt>
        <dd className="mt-1 text-sm text-gray-600 dark:text-gray-100">
          {formatDate(application.createdAt)}
        </dd>
      </div>

      <div>
        <dt className="text-sm font-medium text-gray-600 dark:text-gray-400">Last Updated</dt>
        <dd className="mt-1 text-sm text-gray-600 dark:text-gray-100">
          {formatDate(application.updatedAt)}
        </dd>
      </div>

      <div>
        <dt className="text-sm font-medium text-gray-600 dark:text-gray-400">Assigned Agent</dt>
        <dd className="mt-2">
          {application.agentId ? (
            <div className="flex items-center space-x-3">
              {agentDetails?.photoURL ? (
                <img 
                  src={agentDetails.photoURL} 
                  alt={agentDetails.displayName || 'Agent'} 
                  className="h-10 w-10 rounded-full object-cover border border-gray-200 dark:border-gray-700"
                />
              ) : (
                <div className="h-10 w-10 rounded-full bg-gray-200 dark:bg-gray-700 flex items-center justify-center">
                  <span className="text-gray-500 dark:text-gray-400 text-sm font-medium">
                    {(agentDetails?.displayName || 'A').charAt(0)}
                  </span>
                </div>
              )}
              <div>
                <p className="text-sm font-medium text-gray-900 dark:text-white">
                  {application.agentName || (agentDetails ? `${agentDetails.firstName} ${agentDetails.lastName}` : 'Unknown Agent')}
                  {isAssignedAgent && <span className="ml-2 text-indigo-500">(You)</span>}
                </p>
                {agentDetails?.email && (
                  <p className="text-sm text-gray-500 dark:text-gray-400">
                    {agentDetails.email}
                  </p>
                )}
              </div>
            </div>
          ) : (
            <div className="flex items-center space-x-3">
              <div className="h-10 w-10 rounded-full bg-gray-200 dark:bg-gray-700 flex items-center justify-center">
                <span className="text-gray-500 dark:text-gray-400 text-sm font-medium">?</span>
              </div>
              <p className="text-sm text-gray-500 dark:text-gray-400">
                Unassigned
                {isAssignedAgent && <span className="ml-2 text-indigo-500">(You)</span>}
              </p>
            </div>
          )}
        </dd>
      </div>

      {application.status !== 'pending' && application.notes && (
        <div className="sm:col-span-2">
          <dt className="text-sm font-medium text-gray-600 dark:text-gray-400">Notes</dt>
          <dd className="mt-1 text-sm text-gray-600 dark:text-gray-100 whitespace-pre-wrap">
            {application.notes}
          </dd>
        </div>
      )}

      {!canModifyApplication && userProfile?.userType === 'agent' && (
        <div className="sm:col-span-2 mt-4 bg-yellow-50 dark:bg-yellow-900/20 p-4 rounded-lg">
          <div className="flex">
            <div className="flex-shrink-0">
              <AlertCircle className="h-5 w-5 text-yellow-400" aria-hidden="true" />
            </div>
            <div className="ml-3">
              <h3 className="text-sm font-medium text-yellow-800 dark:text-yellow-200">
                View Only Access
              </h3>
              <div className="mt-2 text-sm text-yellow-700 dark:text-yellow-300">
                <p>
                  This application is {application.agentId ? 'assigned to another agent' : 'not assigned to you'}. 
                  You can view the details but cannot modify the application status.
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </dl>
  );
}